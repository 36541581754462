function OverallScoreIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 29.3337H20C26.6667 29.3337 29.3333 26.667 29.3333 20.0003V12.0003C29.3333 5.33366 26.6667 2.66699 20 2.66699H12C5.33332 2.66699 2.66666 5.33366 2.66666 12.0003V20.0003C2.66666 26.667 5.33332 29.3337 12 29.3337Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.77335 19.3197L12.9467 15.1997C13.4 14.613 14.24 14.5064 14.8267 14.9597L17.2667 16.8797C17.8533 17.333 18.6933 17.2264 19.1467 16.653L22.2267 12.6797"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default OverallScoreIcon
