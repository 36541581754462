// const percentToColor = (percent: number) => {
//   let color: string
//   if (percent >= 100) {
//     color = "#328C57"
//   } else if (percent >= 85) {
//     color = "#B2E5C7"
//   } else {
//     color = "#FFB795"
//   }
//   return color
// }

// export { percentToColor }
const percentToColor = (numerator: number) => {
  const percent = Math.min(100, Math.round(numerator * 100))

  if (Number.isNaN(percent) || percent < 0) {
    return "#D84100"
  }

  let color: string
  if (percent >= 100) {
    color = "#B2E5C7"
  } else if (percent >= 60) {
    color = "#FFA63D"
  } else if (percent >= 50) {
    color = "#D84100"
  } else {
    color = "#D84100"
  }

  return color
}

export { percentToColor }
