import { Progress } from "antd"

import styles from "./styles.module.scss"

interface ScorePercentProps {
  title: string
  percent: number
}

function ScorePercent({ title, percent }: ScorePercentProps) {
  return (
    <div className={styles.wrapper}>
      <p>{title}</p>
      <Progress
        strokeLinecap="butt"
        type="circle"
        percent={percent}
        strokeColor={
          // eslint-disable-next-line no-nested-ternary
          percent === 100 ? "#51BD7E" : percent >= 85 ? "#B2E5C7" : "#FFB795"
        }
        strokeWidth={8}
        trailColor="#35353D"
        width={100}
        className="debrief-progress"
      />
    </div>
  )
}

export default ScorePercent
