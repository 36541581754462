import { Button } from "antd"
import classNames from "classnames"

import { MsTeamIcon } from "../../../assets/svg"

import styles from "./MsTeamButton.module.scss"

function MsTeamButton({ ...restProps }) {
  return (
    <Button
      {...restProps}
      className={classNames(styles.button, restProps.className)}
      icon={
        <div>
          <MsTeamIcon style={{ marginLeft: "10px" }} />
        </div>
      }
    />
  )
}

export default MsTeamButton
