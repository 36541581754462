import classNames from "classnames/bind"

import { PointIcon } from "../../../../assets/svg"

import styles from "./styles.module.scss"

const cx = classNames.bind(styles)

interface PointType {
  id: number | string
  label: string
  time: string
  title: string
}

interface StartPointType {
  point: PointType
  onTop?: boolean
}

interface EndPointType {
  point: PointType
  onBottom?: boolean
}

function BluePoint({
  position,
  point
}: {
  position: number
  point: PointType
}) {
  const { time, title } = point
  return (
    <div
      className={cx("line-point", "blue-point")}
      style={{ left: `${position}%` }}
    >
      <PointIcon color="#2787E0" />
      <div className={cx("tooltip")}>
        <p>
          {time} - {title}
        </p>
      </div>
    </div>
  )
}

function RedPoint({ position, point }: { position: number; point: PointType }) {
  const { time, title } = point
  return (
    <div
      className={cx("line-point", "red-point")}
      style={{ left: `${position}%` }}
    >
      <PointIcon color="#ED223C" />
      <div className={cx("tooltip")}>
        <p>
          {time} - {title}
        </p>
      </div>
    </div>
  )
}

/**
 * The start point is positioned on the bottom by default.
 * If the next point is on the bottom too (Blue point),
 * the start point will be moved to the top.
 */
function StartPoint({ point, onTop = false }: StartPointType) {
  const { time, title } = point
  return (
    <div
      className={cx("line-point", "start-point", onTop ? "start-on-top" : "")}
    >
      <PointIcon color="#51BD7E" />
      <div className={cx("tooltip")}>
        <p>
          {time} - {title}
        </p>
      </div>
    </div>
  )
}
StartPoint.defaultProps = {
  onTop: false
}

/**
 * The end point is positioned on the top by default.
 * If the previous point is on the top too (Red point),
 * the end point will be moved to the bottom.
 */
function EndPoint({ point, onBottom = false }: EndPointType) {
  const { time, title } = point
  return (
    <div
      className={cx("line-point", "end-point", onBottom ? "end-on-bottom" : "")}
    >
      <PointIcon color="#51BD7E" />
      <div className={cx("tooltip")}>
        <p>
          {time} - {title}
        </p>
      </div>
    </div>
  )
}
EndPoint.defaultProps = {
  onBottom: false
}

export { RedPoint, BluePoint, StartPoint, EndPoint }
