import { Col, Row } from "antd"
import classNames from "classnames/bind"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { MinHeightFullScreenRow } from "../../assets/css/styled"
import {
  BlueTeamIcon,
  CategoryIcon,
  DurationIcon,
  RedTeamIcon
} from "../../assets/svg"
import {
  Card,
  HeaderTitle,
  Label,
  MainButton,
  MyTeam,
  Objectives,
  ToolsAndSystems
} from "../../components"
import { Timer } from "../../components/AppLayout/Header/HeaderComponents"
import { fetchStiMulationDetails } from "../../app/reducers/ExercisesSlice"
import { Ellipse } from "../../assets/imgs"
import { extractTime } from "../../utils/Common/timeextract"

import {
  BriefingNote,
  Countingdown,
  Launching,
  Remaining,
  SupportingInformation
} from "./components"
import BriefingNoteModal from "./components/BriefingNote/Modal"
import SupportingInformationModal from "./components/SupportingInformation/Modal"
import styles from "./Simulations.module.scss"
import { MAIN_HEADER_CONTENT, TOOL_AND_SYSTEM } from "./SimulationsConstants"
import { simulationsActions } from "./SimulationsSlice"

const cx = classNames.bind(styles)

function Simulations() {
  const duration = useAppSelector(state => state.simulations.duration)
  const [pagesNo, setPagesNo] = useState(1)
  const isRemaining = useAppSelector(state => state.simulations.isRemaining)
  const isLaunching = useAppSelector(state => state.simulations.isLaunching)
  const isCountingdown = useAppSelector(
    state => state.simulations.isCountingdown
  )
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { stimulationDetails, loading } = useAppSelector(
    state => state.exerciseList
  )
  const dispatch = useAppDispatch()
  const { simulationId } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      if (simulationId && simulationId !== "") {
        try {
          const parsedId = parseInt(simulationId, 10)
          await dispatch(fetchStiMulationDetails(parsedId))
        } catch (error) {
          console.error("Error fetching practice lab detail:", error)
        }
      }
    }

    fetchData()
  }, [dispatch, simulationId])
  const simulationSetails = stimulationDetails.data?.attributes
  const category = simulationSetails?.Category?.data?.attributes?.Name
  const labelType = simulationSetails?.TeamType?.data?.attributes?.Name
  const objectivesAndSkillSet = simulationSetails?.Objectives
  const breifingText = simulationSetails?.BriefingNote
  const supportingInformation = simulationSetails?.SupportingInformation
  const detailsPage = simulationSetails?.DetailsPage
  const stepsToSimulationDetails =
    simulationSetails?.StepsToSimulationDetail || []

  const launchMode = isLaunching || isCountingdown || isRemaining
  // Handle Launching
  const handleLaunching = () => {
    window.scrollTo(0, 0)
    dispatch(simulationsActions.setLaunching(true))
    setTimeout(() => {
      dispatch(simulationsActions.setCountingdown(true))
      dispatch(simulationsActions.setLaunching(false))
    }, 3000)
  }

  // Handle Countdown finished
  const handleCountdownFinish = () => {
    dispatch(simulationsActions.setRemaining(true))
    dispatch(simulationsActions.setCountingdown(false))
  }

  if (Object.keys(stimulationDetails).length === 0) {
    return (
      <div className={cx("loadingIconContainer")}>
        <img className={cx("loadingicon")} src={Ellipse} alt="loading" />
      </div>
    )
  }

  return (
    <div className={cx("container")}>
      <div className={cx("wrapper")}>
        {/* HEADER */}
        <Row gutter={30}>
          <Col xs={24} lg={17}>
            <div className={cx("timer-container")}>
              <div className={cx("simulations-header")}>
                <HeaderTitle text={simulationSetails.Title} />
                <div
                  className={cx("header-right-team")}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "12px",
                    width: "100%",
                    flexWrap: "wrap"
                  }}
                >
                  <div
                    className={cx("label-team")}
                    style={{ display: "flex", gap: "12px" }}
                  >
                    <Label name="Simulations" color="#2787E0" />
                    {labelType === "Red Team" && (
                      <Label
                        name="Red Team"
                        icon={<RedTeamIcon />}
                        outline="#ED223C"
                      />
                    )}
                    {labelType === "Blue Team" && (
                      <Label
                        name="Blue Team"
                        icon={<BlueTeamIcon />}
                        outline="#2787E0"
                      />
                    )}
                  </div>
                  <div
                    className={cx(
                      "header-right-timer",
                      isRemaining ? "mt--25" : ""
                    )}
                    style={{ width: "initial" }}
                  >
                    <div className={cx("header-right-container")}>
                      <div className={cx("header-right")}>
                        <CategoryIcon />
                        <p className={cx("header-right-title")}>
                          Category: {category}
                        </p>
                      </div>
                      <div className={cx("separate")} />
                      <div className={cx("header-right")}>
                        <DurationIcon />
                        <p className={cx("header-right-title")}>
                          Duration:
                          {stimulationDetails?.data?.attributes?.Duration
                            ? extractTime(
                                stimulationDetails.data.attributes.Duration
                              )
                            : ""}
                        </p>
                      </div>
                      {isRemaining && (
                        <p
                          style={{
                            textAlign: "right",
                            color: "#ef4c61",
                            fontSize: "16px",
                            marginLeft: "15px"
                          }}
                        >{`Page: ${pagesNo} / ${stepsToSimulationDetails.length}`}</p>
                      )}
                    </div>
                    {isRemaining && (
                      <div className={cx("simulations_timer")}>
                        <Timer time={Date.now() + duration} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={7} />
        </Row>
        <Row gutter={16}>
          {/* CONTENT */}
          <Col xs={24} lg={17} style={{ marginBottom: "20px" }}>
            <Card shadow="true" className={cx("main-card")}>
              <div className={cx("main-content", launchMode ? "hide" : "")}>
                <p
                  className={cx("main-content-text")}
                  dangerouslySetInnerHTML={{
                    __html: detailsPage
                  }}
                />

                <h3 className={cx("main-content-title")}>Briefing Note</h3>
                <Card className={cx("briefing-card")}>
                  <BriefingNote breifingText={breifingText} />
                </Card>
                {supportingInformation?.length > 0 && (
                  <>
                    <h3 className={cx("main-content-title", "mt-23")}>
                      Supporting Information
                    </h3>
                    <Card className={cx("briefing-card")}>
                      <SupportingInformation
                        supportingInformation={supportingInformation}
                      />
                    </Card>
                  </>
                )}

                <div className={cx("launch-button-container")}>
                  <MainButton
                    onClick={handleLaunching}
                    className={cx("launch-button")}
                  >
                    Launch the Simulation
                  </MainButton>
                </div>
              </div>
              <div className={cx(launchMode ? "launch-mode" : "hide")}>
                {isLaunching && (
                  <Launching text="We are connecting you to your simulation, it might take some time." />
                )}
                {!isLaunching && isCountingdown && (
                  <Countingdown onFinish={handleCountdownFinish} />
                )}
                {!isCountingdown &&
                isRemaining &&
                stepsToSimulationDetails.length > 0 ? (
                  <Remaining
                    stepsToSimulationDetails={stepsToSimulationDetails}
                    setPagesNo={setPagesNo}
                    simulationId={simulationId}
                  />
                ) : null}
              </div>
            </Card>
          </Col>

          {/* SIDEBAR */}
          <Col xs={24} lg={7} className={cx("sidebar-column-wrapper")}>
            {/* <MaxHeightRow gutter={[0, 11]}> */}
            <Col span={launchMode ? 0 : 24} className={cx("sidebar-column")}>
              {objectivesAndSkillSet === null ? null : (
                <Objectives sets={objectivesAndSkillSet} />
              )}
            </Col>
            <Col span={launchMode ? 24 : 0} className={cx("sidebar-column")}>
              <BriefingNoteModal breifingText={breifingText} />
            </Col>
            <Col
              span={launchMode ? 24 : 0}
              className={cx("sidebar-column", "hide")}
            >
              <SupportingInformationModal
                supportingInformation={supportingInformation}
              />
            </Col>
            <Col span={24} className={cx("sidebar-column")}>
              {objectivesAndSkillSet === null ? null : (
                <ToolsAndSystems sets={objectivesAndSkillSet} />
              )}
            </Col>
            <Col span={24} className={cx("sidebar-column")}>
              <MyTeam />
            </Col>
            {/* </MaxHeightRow> */}
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default Simulations
