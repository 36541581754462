/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Input, Radio, Space } from "antd"
import TextArea from "antd/lib/input/TextArea"
import classNames from "classnames/bind"

import { useAppDispatch } from "../../../../app/hooks"
import { MainButton } from "../../../../components"
import { labsActions } from "../../LabsSlice"

import styles from "./styles.module.scss"

const cx = classNames.bind(styles)
export interface OptionType {
  option_id: string
  text: string
}
export interface QuestionType {
  id: number
  Type: string
  Question: string
  options?: OptionType[]
  index?: number
  minRow?: number
}
interface AssessmentQuestionProps {
  questionList: any
  onClickClose: () => void
  currentData: any
  setIsResponseSubmit: any
}

function AssessmentQuestion({
  questionList,
  onClickClose,
  currentData,
  setIsResponseSubmit
}: AssessmentQuestionProps) {
  const dispatch = useAppDispatch()

  const handleValidateForm = async (values: any) => {
    // onClickClose()
    dispatch(labsActions.setValidated(true))
    dispatch(labsActions.setAnswers(values))
    setIsResponseSubmit(true)
  }
  const handleValidateFormFailed = () => {
    dispatch(labsActions.setValidated(false))
    setIsResponseSubmit(false)
  }

  return (
    <Form
      name="assessment-question"
      onFinish={handleValidateForm}
      onFinishFailed={handleValidateFormFailed}
      className={cx("labs-question-wrapper")}
    >
      {questionList.map((item: any, index: number) => {
        let question
        if (item.Type === "Others") {
          question = (
            <TextArea
              bordered={false}
              rows={item.minRow || 6}
              className={cx("text-area", "text-area-container")}
            />
          )
        } else if (item.Type === "Single") {
          question = (
            <Input
              bordered={false}
              className={cx(
                "text-area",
                "short-text-area",
                "text-area-container"
              )}
              style={{ maxWidth: "100%" }}
            />
          )
        } else if (item.Type === "Multi") {
          const options = ["A", "B", "C", "D"].filter(
            optionKey => item[optionKey] !== null
          )

          question =
            options.length > 0 ? (
              <Radio.Group>
                {options.map(optionKey => (
                  <Radio
                    style={{ display: "block" }}
                    key={optionKey}
                    value={optionKey}
                  >
                    {item[optionKey]}
                  </Radio>
                ))}
              </Radio.Group>
            ) : (
              <p>No options available</p>
            )
        }
        return (
          <Form.Item
            key={item.id}
            name={item.Question}
            // label={<li>{`${index + 1}.${item.Question}`}</li>}
            // showing bullets to only one perticular challenge and question
            label={
              item?.id === 51 && currentData?.data?.id === 7 ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${index + 1}. ${
                      item.Question
                    }<li style="list-style-type: disc">Marcus Caldwell</li>
                    <li style="list-style-type: disc">Olivia Harrison</li>
                    <li style="list-style-type: disc">Ethan Lawson</li>
                    <li style="list-style-type: disc">Tricia Peressini</li>
                    <li style="list-style-type: disc">Sophia Bennett</li>`
                  }}
                />
              ) : (
                `${index + 1}. ${item.Question}`
              )
            }
            labelCol={{ span: 24 }}
            rules={[
              { required: true, message: "Please complete the question!" }
            ]}
            className="form-item"
          >
            {question}
          </Form.Item>
        )
      })}
      <MainButton
        htmlType="submit"
        // onClick={onClickClose}
        className={cx("btn-close-modal-question")}
      >
        Submit
      </MainButton>
    </Form>
  )
}

export default AssessmentQuestion
