import React, { useEffect, useState } from "react"

import GalleryIcon from "../../../assets/imgs/gallery.svg"
import CloseIcon from "../../../assets/imgs/Close.svg"
import classes from "../styles.module.scss"
import { setImagelogo } from "../../../app/reducers/authSlice"
import { useAppDispatch } from "../../../app/hooks"

function UploadLogo({ setIsUserDisable, updateUserDetails, updateLogo }: any) {
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const dispatch = useAppDispatch()
  const fileInputRef = React.createRef<HTMLInputElement>()
  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }
  useEffect(() => {
    setImageUrl(`${updateLogo}`)
  }, [updateLogo])
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setImageUrl(reader.result as string)
      }
      reader.readAsDataURL(file)

      const formData = new FormData()
      formData.append("files", file)
      await dispatch(setImagelogo(formData))

      if (file !== null) {
        setIsUserDisable(false)
        updateUserDetails((prevUserDetails: any) => ({
          ...prevUserDetails,
          logoImage: file
        }))
      }
    }
  }

  const handleRemoveImage = () => {
    setImageUrl(null)
  }

  return (
    <div className={classes.box_image}>
      {" "}
      {imageUrl ? (
        <div className={classes.imgBoxioc}>
          <img src={imageUrl} alt="Uploaded" className={classes.img_pre} />
          <button
            type="button"
            className={classes.close_Icon}
            onClick={handleRemoveImage}
          >
            <img src={CloseIcon} alt="close" />
          </button>
        </div>
      ) : (
        <div className={classes.imgBoxioc}>
          <img src={GalleryIcon} alt="icon" />
        </div>
      )}
      <button
        type="button"
        onClick={handleUploadButtonClick}
        className={classes.uploadLogo}
      >
        Upload Logo
      </button>
      <input
        type="file"
        accept="image/*"
        className={classes.inputFile}
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ visibility: "hidden" }}
      />
    </div>
  )
}

export default UploadLogo
