import { Col, Row } from "antd"
import classNames from "classnames/bind"

import { MaxHeightRow } from "../../assets/css/styled"
import { CategoryIcon, DurationIcon } from "../../assets/svg"
import { Card, HeaderTitle } from "../../components"

import { DebriefSidebar, TeamScore, Timeline } from "./components"
import { ScoreItemProps } from "./components/TeamScore/ScoreItem"
import styles from "./Debrief.module.scss"

const cx = classNames.bind(styles)

const SCORE_ITEM_TEAM_A: ScoreItemProps[] = [
  {
    title: "Detection and Analysis",
    score: 8.5,
    scoreScale: 10
  },
  {
    title: "Incident Response",
    score: 6,
    scoreScale: 10
  },
  {
    title: "Crisis Management",
    score: 3,
    scoreScale: 3
  }
]
const SCORE_ITEM_TEAM_B: ScoreItemProps[] = [
  {
    title: "Detection and Analysis",
    score: 5,
    scoreScale: 10
  },
  {
    title: "Incident Response",
    score: 9,
    scoreScale: 10
  },
  {
    title: "Crisis Management",
    score: 2,
    scoreScale: 3
  }
]
function Debrief() {
  return (
    <div className={cx("container")}>
      <div className={cx("wrapper")}>
        <Row>
          <Col span={24}>
            <div className={cx("header")}>
              <HeaderTitle text="APT 29 Cyber Attack" />
              <div className={cx("header-right")}>
                <div className={cx("header-right-item", "header-category")}>
                  <CategoryIcon />
                  <p className={cx("header-right-title")}>
                    Category: Intermediate
                  </p>
                </div>
                <div className={cx("header-right-item")}>
                  <DurationIcon />
                  <p className={cx("header-right-title")}>
                    Duration: 2h 30 min
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={cx("timeline-card-container")}>
              <Card shadow="true" className={cx("timeline-card")}>
                <p className={cx("timeline-title")}>Simulation Timeline</p>
                <Timeline />
              </Card>
            </div>
          </Col>
        </Row>
        <p className={cx("missions")}>Missions</p>
        <Row gutter={[30, 20]}>
          {/* CONTENT */}
          <Col xs={24} sm={24} lg={17}>
            <MaxHeightRow gutter={[0, 20]}>
              <Col span={24}>
                <TeamScore team="A" data={SCORE_ITEM_TEAM_A} />
              </Col>
              <Col span={24}>
                <TeamScore team="B" data={SCORE_ITEM_TEAM_B} />
              </Col>
            </MaxHeightRow>
          </Col>

          {/* SIDEBAR */}
          <Col xs={24} sm={24} lg={7}>
            <DebriefSidebar />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Debrief
