function MsTeamIcon({ ...props }) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_61_7777)">
        <path
          d="M22.0062 9.92958C22.2191 10.1449 22.4702 10.3161 22.7475 10.4353C23.3262 10.6805 23.978 10.6805 24.5577 10.4353C25.1167 10.1946 25.5622 9.74517 25.7991 9.17978C26.0415 8.59452 26.0415 7.93527 25.7991 7.34891C25.5611 6.78352 25.1167 6.33298 24.5577 6.09335C23.9791 5.84821 23.3273 5.84821 22.7475 6.09335C22.1885 6.33408 21.7431 6.78352 21.5062 7.34891C21.2638 7.93417 21.2638 8.59342 21.5062 9.17978C21.6241 9.45916 21.7944 9.71425 22.0062 9.92958ZM21.3249 12.1138V19.2043H22.4932C22.8949 19.2021 23.2945 19.1646 23.6887 19.0895C24.085 19.0188 24.4671 18.8841 24.8209 18.6897C25.1549 18.5064 25.4432 18.248 25.6648 17.9366C25.8974 17.591 26.0142 17.1791 25.9978 16.7606V12.1138H21.3249ZM17.5386 9.14113C17.8487 9.14334 18.1565 9.0804 18.4426 8.95672C19.0016 8.71599 19.446 8.26655 19.6829 7.70117C19.9253 7.11591 19.9253 6.45666 19.6818 5.87029C19.4438 5.30491 18.9994 4.85436 18.4404 4.61474C18.1544 4.49106 17.8465 4.42812 17.5353 4.43033C17.2263 4.42812 16.9206 4.49106 16.6368 4.61474C16.425 4.70639 16.3584 4.82565 16.0668 4.96921V8.60225C16.3594 8.74691 16.425 8.86507 16.6368 8.95672C16.9217 9.0815 17.2285 9.14334 17.5386 9.14113ZM16.0668 21.0794C16.3594 21.1434 16.4643 21.1931 16.6717 21.2218C16.8988 21.255 17.1281 21.2715 17.3574 21.2726C17.7111 21.2704 18.0648 21.2329 18.4109 21.1578C18.7636 21.086 19.102 20.9524 19.4099 20.7636C19.7124 20.5758 19.9667 20.3196 20.1545 20.016C20.362 19.6615 20.4635 19.2529 20.4482 18.841V10.6363H16.0668V21.0794ZM0 23.3409L15.1901 26V0L0 2.65908V23.3409Z"
          fill="#5558AF"
        />
        <path
          d="M11.1003 7.97723L4.08984 8.409V10.0444L6.71889 9.93731V17.5203L8.47122 17.6241V9.86774L11.1003 9.74959V7.97723Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_61_7777">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MsTeamIcon
