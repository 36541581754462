import { useEffect } from "react"
import { Col, Collapse, Row } from "antd"
import { useDispatch, useSelector } from "react-redux"

import { CarouselWrapper } from "../../../../assets/css/styled"
import AvailableLearningPathsLesson from "../AvailableLearningPathsLesson"
import LearningPathsCourse from "../LearningPathsCourse"
import { AvailableLearningPathsType } from "../../type"
import { useAppSelector } from "../../../../app/hooks"

import styles from "./styles.module.scss"

function AvailableLearningPaths() {
  const dispatch = useDispatch()
  const { learningPath, loading, myModule } = useAppSelector(
    (state: any) => state.learningList
  )

  return (
    <Row>
      <Col xl={24} lg={24} md={24} sm={0} xs={0}>
        <Collapse defaultActiveKey={[0]} bordered={false} ghost>
          {myModule.data?.map((item: AvailableLearningPathsType, i: number) => (
            <Collapse.Panel
              showArrow={false}
              key={item.id}
              header={
                <LearningPathsCourse
                  key={`item${Math.random()}`}
                  data={item}
                  index={i}
                  background="#17171B"
                />
              }
            >
              {/* {item.lessonList.length ? (
                item.lessonList.map(lessonItem => (
                  <div key={lessonItem.lessonId}>
                    <AvailableLearningPathsLesson
                      title={lessonItem.title}
                      data={lessonItem.lessonItems}
                    />
                  </div>
                ))
              ) : (
                <p className={styles.lessonInform}>
                  This course has no lessons
                </p>
              )} */}
            </Collapse.Panel>
          ))}
        </Collapse>
      </Col>
      <Col xl={0} lg={0} md={0} sm={24} xs={24}>
        <CarouselWrapper>
          {myModule.data?.map((item: AvailableLearningPathsType, i: number) => (
            <Collapse
              defaultActiveKey={[0]}
              bordered={false}
              ghost
              key={item.id}
              className="collapse-available-learning-paths-responsive"
            >
              <Collapse.Panel
                showArrow={false}
                key={item.id}
                header={
                  <LearningPathsCourse
                    data={item}
                    index={i}
                    background="#17171B"
                  />
                }
              >
                {/* <div className={styles.scroll}>
                  {item.lessonList.length ? (
                    item.lessonList.map(lessonItem => (
                      <div key={lessonItem.lessonId}>
                        <AvailableLearningPathsLesson
                          title={lessonItem.title}
                          data={lessonItem.lessonItems}
                        />
                      </div>
                    ))
                  ) : (
                    <p className={styles.lessonInform}>
                      This course has no lessons
                    </p>
                  )}
                </div> */}
              </Collapse.Panel>
            </Collapse>
          ))}
        </CarouselWrapper>
      </Col>
    </Row>
  )
}

export default AvailableLearningPaths
