import React, { useState } from "react"
// eslint-disable-next-line import/no-extraneous-dependencies
import { QRCodeCanvas } from "qrcode.react"
// eslint-disable-next-line import/no-extraneous-dependencies
import { InputOTP } from "antd-input-otp"
import { Button, Divider, Form } from "antd"
import { useDispatch } from "react-redux"

import "../auth.scss"

import LOGoimage from "../../../assets/imgs/logoNSB.png"
import { useAppSelector } from "../../../app/hooks"

interface LoginFormState {
  email: string
}

const initialFormState: LoginFormState = {
  email: ""
}

function MfaSetup() {
  const [formData, setFormData] = useState<LoginFormState>(initialFormState)
  const [showEmailError, setShowEmailError] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const { loading, user, logohead } = useAppSelector(state => state.user)

  const [showInvalidEmailError, setShowInvalidEmailError] =
    useState<boolean>(false)
  const dispatch = useDispatch()
  const validateEmail = (rule: any, value: string) => {
    if (!value) {
      setShowEmailError(true)
      setShowInvalidEmailError(false)
      return Promise.reject(new Error("Please enter your email"))
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(value)) {
      setShowEmailError(false)
      setShowInvalidEmailError(true)
      return Promise.reject(new Error("Invalid email format"))
    }
    setShowEmailError(false)
    setShowInvalidEmailError(false)
    return Promise.resolve()
  }

  const [form] = Form.useForm()

  const handleFinish = (values: any) => {
    console.log(values)
  }
  const loginText = logohead?.data?.attributes?.LoginText
  return (
    <div className="bground">
      <div className="mixWidth miniuese ">
        <div className="form_range">
          <img src={LOGoimage} alt="" />

          <h5>{loginText}</h5>
          <h2 style={{ fontSize: "32px", marginBottom: "15px" }}>
            SetUp Two-Factor Authentication
          </h2>
          <p>
            In your 2FA-compitable App, Please scan the following
            <br />
            QR-CODE
          </p>
        </div>
        <Form
          className="form_box"
          initialValues={initialFormState}
          style={{ textAlign: "center" }}
          // onFinish={handleSubmit}
        >
          <div className="qr-code">
            <div>
              <QRCodeCanvas
                id="123456"
                value="123456"
                size={250}
                level="H"
                includeMargin={false}
              />
            </div>
            <div className="qr_box">
              <h5>Steps to enable MFA</h5>
              <ul>
                <li>
                  Download an authenticator app on your iOS & Android device
                </li>
                <li>Scan this QR code using an authenticator app</li>
                <li>
                  Enter 2-step verification code generated by your authenticator
                  app
                </li>
              </ul>
              <p>Enter key manually:</p>
            </div>
          </div>

          <Divider style={{ backgroundColor: "#414141" }} />
          <div>
            <p
              style={{
                marginBottom: "12px",
                fontSize: "16px",
                fontWeight: "400"
              }}
            >
              After the application is configured,please enter the configuration
              code
            </p>
            <Form.Item name="otp">
              <InputOTP autoSubmit={form} inputType="numeric" />
            </Form.Item>
            <div
              className="text-center"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ background: "#ED223C" }}
                // disabled={isDisabled}
              >
                Submit
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default MfaSetup
