import React, { useEffect, useState } from "react"
import { Button, Form, Input } from "antd"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"

import { useAppSelector } from "../../../app/hooks"
import LOGoimage from "../../../assets/imgs/logoNSB.png"
import { ProfilePassWordIcon } from "../../../assets/svg"
import {
  ResetPasswordapi,
  forgotPasswordapi,
  loginlogoHead
} from "../../../app/reducers/authSlice"
import "../auth.scss"
import { IMAGE_BASE_URL } from "../../../api/config"

interface ResetPasswordFormState {
  password: string
  passwordConfirmation: string
}

function ResetPassword() {
  const [codeX, setCodeX] = useState<string>("")

  const [formData, setFormData] = useState<ResetPasswordFormState>({
    password: "",
    passwordConfirmation: ""
  })
  const [isDisabled, setIsDisabled] = useState(true)
  const { loading, user, logohead } = useAppSelector(state => state.user)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }))
    setIsDisabled(false)
  }
  useEffect(() => {
    dispatch<any>(loginlogoHead())
  }, [dispatch])
  useEffect(() => {
    const searchParams = location.search
    const tokenUrl = searchParams.split("=")

    setCodeX(tokenUrl[1])
  }, [location])

  const handleSubmit = () => {
    dispatch<any>(ResetPasswordapi({ formData, newCode: codeX, navigate }))
  }
  const Imagelogo = logohead?.data?.attributes?.LoginLogo?.data?.attributes?.url
  const loginText = logohead?.data?.attributes?.LoginText
  return (
    <div className="bground">
      <div className="miniuese">
        <div className="form_range">
          <img
            style={{ width: "200px" }}
            src={`${IMAGE_BASE_URL}${Imagelogo || LOGoimage}`}
            alt=""
          />
          <h5>{loginText}</h5>
          <h2>Change Password</h2>
          <p>Enter a new password below to change your password</p>
        </div>
        <Form className="form_box" onFinish={handleSubmit}>
          <p>New Password</p>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter new password!" }]}
          >
            <Input.Password
              placeholder="Enter new password"
              prefix={
                <span className="inputIcon">
                  <ProfilePassWordIcon />
                </span>
              }
              onChange={handleInputChange}
              value={formData.password}
              name="password"
              autoComplete="new-password"
            />
          </Form.Item>

          <p>Confirm New Password</p>
          <Form.Item
            name="passwordConfirmation"
            rules={[
              { required: true, message: "Please enter confirm new password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(
                      "Your confirm password are not match with new password!"
                    )
                  )
                }
              })
            ]}
          >
            <Input.Password
              placeholder="Confirm new password"
              prefix={
                <span className="inputIcon">
                  <ProfilePassWordIcon />
                </span>
              }
              onChange={handleInputChange}
              value={formData.passwordConfirmation}
              autoComplete="new-password"
              name="passwordConfirmation"
            />
          </Form.Item>

          <div className="open_btn">
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              style={{ background: "#ED223C", width: "100%", color: "#fff" }}
              disabled={isDisabled}
            >
              Change Password
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default ResetPassword
