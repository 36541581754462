import classNames from "classnames"

import styles from "./Card.module.scss"

function Card({ ...restProps }) {
  const { shadow } = restProps
  return (
    <div
      {...restProps}
      className={classNames(
        styles.card,
        restProps.className,
        shadow ? styles.shadow : ""
      )}
    >
      {restProps.children}
    </div>
  )
}

export default Card
