import { Col, Row } from "antd"
import React, { useEffect, useState } from "react"

import { useAppDispatch, useAppSelector } from "../../../app/hooks"

import Question from "./Question"
import QuestionCheckbox from "./QuestionCheckbox"
import QuestionOrder from "./QuestionOrder"
import classes from "./style.module.scss"

function ComponentProvider({ component, srNo }: any) {
  const { __component } = component
  switch (__component) {
    case "questions.single-choice":
      return <Question data={component} srNo={srNo} />
    case "questions.multiple-choice":
      return <QuestionCheckbox data={component} srNo={srNo} />
    case "questions.re-arrange-type":
      return <QuestionOrder data={component} srNo={srNo} />

    default:
      return null
  }
}

export default ComponentProvider
