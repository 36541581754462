/* eslint-disable no-param-reassign */
import { useEffect, useState } from "react"
import {
  Image,
  Col,
  Row,
  Typography,
  Button,
  Card,
  Divider,
  notification
} from "antd"
import classNames from "classnames"
import { Link, useNavigate, useParams } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { HeaderTitle, MainButton, OutlineButton } from "../../../components"
import { IMAGE_BASE_URL } from "../../../api/config"
import homeIcon from "../../../assets/icons/Home.png"
import backIcon from "../../../assets/icons/back.png"
import nextIcon from "../../../assets/icons/next.png"
import {
  fetchQuiz,
  fetchiscompleted,
  payloadProps,
  setIndexvalue
} from "../../../app/reducers/LearningSlice"
import { CustomModal } from "../../../assets/css/styled"
import { CloseIconSm } from "../../../assets/svg"
import { Ellipse } from "../../../assets/imgs"

import styles from "./styles.module.scss"

interface SetProps {
  id: number
  Title: string
  ModuleDetail: any[]
  description?: string[]
  descriptionImg?: any
  img?: any
  button?: string
  img2?: any
  img3?: any
  imageFirstTitle?: string
  imageSecondTitle?: string
  imageThirdTitle?: string
}

interface ObjectivesProps {
  titleNew: string | null
  sets: any
  selectedIndex: number
  handleIndexChange: (index: number) => void
}
function Slides({ sets, titleNew, selectedIndex, handleIndexChange }: any) {
  const filteredSets = sets?.filter((set: any) => set?.Title === titleNew)
  const [showThankYou, setShowThankYou] = useState(false)
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false)
  const [initialRender, setInitialRender] = useState(false)
  const [isPageRefresh, setIsPageRefresh] = useState(true)
  const [confirmNavigation, setConfirmNavigation] = useState(true)
  const { user, logohead } = useAppSelector(state => state.user)
  const {
    moduleId,
    SubModuleId,
    moduleType,
    completedModule,
    indexValue,
    loading,
    questionsList
  } = useAppSelector((state: [] | any) => state?.learningList)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const findIndexById = (id: number | undefined) =>
    filteredSets?.[0]?.ModuleDetail.findIndex((set: any) => set?.id === id) ??
    -1
  const { id } = useParams()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [indexValue, showThankYou])

  const FilterModule = completedModule?.data?.filter(
    (item: any) =>
      item.attributes.ModuleID === `${moduleId}` &&
      item.attributes.Completion === true &&
      item.attributes.SubModuleId === `${id}`
  )

  const handleBackToHome = async () => {
    const payload: payloadProps = {
      data: {
        UserID: `${user?.user?.id}`,
        ModuleID: `${moduleId}`,
        ModuleType: `${moduleType}`,
        Username: `${user?.user?.username}`,
        Completion: true,
        SubModuleId: `${SubModuleId}`
      }
    }
    try {
      if (FilterModule?.[0]?.attributes.Completion === true) {
        navigate("/")
      } else {
        await dispatch(fetchiscompleted({ payload }))
        navigate("/")
      }
    } catch (error) {
      console.error("Error while fetching iscompleted:", error)
    }
  }
  useEffect(() => {
    if (initialRender) {
      localStorage.setItem("shouldOpenModal", "true")
    }
  }, [initialRender])
  useEffect(() => {
    const shouldOpenModal = localStorage.getItem("shouldOpenModal")

    if (shouldOpenModal === "true") {
      setIsModalConfirmOpen(true)
    }
    return () => {
      localStorage.removeItem("shouldOpenModal")
      dispatch(setIndexvalue(0))
    }
  }, [dispatch])

  if (
    filteredSets?.length === 0 ||
    indexValue < 0
    // selectedIndex >= filteredSets[0]?.sets?.length
  ) {
    return <div>No valid data to display</div>
  }
  const selectedSetItem = filteredSets?.[0]?.ModuleDetail?.[indexValue]
  const itemLength = filteredSets?.[0]?.ModuleDetail?.length

  const goBack = () => {
    const currentId = filteredSets?.[0]?.ModuleDetail?.[indexValue]?.id
    const newIndex = findIndexById(currentId) - 1
    setInitialRender(true)
    if (newIndex >= 0) {
      dispatch(setIndexvalue(newIndex))
    } else if (newIndex < 0) {
      navigate(-1)
    }
  }
  const goNext = () => {
    const currentId = filteredSets?.[0]?.ModuleDetail?.[indexValue]?.id
    const newIndex = findIndexById(currentId) + 1
    setInitialRender(true)
    if (newIndex >= filteredSets?.[0]?.ModuleDetail?.length) {
      setShowThankYou(true)
    } else {
      dispatch(setIndexvalue(newIndex))
    }
  }

  const showModalConfirm = () => {
    setIsModalConfirmOpen(true)
  }
  const handleSubmitModalConfirm = () => {
    setIsModalConfirmOpen(false)
    setIsPageRefresh(false)
    setConfirmNavigation(false)
    navigate("/")
  }
  const handleCancelModalConfirm = () => {
    setIsModalConfirmOpen(false)
    setIsPageRefresh(false)
    setConfirmNavigation(false)
  }

  const handleQuizClick = () => {
    handleIndexChange(0) // Navigates to the first slide
    setShowThankYou(false)
  }

  const handleGoToQuestions = () => {
    navigate(`/questions/${id}`)
  }

  if (loading) {
    return (
      <div className={classNames(styles.loadingIconContainer)}>
        <img
          className={classNames(styles.loadingicon)}
          src={Ellipse}
          alt="loading"
        />
      </div>
    )
  }

  return (
    <div>
      <div className={styles.body}>
        <div key={selectedSetItem?.id} className={classNames(styles.container)}>
          {!showThankYou && itemLength > 0 && (
            <>
              {" "}
              <Row
                className={styles.positionheader}
                justify="center"
                align="middle"
              >
                <Col
                  xs={24}
                  sm={24}
                  md={15}
                  lg={17}
                  xl={19}
                  className={styles.headerLeft}
                >
                  <p className={styles.headingleftTitle}>
                    <HeaderTitle text={selectedSetItem?.Title} />
                  </p>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={9}
                  lg={7}
                  xl={5}
                  className={styles.colHomeSecond}
                >
                  <div className={styles.btn}>
                    <Button
                      onClick={showModalConfirm}
                      className={styles.backBtn}
                    >
                      <div className={styles.btnHome}>
                        <span>
                          <img src={homeIcon} alt="" />
                        </span>
                        <p>Home</p>
                      </div>
                    </Button>
                    <Button className={styles.backBtn} onClick={goBack}>
                      <div className={styles.btnback}>
                        <span>
                          <img src={backIcon} alt="" />
                        </span>
                        <p>Back</p>
                      </div>
                    </Button>
                    <Button className={styles.nextBtn} onClick={goNext}>
                      <div style={{ height: "3px" }} className={styles.btnNext}>
                        <p>Next</p>
                        <span className={styles.default}>
                          <img src={nextIcon} alt="" />
                        </span>
                        <span className={styles.defaulthover}>
                          <img src={backIcon} alt="" />
                        </span>
                      </div>
                    </Button>
                    <div className={styles.pages}>
                      {`${indexValue + 1} / ${itemLength}`}
                    </div>
                  </div>
                </Col>
                <Divider
                  style={{
                    backgroundColor: "white",
                    opacity: "0.5",
                    marginTop: "20px"
                  }}
                />
              </Row>
              {selectedSetItem?.Image?.data?.attributes?.url ? (
                <Row className={styles.header}>
                  <Col lg={12} className={styles.info}>
                    <div className={styles.descul}>
                      <p
                        className={styles.paragraph}
                        dangerouslySetInnerHTML={{
                          __html: selectedSetItem?.Description
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg={12} className={styles.colImg}>
                    <div style={{ display: "grid" }}>
                      <Image
                        className={styles.imgPosition}
                        src={`${IMAGE_BASE_URL}${selectedSetItem?.Image?.data?.attributes?.url}`}
                        preview={false}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row className={styles.header}>
                  <Col lg={20} className={styles.info}>
                    <div className={styles.descul}>
                      <p
                        className={styles.paragraph}
                        dangerouslySetInnerHTML={{
                          __html: selectedSetItem?.Description
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </>
          )}
          {showThankYou && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh"
              }}
            >
              <div className={styles.thankYouDiv}>
                {" "}
                <HeaderTitle text="Thank You!" />
                <Divider style={{ backgroundColor: "white" }} />
                <Typography.Paragraph className={styles.paragraph}>
                  This unit has been completed. You can get back to it anytime
                  if you want to review this information again.
                </Typography.Paragraph>
                <Button
                  className={styles.nextBtn}
                  style={{
                    display: "block",
                    fontWeight: "500",
                    fontSize: "18px"
                  }}
                  onClick={handleBackToHome}
                >
                  Get back to the home screen
                </Button>
                {/* {questionsList?.data?.length > 0 && (
                  <Button
                    className={styles.nextBtn}
                    style={{
                      display: "block",
                      fontWeight: "500",
                      fontSize: "18px",
                      marginTop: "20px"
                    }}
                    onClick={handleGoToQuestions}
                  >
                    Visit Questions
                  </Button>
                )} */}
              </div>
            </div>
          )}
          {/* 
          <div className={styles.pageNum}>
            {`${selectedIndex + 1} / ${itemLength}`}
          </div> */}
        </div>
      </div>
      <CustomModal
        title="Modal confirm"
        centered
        footer={null}
        closeIcon={<CloseIconSm />}
        open={isModalConfirmOpen}
        onCancel={handleCancelModalConfirm}
        className={styles.modalConfirm}
      >
        <div className={styles.modalConfirmWrap}>
          <p className={styles.modalConfirmText}>
            Do you want to exit this unit and return to the home page?
          </p>
          <Divider className={styles.divider} />
          <div className={styles.modalButton}>
            <MainButton onClick={handleSubmitModalConfirm}>Yes</MainButton>
            <OutlineButton onClick={handleCancelModalConfirm}>No</OutlineButton>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}
export default Slides
