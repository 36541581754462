/* eslint-disable @typescript-eslint/no-explicit-any */
import { error } from "console"

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import { Avatar, Button, Col, Form, Image, Input, Row, Typography } from "antd"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { Ellipse, avatar, defaultAvatar } from "../../assets/imgs"
import {
  CategoryIcon,
  CloseAvatarIcon,
  DurationIcon,
  ProfileEmailIcon,
  ProfileNameIcon,
  ProfilePassWordIcon,
  ProfilePhoneIcon,
  RedTeamIcon
} from "../../assets/svg"
// eslint-disable-next-line import/no-cycle
import { Card } from "../../components"
import Label from "../../components/Label"
import { useAppSelector } from "../../app/hooks"
import {
  ChangePasswordApi,
  getSession,
  getUserDetail,
  profilechanges
} from "../../app/reducers/authSlice"
import TeamMessaage from "../../assets/svg/TeamMessaage"
import Organisation from "../../assets/svg/Organisation"

import classes from "./styles.module.scss"
import UploadLogo from "./components/UploadLogo"

const formItemLayout = {
  labelCol: {
    span: 24
  }
}

export interface userStateProps {
  username: string
  firstName: string
  lastName: string
  email: string
}
function Profile() {
  const [form] = Form.useForm()
  const [userAvatar, setUserAvatar] = useState(avatar)
  const { saveuser, logoUploaded } = useAppSelector(state => state.user)
  // const { user: userData }: { user: any } = getSession()
  const profileLogo = logoUploaded?.[0]?.url
  const dispatch = useDispatch()
  const userId = saveuser?.id
  const [userDetails, setUserDetails] = useState<any>({
    username: saveuser?.username || "",
    firstName: saveuser?.firstname || "",
    lastName: saveuser?.lastname || "",
    email: saveuser?.email || "",
    organization: saveuser?.organization || "",
    team: saveuser?.team || "",
    ProfileLogo: saveuser?.logo?.url || ""
  })
  const [isPasswordDisabled, setIsPasswordDisabled] = useState<boolean>(true)
  const [isUserDisable, setIsUserDisable] = useState<boolean>(true)

  const [formData, setFormData] = useState({
    currentPassword: "",
    password: "",
    passwordConfirmation: ""
  })
  const handleChange = (e: any) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
    setIsPasswordDisabled(false)
  }
  const handleChangeUser = (e: any) => {
    const { name, value } = e.target
    setUserDetails({
      ...userDetails,
      [name]: value
    })
    setIsUserDisable(false)
  }
  const handleSubmitUser = async () => {
    const updatedValues = {
      firstname: userDetails.firstName,
      lastname: userDetails.lastName,
      username: userDetails.username,
      ProfileLogo: userDetails?.ProfileLogo
    }

    // dispatch<any>(profilechanges({ updatedValues, userId }))
    dispatch<any>(getUserDetail(userId))
    await setIsUserDisable(true)
  }

  const handleSubmit = async () => {
    await dispatch<any>(
      ChangePasswordApi({ formData, form, setIsPasswordDisabled })
    )
  }

  return (
    <div className={`${classes.container} ${classes.profileWrapper}`}>
      <Row>
        <Col span={24}>
          <Typography.Title className={classes.titlePage}>
            Profile
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card className={classes.cardWrapper}>
            <Row>
              <Col span={24} className={classes.avatarHeader}>
                {/* <div className={classes.avatar}>
                  <Avatar
                    style={{ backgroundColor: "#12100e", color: "#f56a00" }}
                    shape="circle"
                    size={105}
                    src={defaultAvatar}
                  /> */}
                {/* <div className={classes.avtIcon} aria-hidden>
                    <CloseAvatarIcon />
                  </div> */}
                {/* </div> */}
                {/* <div className={classes.btnHeader}>
                  <Button type="text">Import From Gravater</Button>
                  <Button
                    type="text"
                    onClick={() => setUserAvatar(defaultAvatar)}
                  >
                    Use Initial as Avatar
                  </Button>
                </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={18}>
                <Form
                  {...formItemLayout}
                  layout="vertical"
                  onFinish={handleSubmitUser}
                >
                  <Row>
                    <Col span={24}>
                      <Typography.Title className={classes.titleForm}>
                        Info
                      </Typography.Title>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        label="First Name"
                        name="firstName"
                        initialValue={userDetails.firstName}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your first name"
                          }
                        ]}
                      >
                        <Input
                          name="firstName"
                          onChange={handleChangeUser}
                          prefix={
                            <span className={classes.inputIcon}>
                              <ProfileNameIcon />
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        label="Last Name"
                        name="lastName"
                        initialValue={userDetails.lastName}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your last name"
                          }
                        ]}
                      >
                        <Input
                          name="lastName"
                          onChange={handleChangeUser}
                          prefix={
                            <span className={classes.inputIcon}>
                              <ProfileNameIcon />
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        label="Username"
                        name="username"
                        initialValue={userDetails.username}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your username"
                          }
                        ]}
                      >
                        <Input
                          name="username"
                          onChange={handleChangeUser}
                          prefix={
                            <span className={classes.inputIcon}>
                              <ProfileNameIcon />
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        label="Email"
                        name="email"
                        initialValue={userDetails.email}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your email"
                          }
                        ]}
                      >
                        <Input
                          name="email"
                          onChange={handleChangeUser}
                          prefix={
                            <span className={classes.inputIcon}>
                              <ProfileEmailIcon />
                            </span>
                          }
                          style={{ color: "#fff", pointerEvents: "none" }}
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="ProfileLogo"
                        initialValue={userDetails.ProfileLogo}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your team name"
                          }
                        ]}
                      >
                        <UploadLogo profileLogo={userDetails?.ProfileLogo} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        label="Team"
                        name="team"
                        initialValue={userDetails.team}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your team name"
                          }
                        ]}
                      >
                        <Input
                          name="team"
                          onChange={handleChangeUser}
                          prefix={
                            <span
                              className={classes.inputIcon}
                              style={{
                                filter:
                                  "brightness(0) saturate(100%) invert(60%) sepia(5%) saturate(595%) hue-rotate(201deg) brightness(82%) contrast(87%)"
                              }}
                            >
                              <TeamMessaage />
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        label="Organisation"
                        name="organization"
                        initialValue={userDetails.organization}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your organisation name"
                          }
                        ]}
                      >
                        <Input
                          name="organization"
                          onChange={handleChangeUser}
                          prefix={
                            <span className={classes.inputIcon}>
                              <Organisation />
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isUserDisable}
                    >
                      Save Changes
                    </Button>
                  </Form.Item>
                </Form>
                {saveuser?.provider === "local" && (
                  <Form
                    {...formItemLayout}
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                  >
                    <Row>
                      <Col span={24}>
                        <Typography.Title className={classes.titleForm}>
                          Change Password
                        </Typography.Title>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          label="Old Password"
                          name="currentPassword"
                          //  initialValue={formData.currentPassword}
                          rules={[
                            {
                              required: true,
                              message: "Please enter your old password"
                            }
                          ]}
                        >
                          <Input.Password
                            name="currentPassword"
                            onChange={handleChange}
                            placeholder="Enter old password"
                            prefix={
                              <span className={classes.inputIcon}>
                                <ProfilePassWordIcon />
                              </span>
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24} />
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          label="New Password"
                          name="password"
                          // initialValue={formData.password}
                          rules={[
                            {
                              required: true,
                              message: "Please enter new password"
                            }
                          ]}
                        >
                          <Input.Password
                            name="password"
                            onChange={handleChange}
                            placeholder="Enter new password"
                            prefix={
                              <span className={classes.inputIcon}>
                                <ProfilePassWordIcon />
                              </span>
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          label="Confirm Password"
                          name="passwordConfirmation"
                          // initialValue={formData.passwordConfirmation}
                          rules={[
                            {
                              required: true,
                              message: "Please confirm  new password!"
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve()
                                }
                                return Promise.reject(
                                  new Error(
                                    "Your confirm password are not match with new password!"
                                  )
                                )
                              }
                            })
                          ]}
                        >
                          <Input.Password
                            name="passwordConfirmation"
                            onChange={handleChange}
                            placeholder="Enter confirm new  password"
                            prefix={
                              <span className={classes.inputIcon}>
                                <ProfilePassWordIcon />
                              </span>
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isPasswordDisabled}
                      >
                        Save Changes
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24} />
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Profile
