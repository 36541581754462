import { LogoutOutlined, UserOutlined } from "@ant-design/icons"
import { useKeycloak } from "@react-keycloak/web"
import { Avatar, Dropdown, Menu } from "antd"
import Countdown from "antd/lib/statistic/Countdown"
import classNames from "classnames/bind"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"

import { ArrowDownIcon, DurationLgIcon, UserIcon } from "../../../assets/svg"
// eslint-disable-next-line import/no-cycle
import {
  getSession,
  logoutApi,
  setActiveHeaderMenu
} from "../../../app/reducers/authSlice"
import { useAppSelector } from "../../../app/hooks"

import styles from "./header.module.scss"

const cx = classNames.bind(styles)
// eslint-disable-next-line react/prop-types
export function HeaderUser({
  visible,
  setOpenHeaderMenu
}: {
  visible?: boolean
  setOpenHeaderMenu: any
}) {
  const classes = visible ? "" : "header_user-dropdown"
  const { user } = useAppSelector(state => state.user)
  // const { keycloak } = useKeycloak()
  //  const { user: userData }: { user: any } = getSession()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const menu = (
    <Menu
      className={cx("header_user-menu")}
      items={[
        {
          label: (
            <Link to="/profile" onClick={() => setOpenHeaderMenu(false)}>
              Profile
            </Link>
          ),
          key: "0",
          icon: <UserOutlined />,
          onClick: () => {
            dispatch(setActiveHeaderMenu(""))
          }
        },
        {
          label: "Log out",
          key: "4",
          icon: <LogoutOutlined />,
          onClick: () => {
            dispatch<any>(logoutApi(navigate))
            dispatch(setActiveHeaderMenu(""))
          }
        }
      ]}
    />
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      placement="bottomRight"
      overlayStyle={{
        background: "",
        position: "fixed",
        top: "50px",
        zIndex: 1000
      }}
      className={cx(classes)}
    >
      <div className={cx("header_user")}>
        <div>
          <Avatar
            shape="square"
            size={33}
            icon={<UserIcon />}
            className={cx("user-avatar")}
          />
        </div>
        <p
          className={cx("user-name")}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
        >
          {user?.user?.username ? user?.user?.username : "Username"}
        </p>
        <div className={cx("user-arrow")}>
          <ArrowDownIcon />
        </div>
      </div>
    </Dropdown>
  )
}

HeaderUser.defaultProps = {
  visible: false
}

export function Timer({ time }: { time: number }) {
  return (
    <div className={cx("header_timer-component", "header_user")}>
      <Countdown
        value={time}
        prefix={<DurationLgIcon />}
        format="H:mm:ss"
        valueStyle={{
          width: "100%",
          color: "#FFFFFF",
          fontSize: "24px",
          opacity: 0.8,
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      />
    </div>
  )
}
