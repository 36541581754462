import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"

import { AppLayout } from "./components"
import PrivateRoute from "./helpers/PrivateRoute"
import {
  Home,
  Debrief,
  Exercises,
  Labs,
  Simulations,
  Skills,
  ListComponents
} from "./pages"
import Presentation from "./pages/Presentation"
import Quiz from "./pages/Quiz"
import { publicRoutes } from "./helpers/pubicRoutes"
import { useAppSelector } from "./app/hooks"
import GoogleAuthCallback from "./pages/Authentication/GoogleauthCallback"
import NotFound from "./pages/notfound"
import ScoreBoard from "./pages/ScoreBoard"
import ProfileInfo from "./pages/Profile/ProfileInfo"
import ResponseForm from "./pages/ResponseForm"
import ChallengesComponent from "./pages/Challanges"
import Download from "./pages/Download"
import VideoPlayer from "./pages/VideoPlayer"
import Questions from "./pages/Questions"

function App() {
  const { isAuthenticated } = useAppSelector(state => state.user)
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/api/auth/google/callback"
            element={<GoogleAuthCallback />}
          />
          {isAuthenticated ? (
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <AppLayout />
                </PrivateRoute>
              }
            >
              <Route path="/login" element={<Navigate to="/" />} />
              <Route index element={<Home />} />
              <Route path="list-components" element={<ListComponents />} />
              <Route path="exercises" element={<Exercises />} />
              <Route path="simulations/" element={<Simulations />}>
                <Route path=":simulationId" element={<Simulations />} />
              </Route>
              <Route path="skills" element={<Skills />} />
              <Route path="debrief" element={<Debrief />} />
              <Route path="profile" element={<ProfileInfo />} />
              <Route path="scoreboard" element={<ScoreBoard />} />
              <Route path="questions/:id" element={<Questions />} />
              <Route path="download/:fileName" element={<Download />} />
              <Route path="video-player" element={<VideoPlayer />} />
              {/* <Route path="submit-your-response" element={<ResponseForm />} /> */}
              <Route path="labs/:team" element={<Labs />}>
                <Route path=":practiceId" element={<Labs />} />
              </Route>
              <Route path="learning/:id" element={<Presentation />}>
                <Route path=":id" element={<Presentation />} />
              </Route>
              <Route path="quiz/:id" element={<Quiz />}>
                <Route path=":id" element={<Quiz />} />
              </Route>
              <Route path="challenges/:id" element={<ChallengesComponent />}>
                <Route path=":id" element={<ChallengesComponent />} />
              </Route>
              {/* <Route path="*" element={<NotFound />} /> */}
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          ) : (
            <>
              <Route path="/" element={<Navigate to="/login" />} />
              {publicRoutes.map((item: any) => (
                <Route
                  key={item.id}
                  path={item.path}
                  element={<item.conponent />}
                />
              ))}
              {/* <Route path="*" element={<NotFound />} /> */}
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
