import React, { useEffect, useState } from "react"
import { Button, Checkbox, Divider, Form, Input, Spin } from "antd"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { LoadingOutlined } from "@ant-design/icons"
import { Icon } from "@iconify/react"
import { CheckboxChangeEvent } from "antd/lib/checkbox"

import LOGoimage from "../../assets/imgs/logoNSB.png"
import "./auth.scss"
import {
  UserSigin,
  getSession,
  loginlogoHead,
  saveSession
} from "../../app/reducers/authSlice"
import { useAppSelector } from "../../app/hooks"
import { IMAGE_BASE_URL } from "../../api/config"
import { ProfileNameIcon, ProfilePassWordIcon } from "../../assets/svg"

interface LoginFormState {
  identifier: string
  password: string
}

const initialFormState: LoginFormState = {
  identifier: "",
  password: ""
}

function Login() {
  const persisUser = localStorage.getItem("usersLogin")
  const passwordRem = localStorage.getItem("passwordUser")
  const rememberMer = localStorage.getItem("remenberMe")
  const rememBer = rememberMer ? JSON.parse(rememberMer) : false

  const [formData, setFormData] = useState<LoginFormState>({
    identifier: persisUser || "",
    password: passwordRem || ""
  })
  const [showEmailError, setShowEmailError] = useState<boolean>(false)
  const { loading, user, logohead } = useAppSelector(state => state.user)
  const [remember, setRemember] = useState<boolean>(rememBer)
  const [isDisabled, setIsDisabled] = useState(false)
  const navigate = useNavigate()
  const [showInvalidEmailError, setShowInvalidEmailError] =
    useState<boolean>(false)
  const dispatch = useDispatch()

  const validateEmail = (rule: any, value: string) => {
    if (!value) {
      setShowEmailError(true)
      setShowInvalidEmailError(false)
      return Promise.reject(new Error("Please enter your email"))
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(value)) {
      setShowEmailError(false)
      setShowInvalidEmailError(true)
      return Promise.reject(new Error("Please enter a valid email!"))
    }
    setShowEmailError(false)
    setShowInvalidEmailError(false)
    return Promise.resolve()
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  useEffect(() => {
    if (user?.jwt) {
      saveSession(user)
      navigate(`/`)
    }
  }, [navigate, user])
  useEffect(() => {
    dispatch<any>(loginlogoHead())
  }, [dispatch])
  const handleSubmit = (values: LoginFormState): void => {
    dispatch<any>(UserSigin(values))
    if (remember === true) {
      localStorage.setItem("usersLogin", formData.identifier)
      localStorage.setItem("remenberMe", JSON.stringify(remember))
      localStorage.setItem("passwordUser", formData.password)
    }
    setFormData(formData)
    setIsDisabled(!!user?.jwt)
  }
  const Imagelogo = logohead?.data?.attributes?.LoginLogo?.data?.attributes?.url
  const loginText = logohead?.data?.attributes?.LoginText
  return (
    <div className="bground">
      <div className="miniuese">
        <div className="form_range">
          <img
            className="imgSize"
            src={`${IMAGE_BASE_URL}${Imagelogo || LOGoimage}`}
            alt=""
          />
          <h5>{loginText}</h5>
          <h2>Sign in to your account:</h2>
        </div>
        <div className="form_box">
          <Form onFinish={handleSubmit}>
            <p>Email</p>
            <Form.Item
              name="identifier"
              initialValue={formData.identifier}
              rules={[{ validator: validateEmail }]}
              validateStatus={
                showEmailError || showInvalidEmailError ? "error" : undefined
              }
              help={
                (showEmailError || showInvalidEmailError) &&
                (showEmailError
                  ? "Please enter your email!"
                  : "Please enter a valid email!")
              }
            >
              <Input
                prefix={
                  <span className="inputIcon">
                    <ProfileNameIcon />
                  </span>
                }
                defaultValue={formData.identifier}
                onChange={handleInputChange}
                placeholder="Enter Email"
                name="identifier"
              />
            </Form.Item>
            <p>Password</p>
            <Form.Item
              name="password"
              initialValue={formData.password}
              rules={[
                { required: true, message: "Please enter your password!" }
              ]}
            >
              <Input.Password
                placeholder="Enter Password"
                defaultValue={formData.identifier}
                prefix={
                  <span className="inputIcon">
                    <ProfilePassWordIcon />
                  </span>
                }
                onChange={handleInputChange}
                name="password"
              />
            </Form.Item>

            <div className="newone" style={{ justifyContent: "end" }}>
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
            <div className="open_btn">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ background: "#ED223C", width: "100%" }}
                disabled={isDisabled}
              >
                {loading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 24, color: "#fff" }}
                        spin
                      />
                    }
                  />
                ) : (
                  "Sign In"
                )}
              </Button>
            </div>
            {/* <Divider
              style={{
                borderColor: "#414141",
                width: "120px",
                color: "#fafafa",
                fontSize: "14px",
                marginTop: "18px",
                marginBottom: "18px"
              }}
            >
              OR
            </Divider> */}
          </Form>
          {/* <GoogleAuth /> */}
        </div>
      </div>
    </div>
  )
}

export default Login
