/* eslint-disable no-console */
import { Button, Col, Row, Tooltip, notification, Divider } from "antd"
import classNames from "classnames/bind"
import { useEffect, useMemo, useRef, useState, useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import { vmApi } from "../../api/apiServices"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { CustomModal, MaxHeightRow } from "../../assets/css/styled"
import { Ellipse } from "../../assets/imgs"
import homeIcon from "../../assets/icons/Home.png"
import backIcon from "../../assets/icons/back.png"
import nextIcon from "../../assets/icons/next.png"
import {
  BlueTeamIcon,
  CategoryIcon,
  CloseIconSm,
  DiagramBlueTeamIcon,
  DiagramRedTeamIcon,
  DurationIcon,
  RedTeamIcon
} from "../../assets/svg"
import {
  Card,
  HeaderTitle,
  Label,
  MainButton,
  OutlineButton,
  SecondaryButton,
  ToolsAndSystems
} from "../../components"
import Objectives from "../../components/Sidebar/Objectives"
import {
  checkActiveLab,
  checkAssignedLab,
  fetchPracticeLabDetail,
  getActiveLab,
  getCloneTemplate,
  getTemplates,
  getVNC,
  payloadSubmitYourResponseProps,
  resetActiveLabState,
  resetCheckActiveLabData,
  resetCheckActiveLabState,
  resetCloneState,
  resetTemplateState,
  resetVncInfoState,
  setIndexvalue,
  setMultiPage,
  submittedAssementResponse
} from "../../app/reducers/ExercisesSlice"
import { extractTime } from "../../utils/Common/timeextract"
import {
  fetchiscompleted,
  payloadProps
} from "../../app/reducers/LearningSlice"

import { labsActions } from "./LabsSlice"
import { DiagramBlueTeam, DiagramRedTeam } from "./components"
import ContentFirst from "./components/Contents/ContentFirst"
import ContentSecond from "./components/Contents/ContentSecond"
import AssessmentQuestion from "./components/Questions"
import { QUESTION_LIST } from "./components/Questions/QuestionList"
import styles from "./Labs.module.scss"

const cx = classNames.bind(styles)

const RED_TEAM = "red"
const BLUE_TEAM = "blue"

function Labs() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const isValidated = useAppSelector(state => state.labs.isValidated)
  const answerList = useAppSelector(state => state.labs.answers)
  const {
    excerciseDetail,
    loading,
    templates,
    clone,
    activeLab,
    activeLabList,
    vncInfo,
    checkActiveLabData,
    assignedLabList
  } = useAppSelector(state => state.exerciseList)

  const params = useParams<{ team: string; practiceId: string }>()
  let teamName = params.team
  if (teamName !== RED_TEAM && teamName !== BLUE_TEAM) teamName = BLUE_TEAM
  const redTeam = teamName === RED_TEAM
  const blueTeam = teamName === BLUE_TEAM

  const { practiceId } = params

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  useEffect(() => {
    const fetchData = async () => {
      if (practiceId && practiceId !== "") {
        try {
          const parsedId = parseInt(practiceId, 10)
          await dispatch(fetchPracticeLabDetail(parsedId))
        } catch (error) {
          console.error("Error fetching practice lab detail:", error)
        }
      }
    }

    fetchData()
  }, [dispatch, practiceId])
  const [isExercise, setIsExercise] = useState(false)
  const [isProvision, setIsProvision] = useState(false)
  const [isShowBtn, setIsShowBtn] = useState(true)
  const [isShowLabBtn, setIsShowLabBtn] = useState(false)
  const [isModalProvisionOpen, setIsModalProvisionOpen] = useState(false)
  const [isModalQuestionOpen, setIsModalQuestionOpen] = useState(false)
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false)
  const [isModalGotoHomepage, setIsModalGotoHomepage] = useState(false)
  const [initialRender, setInitialRender] = useState(false)
  const [titleNew, setTitleNew] = useState<string | null>("")
  const timer = useRef<NodeJS.Timeout>()
  const [isModalLegendOpen, setIsModalLgendOpen] = useState(false)
  const [isResponseSubmit, setIsResponseSubmit] = useState(false)
  const { user, logohead } = useAppSelector(state => state.user)
  const { moduleId, SubModuleId, moduleType, completedModule } = useAppSelector(
    (state: [] | any) => state?.learningList
  )
  const { indexValue, multiPage } = useAppSelector(
    (state: [] | any) => state?.exerciseList
  )

  const { answers } = useAppSelector((state: [] | any) => state?.labs)

  const [currentPage, setCurrentPage] = useState(
    indexValue === 0 ? 0 : indexValue
  )
  const [showCurrentPage, setShowCurrentPage] = useState(
    indexValue === 0 && multiPage === false ? 1 : indexValue + 2
  )

  // keys objects
  const excerciseDetails = excerciseDetail?.data?.attributes
  const labelType = excerciseDetails?.TeamType?.data?.attributes?.Name
  const category = excerciseDetails?.Category?.data.attributes.Name
  const breifDescription =
    excerciseDetails?.BriefInfo?.BriefingDescription || []
  const objectivesAndSkillSet = excerciseDetails?.OtherModules
  const assestmentQues = excerciseDetails?.PracticeAssessmentQuestions
  const breifImage = excerciseDetails?.BriefInfo?.Image?.data?.attributes?.url
  const imageLegend = excerciseDetail?.data?.attributes?.ImageLegend
  const temName = excerciseDetails?.TemplateName
  useEffect(() => {
    if (clone?.data?.error) {
      notification.error({
        // message: clone?.data?.error,
        message: "Something went wrong. Please refresh the page and try again",
        key: "updatable"
      })
      setIsShowBtn(false)
      dispatch<any>(resetCloneState())
    }
  }, [clone?.data?.error, dispatch])

  useEffect(() => {
    if (templates?.error) {
      notification.error({
        message: templates?.data?.error,
        key: "updatable"
      })
      dispatch<any>(resetTemplateState())
    }
  }, [templates, dispatch])

  useEffect(() => {
    if (activeLab?.error) {
      notification.error({
        // message: activeLab?.error,
        message: "Something went wrong. Please refresh the page and try again",
        key: "updatable"
      })
      setIsShowLabBtn(true)
      setIsProvision(true)
      setIsShowBtn(false)
      setIsModalLgendOpen(false)
      setIsExercise(false)
      dispatch<any>(resetActiveLabState())
    }
  }, [activeLab, dispatch])

  useEffect(() => {
    if (activeLabList?.error) {
      // notification.error({
      //   message: activeLabList?.error,
      //   key: "updatable"
      // })
      setIsShowLabBtn(true)
      setIsProvision(true)
      setIsShowBtn(false)
      dispatch<any>(resetCheckActiveLabState())
    }
  }, [activeLabList, dispatch])

  useEffect(() => {
    if (assignedLabList?.error) {
      notification.error({
        message: "Something went wrong. Please refresh the page and try again",
        key: "updatable"
      })
      setIsShowLabBtn(true)
      setIsProvision(true)
      setIsShowBtn(false)
      dispatch<any>(resetCheckActiveLabState())
    }
  }, [assignedLabList, dispatch])

  useEffect(() => {
    if (vncInfo?.error) {
      notification.error({
        // message: activeLab?.error,
        message: "Something went wrong. Please refresh the page and try again",
        key: "updatable"
      })
      setIsShowLabBtn(true)
      setIsProvision(true)
      setIsShowBtn(false)
      setIsModalLgendOpen(false)
      setIsExercise(false)
      dispatch<any>(resetVncInfoState())
    }
  }, [vncInfo, dispatch])

  useEffect(() => {
    setIsProvision(false)
    dispatch<any>(resetCheckActiveLabState())
  }, [activeLabList?.result, dispatch])

  useEffect(() => {
    dispatch<any>(resetCheckActiveLabData())
  }, [dispatch])

  useEffect(() => {
    if (initialRender) {
      localStorage.setItem("shouldOpenModal", "true")
    }
  }, [initialRender])
  useEffect(() => {
    const shouldOpenModal = localStorage.getItem("shouldOpenModal")

    if (shouldOpenModal === "true") {
      setIsModalGotoHomepage(true)
    }
    return () => {
      localStorage.removeItem("shouldOpenModal")
      setCurrentPage(0)
      setShowCurrentPage(1)
      dispatch(setIndexvalue(0))
      setIsProvision(false)
      setIsExercise(false)
      dispatch(setMultiPage(false))
    }
  }, [dispatch])

  // Modal Provision
  const showModalProvision = async (templateName: string) => {
    setIsModalProvisionOpen(true)
    const payload = {
      templatesName: templateName,
      userName: user?.user?.username
    }
    try {
      await dispatch(getCloneTemplate(payload))
    } catch (error) {
      console.error("Error fetching clone:", error)
    }
    setIsShowBtn(true)
    setIsModalProvisionOpen(false)
    setIsProvision(false)
  }
  const handleOkModalProvision = () => {
    setIsModalProvisionOpen(false)
  }
  const handleCancelModalProvision = () => {
    clearTimeout(timer.current)
    setIsModalProvisionOpen(false)
  }

  const showModalConfirm = async () => {
    setIsModalConfirmOpen(true)
  }

  const showModalGotoHomepage = async () => {
    setIsModalGotoHomepage(true)
  }

  const FilterModule = completedModule?.data?.filter(
    (item: any) =>
      item.attributes.ModuleID === `${moduleId}` &&
      item.attributes.ModuleType === "PracticeLab" &&
      item.attributes.SubModuleId === `${practiceId}` &&
      item.attributes.Completion === true
  )

  const handleSubmitModalGotoHomepage = () => {
    setIsModalGotoHomepage(false)
    navigate("/")
  }

  const handleCancelModalConfirm = () => {
    window.scrollTo(0, 0)
    setIsModalConfirmOpen(false)
  }

  const handleCancelModalGotoHomepage = () => {
    window.scrollTo(0, 0)
    setIsModalGotoHomepage(false)
    setIsProvision(false)
    setIsExercise(true)
  }

  // Modal Question
  const showModalQuestion = () => {
    window.scrollTo(0, 0)
    setIsModalQuestionOpen(true)
  }
  const handleCancelModalQuestion = () => {
    setIsModalQuestionOpen(false)
    setIsResponseSubmit(false)
  }

  const handleSubmitYourResponse = useCallback(async () => {
    const responseArray: { Question: string; Answer: string }[] = []
    Object.keys(answers).forEach(key => {
      const Question = key?.trim()
      const Answer = answers[key]
      responseArray.push({ Question, Answer })
    })
    const payload: payloadSubmitYourResponseProps = {
      data: {
        PracticeLabName: `${excerciseDetails?.Title}`,
        AssessmentResponse: responseArray,
        UserId: user?.user?.id,
        Username: `${user?.user?.username}`,
        Organization: `${user?.user?.organization}`,
        PracticeLabId: excerciseDetail?.data?.id
      }
    }

    setIsResponseSubmit(true)
    setIsModalQuestionOpen(false)
    dispatch(labsActions.setValidated(false))
    dispatch(labsActions.setAnswers({}))
    await dispatch(submittedAssementResponse({ payload }))
  }, [
    answers,
    dispatch,
    excerciseDetail?.data?.id,
    excerciseDetails?.Title,
    user?.user?.id,
    user?.user?.organization,
    user?.user?.username
  ])

  const handleCloseModalQuestion = useCallback(() => {
    handleSubmitYourResponse()
  }, [handleSubmitYourResponse])

  useEffect(() => {
    if (isValidated) {
      handleCloseModalQuestion()
    }
  }, [isValidated, handleCloseModalQuestion])

  useEffect(() => {
    if (vncInfo?.length !== 0) {
      const date = new Date()
      document.cookie = `PVEAuthCookie=${
        vncInfo?.data?.result?.PVEAuthCookie
      };expires=${date.setDate(
        date.getDate() + 1
      )};path=/;domain=cyberchallenge.au`
    }
  }, [vncInfo])

  useEffect(() => {
    if (checkActiveLabData?.result !== undefined) {
      if (checkActiveLabData?.result?.lab_template?.name !== temName) {
        // notification.error({
        //   message: activeLabList?.error,
        //   key: "updatable"
        // })
        setIsShowLabBtn(true)
        setIsProvision(true)
      }
    }
  }, [checkActiveLabData, activeLabList, dispatch, temName])

  const handleLaunchExercise = async () => {
    window.scrollTo(0, 0)
    setIsExercise(true)
    setIsProvision(true)
    // try {
    // await dispatch(getTemplates())
    // } catch (error) {
    //   console.error("Error fetching templates:", error)
    // }
    await dispatch(checkActiveLab(user?.user?.username))
  }

  if (Object.keys(excerciseDetail).length === 0) {
    return (
      <div className={cx("loadingIconContainer")}>
        <img className={cx("loadingicon")} src={Ellipse} alt="loading" />
      </div>
    )
  }

  if (loading && !isModalProvisionOpen) {
    return (
      <div className={cx("loadingIconContainer")}>
        <img className={cx("loadingicon")} src={Ellipse} alt="loading" />
      </div>
    )
  }

  const handleCancelModalLegend = () => {
    setIsModalLgendOpen(false)
  }
  const handleOpenModalLegend = async () => {
    setIsModalLgendOpen(true)
    await dispatch(checkAssignedLab(user?.user?.username))
  }

  const downloadSpiceFile = async () => {
    if (checkActiveLabData?.result?.lab_template?.name === temName) {
      try {
        await dispatch(getActiveLab(user?.user?.username))
      } catch (error) {
        console.error("Error fetching templates:", error)
      }
    } else {
      notification.error({
        message: "Something went wrong. Please refresh the page and try again",
        key: "updatable"
      })
    }
  }

  const handleLaunchVNC = async () => {
    if (checkActiveLabData?.result?.lab_template?.name === temName) {
      dispatch(getVNC(user?.user?.username))
    } else {
      notification.error({
        message: "Something went wrong. Please refresh the page and try again",
        key: "updatable"
      })
    }
  }

  const totalLength = excerciseDetails?.PracticeLabDetailPages?.length
  const totalPages = totalLength + 1

  const goBack = () => {
    setInitialRender(true)
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
      const showNewPage = showCurrentPage - 1
      setShowCurrentPage(showNewPage)
      dispatch(setIndexvalue(currentPage - 1))
    } else if (currentPage === 0 && multiPage === false) {
      setCurrentPage(0)
      setShowCurrentPage(1)
      dispatch(setIndexvalue(0))
      setIsProvision(false)
      setIsExercise(false)
    } else if (currentPage <= 0) {
      dispatch(setMultiPage(false))
      setShowCurrentPage(1)
    }
  }
  const goNext = () => {
    const newPage = currentPage + 1

    setInitialRender(true)
    if (newPage < excerciseDetails?.PracticeLabDetailPages?.length) {
      dispatch(setMultiPage(true))
      const showNewPage = showCurrentPage + 1
      setShowCurrentPage(showNewPage)
      setCurrentPage(showCurrentPage === 1 ? 0 : newPage)
      dispatch(setIndexvalue(showCurrentPage === 1 ? 0 : newPage))
    }
  }

  const handleSubmitModalConfirm = async () => {
    window.scrollTo(0, 0)
    setIsModalConfirmOpen(false)
    dispatch(labsActions.setValidated(false))
    const payload: payloadProps = {
      data: {
        UserID: `${user?.user?.id}`,
        ModuleID: `${moduleId}`,
        ModuleType: `${moduleType}`,
        Username: `${user?.user?.username}`,
        Completion: true,
        SubModuleId: `${SubModuleId}`
      }
    }

    try {
      setIsResponseSubmit(false)
      dispatch(labsActions.setAnswers({}))
      if (FilterModule.length > 0) {
        navigate("/exercises")
      } else {
        await dispatch(fetchiscompleted({ payload }))
        navigate("/exercises")
      }
    } catch (error) {
      console.error("Error while fetching iscompleted:", error)
    }
  }

  return (
    <div className={cx("container")}>
      <div className={cx("wrapper")}>
        {/* HEADER */}
        <Row gutter={30}>
          <Col xs={24} lg={24}>
            <div className={cx("simulations-header")}>
              <div className={cx("header-right-team")}>
                <div className={cx("label-team")}>
                  <HeaderTitle
                    text={
                      multiPage
                        ? excerciseDetails?.PracticeLabDetailPages?.[
                            currentPage
                          ]?.Title
                        : excerciseDetails?.Title
                    }
                  />
                </div>
                <div className={cx("header-right-container")}>
                  <div className={cx("header-right")}>
                    {!isProvision && isExercise && (
                      <div className={styles.btn}>
                        <Button
                          onClick={showModalGotoHomepage}
                          className={styles.backBtn}
                        >
                          <div className={styles.btnHome}>
                            <span>
                              <img src={homeIcon} alt="" />
                            </span>
                            <p>Home</p>
                          </div>
                        </Button>
                        <Button className={styles.backBtn} onClick={goBack}>
                          <div className={styles.btnback}>
                            <span>
                              <img src={backIcon} alt="" />
                            </span>
                            <p>Back</p>
                          </div>
                        </Button>
                        <Button className={styles.nextBtn} onClick={goNext}>
                          <div
                            style={{ height: "3px" }}
                            className={styles.btnNext}
                          >
                            <p>Next</p>
                            <span className={styles.default}>
                              <img src={nextIcon} alt="" />
                            </span>
                            <span className={styles.defaulthover}>
                              <img src={backIcon} alt="" />
                            </span>
                          </div>
                        </Button>
                        <div className={styles.pages}>
                          {`${showCurrentPage} / ${totalPages}`}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={17} className={cx("mt-22")}>
            <div className={cx("simulations-header")}>
              <div className={cx("header-right-team")}>
                <div className={cx("label-team")}>
                  <Label name="Practice Lab" color="#D5550D" />
                  {labelType === "Red Team" && (
                    <Label
                      name="Red Team"
                      icon={<RedTeamIcon />}
                      outline="#ED223C"
                    />
                  )}
                  {labelType === "Blue Team" && (
                    <Label
                      name="Blue Team"
                      icon={<BlueTeamIcon />}
                      outline="#2787E0"
                    />
                  )}
                </div>
                <div className={cx("header-right-container")}>
                  <div className={cx("header-right")}>
                    <CategoryIcon />
                    <p className={cx("header-right-title")}>
                      Category: {category}
                    </p>
                  </div>
                  <div className={cx("separate")} />
                  <div className={cx("header-right")}>
                    <DurationIcon />
                    <p className={cx("header-right-title")}>
                      Duration: {extractTime(excerciseDetails.Duration)}{" "}
                    </p>
                  </div>
                </div>
                {/* <div className={cx("separate")} /> */}
                <div className={styles.marginRight} />
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={[30, 20]} className={cx("labs-content-row")}>
          <Col xs={24} lg={17}>
            <Card shadow="true" className={cx("main-card")}>
              <div className={cx(isExercise ? "hide" : "")}>
                {breifDescription.length > 0 && (
                  <ContentFirst sets={excerciseDetails?.DetailsPage} />
                )}
                {/* <ContentFirst sets={breifDescription?.[0]} /> */}
                <MainButton onClick={handleLaunchExercise}>
                  Launch the Exercise
                </MainButton>
              </div>
              <div className={cx(isExercise ? "" : "hide")}>
                {breifDescription.length > 1 && (
                  <>
                    <ContentSecond
                      sets={
                        multiPage
                          ? excerciseDetails?.PracticeLabDetailPages?.[
                              currentPage
                            ]?.Description
                          : excerciseDetails?.BriefInfo?.BriefingDescription
                      }
                    />
                    <Card className={cx("diagram")}>
                      {labelType === "Blue Team" &&
                        (isProvision ? (
                          <DiagramBlueTeamIcon imageBig={breifImage} />
                        ) : (
                          <>
                            {isShowBtn ? (
                              <p className={cx("btnLegend")}>
                                <Label
                                  onClick={handleOpenModalLegend}
                                  name="Connectivity"
                                  color="#D5550D"
                                />
                              </p>
                            ) : null}

                            <DiagramBlueTeam
                              legend={imageLegend}
                              imageBig={breifImage}
                              isModalLegendOpen={isModalLegendOpen}
                              handleOpenModalLegend={handleOpenModalLegend}
                              handleCancelModalLegend={handleCancelModalLegend}
                              handleLaunchVNC={handleLaunchVNC}
                              downloadSpiceFile={downloadSpiceFile}
                            />
                          </>
                        ))}
                      {labelType === "Red Team" &&
                        (isProvision ? (
                          <DiagramRedTeamIcon imageBig={breifImage} />
                        ) : (
                          <>
                            {isShowBtn ? (
                              <p className={cx("btnLegend")}>
                                <Label
                                  onClick={handleOpenModalLegend}
                                  name="Connectivity"
                                  color="#D5550D"
                                />
                              </p>
                            ) : null}

                            <DiagramRedTeam
                              legend={imageLegend}
                              imageBig={breifImage}
                              isModalLegendOpen={isModalLegendOpen}
                              handleOpenModalLegend={handleOpenModalLegend}
                              handleCancelModalLegend={handleCancelModalLegend}
                              handleLaunchVNC={handleLaunchVNC}
                              downloadSpiceFile={downloadSpiceFile}
                            />
                          </>
                        ))}
                    </Card>
                  </>
                )}

                <div className={cx(isProvision ? "provision-button" : "hide")}>
                  {isShowLabBtn && (
                    <MainButton
                      onClick={() =>
                        showModalProvision(excerciseDetails?.TemplateName)
                      }
                    >
                      Provision the Lab
                    </MainButton>
                  )}
                  <CustomModal
                    title=" "
                    centered
                    // closeIcon={<CloseIconSm />}
                    open={isModalProvisionOpen}
                    // onOk={handleOkModalProvision}
                    // onCancel={handleCancelModalProvision}
                    className={cx("modal-question")}
                    footer={null}
                    maskClosable={false}
                    closable={false}
                  >
                    <p className={cx("launch-text")}>
                      Please wait, your lab is being provisioned...
                    </p>
                    <div
                      className={cx("loading-icon-container", "counting-down")}
                    >
                      <img
                        className={cx("loading-icon")}
                        src={Ellipse}
                        alt="loading"
                      />
                      <p className={cx("loading-text")}>Loading...</p>
                    </div>
                  </CustomModal>
                </div>
                <div className={cx(isProvision ? "hide" : "group-button")}>
                  <SecondaryButton
                    onClick={showModalQuestion}
                    className={cx("group-button-item")}
                  >
                    Submit Your Response
                  </SecondaryButton>
                  <CustomModal
                    title="Submit Your Response"
                    centered
                    closeIcon={<CloseIconSm />}
                    open={isModalQuestionOpen}
                    onCancel={handleCancelModalQuestion}
                    footer={null}
                    maskClosable={false}
                    className={cx("modal-question")}
                  >
                    <AssessmentQuestion
                      questionList={assestmentQues}
                      onClickClose={handleCloseModalQuestion}
                      currentData={excerciseDetail}
                      setIsResponseSubmit={setIsResponseSubmit}
                    />
                  </CustomModal>
                  <Tooltip
                    title={
                      isResponseSubmit ? "" : "You need to submit your response"
                    }
                    overlayClassName={cx("complete-exercise-btn-tooltip")}
                  >
                    <div className={cx(isResponseSubmit ? "" : "disabled")}>
                      <MainButton
                        onClick={showModalConfirm}
                        className={cx("group-button-item")}
                      >
                        Complete the Exercise
                      </MainButton>
                    </div>
                  </Tooltip>
                  <CustomModal
                    title="Modal confirm"
                    centered
                    footer={null}
                    closeIcon={<CloseIconSm />}
                    open={isModalConfirmOpen}
                    onCancel={handleCancelModalConfirm}
                    className={cx("modal-confirm")}
                  >
                    <div className={cx("modal-confirm-wrapper")}>
                      <p className={cx("modal-confirm-text")}>
                        Do you want to complete the exercise to submit the
                        results to your instructor?
                      </p>
                      <div className={cx("modal-confirm-buttons")}>
                        <MainButton onClick={handleSubmitModalConfirm}>
                          Submit
                        </MainButton>
                        <OutlineButton onClick={handleCancelModalConfirm}>
                          Go Back
                        </OutlineButton>
                      </div>
                    </div>
                  </CustomModal>
                </div>
              </div>
            </Card>
          </Col>

          <Col xs={24} lg={7}>
            {/* <MaxHeightRow gutter={[0, 11]}> */}
            <Col span={24}>
              <Objectives sets={objectivesAndSkillSet} />
            </Col>
            <Col span={24}>
              <ToolsAndSystems sets={objectivesAndSkillSet} />
            </Col>
            {/* <Col span={24}>
                {blueTeam && <ToolsAndSystems {...TOOL_AND_SYSTEM_BLUE_TEAM} />}
                {redTeam && <ToolsAndSystems {...TOOL_AND_SYSTEM_RED_TEAM} />}
              </Col> */}
            {/* </MaxHeightRow> */}
          </Col>
        </Row>
        <CustomModal
          title="Modal confirm"
          centered
          footer={null}
          closeIcon={<CloseIconSm />}
          open={isModalGotoHomepage}
          onCancel={handleCancelModalGotoHomepage}
          className={styles.modalConfirm}
        >
          <div className={styles.modalConfirmWrap}>
            <p className={styles.modalConfirmText}>
              Do you want to exit this exercise and return to the home page?
            </p>
            <Divider className={styles.divider} />
            <div className={styles.modalButton}>
              <MainButton onClick={handleSubmitModalGotoHomepage}>
                Yes
              </MainButton>
              <OutlineButton onClick={handleCancelModalGotoHomepage}>
                No
              </OutlineButton>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  )
}
export default Labs
