/* eslint-disable react/no-unescaped-entities */
import classNames from "classnames/bind"

import styles from "../../Labs.module.scss"

const cx = classNames.bind(styles)

interface ObjectivesProps {
  sets: string
}
function ContentSecond({ sets }: ObjectivesProps) {
  return (
    <div className={cx("content-text-container")}>
      <div className={cx("content-text-container")}>
        <p
          className={cx("content-text")}
          dangerouslySetInnerHTML={{ __html: sets }}
          style={{ color: "#fff" }}
        />
      </div>
    </div>
  )
}

export default ContentSecond
