import Countdown, { CountdownProps } from "react-countdown"
import classNames from "classnames/bind"
import React from "react"

import styles from "./styles.module.scss"

const cx = classNames.bind(styles)
interface CustomCountdownProps extends Omit<CountdownProps, "date"> {
  minute?: number
  second?: number
}
function CustomCountdown({
  minute = 20,
  second = 0,
  ...restProps
}: CustomCountdownProps) {
  const value = Date.now() + minute * 60 * 1000 + second * 1000

  const renderer = ({
    minutes,
    seconds
  }: {
    minutes: number
    seconds: number
  }) => {
    let min = minutes.toString()
    let sec = seconds.toString()

    if (minutes < 10) {
      min = `0${minutes}`
    }
    if (seconds < 10) {
      sec = `0${sec}`
    }

    const [preMin, sufMin] = min.split("")
    const [preSec, sufSec] = sec.split("")

    return (
      <div className={cx("wrapper")}>
        <div className={cx("item")}>{preMin}</div>
        <div className={cx("item")}>{sufMin}</div>
        <span>:</span>
        <div className={cx("item")}>{preSec}</div>
        <div className={cx("item")}>{sufSec}</div>
      </div>
    )
  }

  return <Countdown {...restProps} date={value} renderer={renderer} />
}

CustomCountdown.defaultProps = {
  minute: 20,
  second: 0
}

export default CustomCountdown
