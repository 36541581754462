function ProfilePassWordIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={0.5}
    >
      <path
        d="M5 8.33366V6.66699C5 3.90866 5.83333 1.66699 10 1.66699C14.1667 1.66699 15 3.90866 15 6.66699V8.33366"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99999 15.4167C11.1506 15.4167 12.0833 14.4839 12.0833 13.3333C12.0833 12.1827 11.1506 11.25 9.99999 11.25C8.8494 11.25 7.91666 12.1827 7.91666 13.3333C7.91666 14.4839 8.8494 15.4167 9.99999 15.4167Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 18.333H5.83335C2.50002 18.333 1.66669 17.4997 1.66669 14.1663V12.4997C1.66669 9.16634 2.50002 8.33301 5.83335 8.33301H14.1667C17.5 8.33301 18.3334 9.16634 18.3334 12.4997V14.1663C18.3334 17.4997 17.5 18.333 14.1667 18.333Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ProfilePassWordIcon
