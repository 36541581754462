/* eslint-disable @typescript-eslint/no-explicit-any */
function DescTableIcon({ ...props }: any) {
  return (
    <div
      style={{
        display: "flex",
        width: props?.width || "165px",
        height: props?.height || "10px",
        borderRadius: "20px",
        gap: 1,
        overflow: "hidden"
      }}
      {...props}
    >
      <div
        style={{
          width: "16.6666667%",
          height: "100%",
          background: "#303037"
        }}
      />
      <div
        style={{
          width: "16.6666667%",
          height: "100%",
          background: "#F67384",
          gap: 1
        }}
      />
      <div
        style={{
          width: "16.6666667%",
          height: "100%",
          background: "#FFB795"
        }}
      />
      <div
        style={{
          width: "16.6666667%",
          height: "100%",
          background: "#FFE195"
        }}
      />
      <div
        style={{
          width: "16.6666667%",
          height: "100%",
          background: "#B2E5C7"
        }}
      />
      <div
        style={{
          width: "16.6666667%",
          height: "100%",
          background: "#51BD7E"
        }}
      />
    </div>
  )
}

export default DescTableIcon
