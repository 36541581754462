import { OverallScoreIcon } from "../../../../../assets/svg"
import { Card } from "../../../../../components"

import styles from "./styles.module.scss"

interface OverallScoreProps {
  overallScore: number
}

function OverallScore({ overallScore }: OverallScoreProps) {
  return (
    <Card className={styles.wrapper}>
      <div>
        <OverallScoreIcon />
      </div>
      <p>Overall Score:</p>
      <h3>{overallScore} out of 10</h3>
    </Card>
  )
}

export default OverallScore
