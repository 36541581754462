/* eslint-disable import/no-cycle */
import { Drawer } from "antd"
import classNames from "classnames/bind"
import { useState, useEffect } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { Icon } from "@iconify/react"
import { useDispatch } from "react-redux"

import { useAppSelector } from "../../../app/hooks"
import {
  CloseIcon,
  MenuIcon,
  PathsIcon,
  SimulationsIcon
} from "../../../assets/svg"
import { IMAGE_BASE_URL } from "../../../api/config"
import { setActiveHeaderMenu } from "../../../app/reducers/authSlice"

import styles from "./header.module.scss"
import { HeaderUser, Timer } from "./HeaderComponents"

const cx = classNames.bind(styles)

function Header() {
  const dispatch = useDispatch()

  const duration = useAppSelector(state => state.simulations.duration)
  const isRemaining = useAppSelector(state => state.simulations.isRemaining)
  const { headersList } = useAppSelector((state: any) => state.learningList)
  const { activeHeaderMenu } = useAppSelector((state: any) => state.user)
  const [openHeaderMenu, setOpenHeaderMenu] = useState<boolean>(false)

  useEffect(() => {
    const learningRoutes = ["challenges", "learning", "quiz", "", "questions"]
    const exerciseRoutes = ["labs", "simulations", "exercises"]
    const scoreboardRoutes = ["scoreboard"]
    const nonActiveRoutes = ["profile"]

    if (learningRoutes.includes(window?.location?.pathname?.split("/")[1])) {
      dispatch(setActiveHeaderMenu("/"))
    } else if (
      exerciseRoutes.includes(window?.location?.pathname?.split("/")[1])
    ) {
      dispatch(setActiveHeaderMenu("exercises"))
    } else if (
      scoreboardRoutes.includes(window?.location?.pathname?.split("/")[1])
    ) {
      dispatch(setActiveHeaderMenu("scoreboard"))
    } else if (
      nonActiveRoutes.includes(window?.location?.pathname?.split("/")[1])
    ) {
      dispatch(setActiveHeaderMenu(""))
    }
  }, [dispatch, activeHeaderMenu])

  const navigate = useNavigate()
  const showHeaderMenu = () => {
    setOpenHeaderMenu(true)
  }

  const closeHeaderMenu = () => {
    setOpenHeaderMenu(false)
  }

  const handleNavigation = (path: string) => {
    navigate(path)
  }
  const LogoSchool =
    headersList.headerDataApi?.data?.attributes.Logo?.data?.attributes.url

  return (
    <div className={cx("container")}>
      <div className={cx("wrapper")}>
        <div
          onClick={() => {
            dispatch(setActiveHeaderMenu("/"))
            handleNavigation("/")
          }}
          aria-hidden="true"
          className={cx("header-logo")}
        >
          {/* <Logo /> */}
          <img
            src="https://assets.threatdefence.io/img/logo-header.png"
            alt="cyber range"
            width={160}
          />
        </div>

        {/* NAVIGATION */}
        <div className={cx("navigate")}>
          <NavLink
            to="/"
            end
            className={({ isActive }) =>
              cx(
                "nav_button",
                isActive || activeHeaderMenu === "/" ? "nav-active" : undefined
              )
            }
            onClick={() => dispatch(setActiveHeaderMenu("/"))}
          >
            <PathsIcon />
            <span className={cx("nav_button-title")}>Learning Paths</span>
          </NavLink>
          <NavLink
            to="exercises"
            className={({ isActive }) =>
              cx(
                "nav_button",
                isActive || activeHeaderMenu === "exercises"
                  ? "nav-active"
                  : undefined
              )
            }
            onClick={() => dispatch(setActiveHeaderMenu("exercises"))}
          >
            <SimulationsIcon />
            <span className={cx("nav_button-title")}>Exercises</span>
          </NavLink>
          {/* <NavLink
            to="scoreboard"
            className={({ isActive }) =>
              cx(
                "nav_button",
                isActive || activeHeaderMenu === "scoreboard"
                  ? "nav-active"
                  : undefined
              )
            }
            onClick={() => dispatch(setActiveHeaderMenu("scoreboard"))}
          >
            <Icon
              className={cx("icon_scoreb")}
              icon="material-symbols-light:scoreboard-outline"
            />
            <span className={cx("nav_button-title")}>Scoreboard</span>
          </NavLink> */}
        </div>
        <div style={{ overflow: "hidden" }} className={styles.bgsLogo}>
          <img
            style={{ maxWidth: "180px", objectFit: "cover" }}
            src={`${IMAGE_BASE_URL}${LogoSchool}`}
            alt="BGS"
          />
        </div>
        {/* USER SETTINGS */}
        {isRemaining && (
          <div className={cx("header_timer")}>
            <Timer time={Date.now() + duration} />
          </div>
        )}
        <HeaderUser setOpenHeaderMenu={setOpenHeaderMenu} />
        <div
          className={cx("hamburger")}
          onClick={showHeaderMenu}
          aria-hidden="true"
        >
          <MenuIcon />
        </div>
        <Drawer
          title=" "
          placement="right"
          closable={false}
          onClose={closeHeaderMenu}
          open={openHeaderMenu}
          extra={
            <div className={cx("header-drawer-extra")}>
              <p>Menu</p>
              <span onClick={closeHeaderMenu} aria-hidden="true">
                <CloseIcon />
              </span>
            </div>
          }
          contentWrapperStyle={{ width: 255 }}
          drawerStyle={{ background: "#17171B" }}
          headerStyle={{ border: "none" }}
          bodyStyle={{ padding: "24px 20px" }}
        >
          <NavLink
            to="/"
            className={({ isActive }) =>
              cx(
                "nav_button",
                "nav_button-responsive",
                isActive || activeHeaderMenu === "/" ? "nav-active" : undefined
              )
            }
            onClick={() => {
              closeHeaderMenu()
              dispatch(setActiveHeaderMenu("/"))
            }}
          >
            <PathsIcon />
            <span className={cx("nav_button-title")}>Learning Paths</span>
          </NavLink>
          <NavLink
            to="exercises"
            className={({ isActive }) =>
              cx(
                "nav_button",
                "nav_button-responsive",
                isActive || activeHeaderMenu === "exercises"
                  ? "nav-active"
                  : undefined
              )
            }
            onClick={() => {
              closeHeaderMenu()
              dispatch(setActiveHeaderMenu("exercises"))
            }}
          >
            <SimulationsIcon />
            <span className={cx("nav_button-title")}>Exercises</span>
          </NavLink>
          {/* <NavLink
            to="scoreboard"
            className={({ isActive }) =>
              cx(
                "nav_button",
                "nav_button-responsive",
                isActive || activeHeaderMenu === "scoreboard"
                  ? "nav-active"
                  : undefined
              )
            }
            onClick={() => {
              closeHeaderMenu()
              dispatch(setActiveHeaderMenu("scoreboard"))
            }}
          >
            <Icon
              className={cx("icon_scoreb")}
              icon="material-symbols-light:scoreboard-outline"
            />
            <span className={cx("nav_button-title")}>Scoreboard</span>
          </NavLink> */}
          <div className={cx("mt-40")}>
            <HeaderUser visible setOpenHeaderMenu={setOpenHeaderMenu} />
          </div>
        </Drawer>
      </div>
    </div>
  )
}

export default Header
