import { Col, Collapse, Row, Typography } from "antd"
import classNames from "classnames"
import { useEffect, useState } from "react"

import {
  fetchExerciseList,
  fetchSimulations
} from "../../app/reducers/ExercisesSlice"
import { DownSquare, UpSquare } from "../../assets/icons"
import { Ellipse } from "../../assets/imgs"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { fetchCompletedModule } from "../../app/reducers/LearningSlice"
import { simulationsActions } from "../Simulations/SimulationsSlice"

import ExerciseList from "./ExerciseList"
import styles from "./styles.module.scss"

function Exercises() {
  const [isExpandPracticeLabs, setIsExpandPracticeLabs] = useState(true)
  const [isExpandSimulations, setIsExpandSimulations] = useState(true)
  const { exercises, loading, simulationsList } = useAppSelector(
    (state: any) => state.exerciseList
  )
  const { user } = useAppSelector(state => state.user)
  const userId = user?.user?.id
  const dispatch = useAppDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    dispatch(fetchExerciseList())
    dispatch(fetchSimulations())
    dispatch(fetchCompletedModule(userId))
    dispatch(simulationsActions.reset())
  }, [dispatch, userId])

  if (loading) {
    return (
      <div className={classNames(styles.loadingIconContainer)}>
        <img
          className={classNames(styles.loadingicon)}
          src={Ellipse}
          alt="loading"
        />
      </div>
    )
  }

  const practiceLabs = exercises?.data
  const stimulation = simulationsList?.data

  return (
    <div className={styles.body}>
      <div className={classNames(styles.container, styles.exercisesContainer)}>
        <Row>
          <Col span={24}>
            <Collapse bordered={false} defaultActiveKey={[1, 2]} ghost>
              <Collapse.Panel
                showArrow={false}
                key="1"
                header={
                  <Row onClick={() => setIsExpandPracticeLabs(prev => !prev)}>
                    <Col span={24} className={styles.title}>
                      <div className={styles.titleContainer}>
                        {isExpandPracticeLabs ? (
                          <img src={UpSquare} alt="up square icon" />
                        ) : (
                          <img src={DownSquare} alt="down square icon" />
                        )}
                        <Typography className={styles.titleName}>
                          Practice Labs
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                }
              >
                <ExerciseList labtype="PracticeLab" data={practiceLabs} />
              </Collapse.Panel>
              <Collapse.Panel
                showArrow={false}
                key="2"
                header={
                  <Row onClick={() => setIsExpandSimulations(prev => !prev)}>
                    <Col span={24} className={styles.title}>
                      <div className={styles.titleContainer}>
                        {isExpandSimulations ? (
                          <img src={UpSquare} alt="up square icon" />
                        ) : (
                          <img src={DownSquare} alt="down square icon" />
                        )}
                        <Typography className={styles.titleName}>
                          Simulations
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                }
              >
                <ExerciseList labtype="Simulation" data={stimulation} />
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Exercises
