function ProfileEmailIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={0.5}
    >
      <path
        d="M14.1667 17.0827H5.83338C3.33338 17.0827 1.66672 15.8327 1.66672 12.916V7.08268C1.66672 4.16602 3.33338 2.91602 5.83338 2.91602H14.1667C16.6667 2.91602 18.3334 4.16602 18.3334 7.08268V12.916C18.3334 15.8327 16.6667 17.0827 14.1667 17.0827Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1666 7.5L11.5583 9.58333C10.6999 10.2667 9.29161 10.2667 8.43328 9.58333L5.83328 7.5"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ProfileEmailIcon
