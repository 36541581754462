import { Divider, Tooltip } from "antd"
import classNames from "classnames/bind"

import { CustomModal } from "../../../../assets/css/styled"
import { CloseIconSm } from "../../../../assets/svg"
import { IMAGE_BASE_URL } from "../../../../api/config"
import { Label } from "../../../../components"

import {
  DiagramTooltipBlueTeamProps,
  DiagramTooltipRedTeam
} from "./DiagramTooltip"
import styles from "./styles.module.scss"

const cx = classNames.bind(styles)

const DOMAIN_CTL: DiagramTooltipBlueTeamProps = {
  type: "domainCtl",
  name: "Windows Server - Domain Controller",
  ip: "10.111.0.10",
  port: "3389",
  description:
    "To connect to your virtual machine via Remote Desktop, download the RDP file below",
  user: "cyberlab",
  password: "99Password99"
}
const WEB_SERVER: DiagramTooltipBlueTeamProps = {
  type: "webServer",
  name: "Windows Server - Web",
  ip: "10.111.0.20",
  port: "3389",
  description:
    "To connect to your virtual machine via Remote Desktop, download the RDP file below.",
  user: "cyberlab",
  password: "99Password99"
}

function DiagramBlueTeam({
  imageBig,
  legend,
  handleOpenModalLegend,
  handleCancelModalLegend,
  isModalLegendOpen,
  handleLaunchVNC,
  downloadSpiceFile
}: {
  imageBig: any
  legend: []
  handleOpenModalLegend: any
  handleCancelModalLegend: any
  isModalLegendOpen: any
  handleLaunchVNC: any
  downloadSpiceFile: any
}) {
  return (
    <div className={cx("overlay")}>
      <img
        style={{
          objectFit: "contain",
          width: "100%",
          height: "257px",
          marginLeft: "8px"
        }}
        src={`${IMAGE_BASE_URL}${imageBig}`}
        alt=""
      />
      <CustomModal
        title="Connectivity Details"
        centered
        closeIcon={<CloseIconSm />}
        open={isModalLegendOpen}
        onCancel={handleCancelModalLegend}
        // onOk={handleOkModalProvision}
        className={cx("modal-question")}
        footer={null}
        maskClosable={false}
      >
        <div>
          {legend?.length > 0 ? (
            legend?.map((img: any) => (
              <>
                <div style={{ display: "flex" }}>
                  <img
                    src={`${IMAGE_BASE_URL}${img?.Image?.data?.attributes?.url}`}
                    alt="Web-Server"
                    className={cx("kali-linux")}
                  />
                  <div>
                    <p
                      className={cx("text")}
                      dangerouslySetInnerHTML={{
                        __html: img?.Description
                      }}
                    />
                    {img?.UploadFile?.data?.attributes?.url ? (
                      <button
                        style={{ marginBottom: "16px" }}
                        type="button"
                        className={cx("button")}
                      >
                        <a
                          href={`${IMAGE_BASE_URL}${img?.UploadFile?.data?.attributes?.url}`}
                          download={`${IMAGE_BASE_URL}${img?.UploadFile?.data?.attributes?.url}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Download RDP File
                        </a>
                      </button>
                    ) : null}
                  </div>
                </div>
                {legend?.length > 1 ? (
                  <Divider className={cx("dividerline")} />
                ) : null}
              </>
            ))
          ) : (
            <div className={cx("my-20")}>
              <p>No legends are added</p>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: 20
            }}
          >
            <p>
              <Label
                onClick={downloadSpiceFile}
                name="Download SPICE file"
                color="#D5550D"
              />
            </p>
            <p>
              <Label
                onClick={handleLaunchVNC}
                name="Launch VNC"
                color="#D5550D"
              />
            </p>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

export default DiagramBlueTeam
