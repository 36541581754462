import { Col, Row } from "antd"
import React, { useEffect, useState } from "react"

import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { setSelectedAnswerList } from "../../../app/reducers/LearningSlice"

import classes from "./style.module.scss"

function Question({ data, srNo }: any) {
  const dispatch = useAppDispatch()

  const { questionsList, loading, selectedAnswerList } = useAppSelector(
    state => state.learningList
  )

  const onSelectOption = (event: any, selectedId: any) => {
    const from = "single"
    dispatch(setSelectedAnswerList({ selectedId, srNo, from }))
  }

  return (
    <div>
      <Row>
        <Row className={classes.questionWrapper}>
          <Col lg={24} md={24}>
            <div className={classes.questionName}>
              {srNo}. {data?.Title}
            </div>
            <div className={classes.questionDescription}>
              {data?.Description}
            </div>
            {data.Options.map((item: any) => (
              <span
                className={`${classes.cp} ${
                  selectedAnswerList[srNo] === item.id
                    ? classes.questionOptionsSelected
                    : classes.questionOptions
                }`}
                onClick={e => onSelectOption(e, item.id)}
                aria-hidden
              >
                {item.Title}
              </span>
            ))}
          </Col>
        </Row>
      </Row>
    </div>
  )
}

export default Question
