/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Row, Typography } from "antd"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

import { CategoryIcon, DurationIcon } from "../../../../assets/svg"
import {
  LAB_BLUE,
  LAB_RED,
  PRESENTATION_RED,
  QUIZ,
  SIMULATION_BLUE,
  SIMULATION_RED,
  challanges
} from "../../data"
import { extractTime } from "../../../../utils/Common/timeextract"
import { Label } from "../../../../components"
import { useAppDispatch } from "../../../../app/hooks"
import {
  setModuleType,
  setSubModuleId
} from "../../../../app/reducers/LearningSlice"

import classes from "./styles.module.scss"

function MyLearningPathsLesson({
  data,
  background,
  name,
  team,
  completedModule
}: {
  data: any
  background: string
  name: string
  team: any
  completedModule: any
}) {
  const { moduleId } = useSelector((state: [] | any) => state.learningList)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleNavigate = (id: string | number, ModuleType: string) => {
    dispatch(setSubModuleId(id))
    dispatch(setModuleType(ModuleType))
    if (!data) return
    switch (team) {
      case LAB_RED:
        navigate(`/labs/red/${id}`)
        break
      case LAB_BLUE:
        navigate(`/labs/blue/${id}`)
        break
      case SIMULATION_BLUE:
        navigate(`/simulations/${id}`)
        break
      case SIMULATION_RED:
        navigate(`/simulations/${id}`)
        break
      case PRESENTATION_RED:
        navigate(`/learning/${id}`)
        break
      case QUIZ:
        navigate(`/quiz/${id}`)
        break
      case challanges:
        navigate(`/challenges/${id}`)
        break
      default:
        break
    }
    window.scroll(0, 0)
  }
  const filteredCompletedModules = completedModule.data.filter(
    (module: any) =>
      module.attributes.ModuleID === `${moduleId}` &&
      module.attributes.SubModuleId === `${data.id}` &&
      module.attributes.ModuleType === name &&
      module.attributes.Completion === true
  )

  return (
    <div
      className={`${classes.path} ${
        filteredCompletedModules.length > 0 ? classes.backgroundClr : ""
      } ${team ? classes.onHover : ""}`}
      style={{ backgroundColor: background }}
      onClick={() => {
        handleNavigate(data.id, name)
      }}
      aria-hidden
    >
      <div className={classes.topRight}>
        {filteredCompletedModules.length > 0 ? (
          <span className={classes.completedLabel}>COMPLETED</span>
        ) : (
          <span className={classes.newLabel}>NEW</span>
        )}
      </div>
      <div className={classes.item}>
        <Typography className={classes.pathName}>
          {data?.attributes?.Title}
        </Typography>
        <Row>
          <Col
            xl={13}
            lg={13}
            md={12}
            sm={24}
            xs={24}
            className={`${classes.info} ${
              name === "Challenges" ? classes.challengesWrap : ""
            }`}
          >
            <Typography.Paragraph className={classes.paragraph}>
              {data?.attributes?.Description}
            </Typography.Paragraph>
            {/* <div className={classes.labelContainer}>{data.label}</div> */}
            {name === "LearningUnit" && (
              <div className={classes.labelContainer}>
                <Label name="Learning Unit" color="rgb(50, 140, 87)" to="" />
              </div>
            )}
            {name === "PracticeLab" && (
              <div className={classes.labelContainer}>
                <Label name="Practice Lab" color="rgb(213, 85, 13)" to="" />
              </div>
            )}
            {name === "Simulation" && (
              <div className={classes.labelContainer}>
                <Label name="Simulation" color="rgb(79, 163, 203)" to="" />
              </div>
            )}
            {name === "Assessment" && (
              <div className={classes.labelContainer}>
                <Label name="Assessment" color="rgb(229, 137, 94)" to="" />
              </div>
            )}
            {name === "Challenges" && (
              <div className={classes.labelContainer}>
                <Label name="Challenge" color="rgb(229, 137, 94)" to="" />
              </div>
            )}
          </Col>
          <Col xl={7} lg={7} md={8} sm={24} xs={24} className={classes.para}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <div>
                  {name === "Challenges" ? null : (
                    <>
                      {" "}
                      <DurationIcon />
                      <Typography.Text className={classes.paraCourseName}>
                        Duration:
                      </Typography.Text>
                    </>
                  )}
                </div>
                <Typography.Text className={classes.paraCourseValue}>
                  {data?.attributes?.Duration
                    ? extractTime(data?.attributes?.Duration)
                    : ""}
                </Typography.Text>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <div>
                  {name === "Challenges" ? null : (
                    <>
                      <CategoryIcon />
                      <Typography.Text className={classes.paraCourseName}>
                        Category:
                      </Typography.Text>
                    </>
                  )}
                </div>
                <Typography.Text className={classes.paraCourseValue}>
                  {data?.attributes?.Category?.data?.attributes?.Name}
                </Typography.Text>
              </div>
            </div>
          </Col>
          {/* <Col
            xl={4}
            lg={4}
            md={4}
            sm={24}
            xs={24}
            className={classes.progress}
          >
            <Progress
              strokeLinecap="butt"
              type="circle"
              percent={0}
              className={classes.circle}
              strokeColor={percentToColor(0)}
              strokeWidth={8}
              trailColor="#35353D"
              width={135}
            />
          </Col> */}
        </Row>
      </div>
    </div>
  )
}
export default MyLearningPathsLesson
