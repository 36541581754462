import { getSession } from "../app/reducers/authSlice"

const APIS = {
  // headers
  headersApi: "header-info?populate=*",
  // My Module
  myModuleAPI:
    "learn-modules?populate[LearningUnit][populate]=*&populate[PracticeLab][populate]=*&populate[Simulation][populate]=*&populate[Assessment][populate]=*&populate[Category][populate]=*&populate[Challenges][populate]=*&populate=*&sort[0]=Sequence",
  learningUnitDetails: "learning-units",
  // ExerciseModules
  practiceLabslist:
    "practice-labs?populate[Category][populate]=*&populate[TeamType][populate]=*&populate=*&[ModuleName][populate]=*&sort[0]=Sequence&populate=*",
  practicelabDetails: "practice-labs",
  stumulationList: `/simulations?populate[Category][populate]=*&populate[TeamType][populate]=*&populate=*"`,
  stimulationDetails: `simulations`,

  landingApi: "learning-path-content?populate=*",

  // Assesment
  quizApi: "assessments",
  // challanges

  ChallangesApi: "challenges"
}

export { APIS }
