function LinkIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25085 7.74902C10.8446 9.34277 10.8446 11.9211 9.25085 13.5078C7.6571 15.0944 5.07876 15.1015 3.4921 13.5078C1.90543 11.914 1.89835 9.33569 3.4921 7.74902"
        stroke="#2787E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50121 9.4983C5.84371 7.8408 5.84371 5.14914 7.50121 3.48455C9.15871 1.81997 11.8504 1.82705 13.515 3.48455C15.1795 5.14205 15.1725 7.83372 13.515 9.4983"
        stroke="#2787E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LinkIcon
