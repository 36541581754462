export const API_URL = "https://manage-vm-api.azurewebsites.net/api/"
export const CREATE_VM_URL = "CreateVM"
export const LIST_VM_URL = "ListVM"
// const DOMAIN_URL = "https://www.cyberchallenge.au"
// const BASE_URL = "https://cms.cyberchallenge.au/api/"
// const IMAGE_BASE_URL = "https://cms.cyberchallenge.au"

// const DOMAIN_URL = "https://d6qbcs5ojtmam.cloudfront.net"
// const BASE_URL = "https://dev-cms.cyberchallenge.au/api/"
// const IMAGE_BASE_URL = "https://dev-cms.cyberchallenge.au"

const DOMAIN_URL = "https://www.cyberrange.com.au"
const BASE_URL = "https://cms.cyberrange.com.au/api/"
const IMAGE_BASE_URL = "https://cms.cyberrange.com.au"

export { BASE_URL, IMAGE_BASE_URL, DOMAIN_URL }
