import { Image, List, Tooltip, Typography } from "antd"
import { useNavigate } from "react-router-dom"

import { Skill } from "../../assets/imgs/index"
import DotIcon from "../../assets/svg/DotIcon"

import styles from "./SkillTooltip.module.scss"

const data = [
  {
    id: 1,
    title: "Completed Exercises",
    items: ["Opportunistic Threat Actors"]
  },
  {
    id: 2,
    title: "Recommended Exercises",
    items: ["Opportunistic Threat Actors", "Opportunistic Threat Actors"]
  }
]

function SkillTooltip({ ...restProps }) {
  const { children, title, id, link } = restProps

  const navigate = useNavigate()
  return (
    <Tooltip
      {...restProps}
      placement="right"
      overlayClassName="skillTooltipWrapper"
      color="#32323A"
      title={
        <div className={styles.tooltip}>
          <div className={styles.title}>{`Initial Access - > ${title}`}</div>
          <div className={styles.link}>
            <div className={styles.label}>
              <Image
                width={54}
                src={Skill || "error"}
                fallback={Skill}
                preview={false}
              />
            </div>
            <div className={styles.value}>
              <span>
                ID: {id}{" "}
                <a href={link} target="_blank" rel="noreferrer">
                  ({link})
                </a>
              </span>
            </div>
          </div>
          {data.map(list => (
            <div className={styles.list} key={list.id}>
              <div className={styles.label}>
                <span>{list.title}:</span>
              </div>
              <List
                dataSource={list.items}
                renderItem={item => (
                  <List.Item>
                    <Typography.Text>
                      <DotIcon />
                    </Typography.Text>
                    <span
                      className={styles.item}
                      aria-hidden
                      onClick={() => navigate("/exercises")}
                    >
                      {item}
                    </span>
                  </List.Item>
                )}
              />
            </div>
          ))}
        </div>
      }
    >
      {children}
    </Tooltip>
  )
}

export default SkillTooltip
