import classNames from "classnames/bind"

import { SUPPORTING_INFO_LINKS } from "../../SimulationsConstants"

import styles from "./styles.module.scss"

const cx = classNames.bind(styles)
interface SupportingInformationProps {
  supportingInformation: string
  modal?: boolean
}
function SupportingInformation({
  modal,
  supportingInformation
}: SupportingInformationProps) {
  return (
    <p
      className={cx(
        modal ? "briefing-modal-text" : "briefing-card-text",
        "mb-15"
      )}
      dangerouslySetInnerHTML={{
        __html: supportingInformation
      }}
    />
  )
}
SupportingInformation.defaultProps = {
  modal: false
}

export default SupportingInformation
