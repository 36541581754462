/* eslint-disable import/no-extraneous-dependencies */
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import { persistStore } from "redux-persist"

import persistedReducer from "./persistedReducer"

export const store = configureStore({
  reducer: persistedReducer
})
export const persistor = persistStore(store)
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
