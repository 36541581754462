/* eslint-disable react/no-unescaped-entities */
import classNames from "classnames/bind"
import { useEffect, useState } from "react"

import styles from "../../Labs.module.scss"

const cx = classNames.bind(styles)

interface ObjectivesProps {
  sets: string
}
function ContentFirst({ sets }: ObjectivesProps) {
  return (
    <div className={cx("content-text-container")}>
      <p
        className={cx("content-text")}
        dangerouslySetInnerHTML={{ __html: sets }}
        style={{ color: "#fff" }}
      />
    </div>
  )
}

export default ContentFirst
