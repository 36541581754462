import { Button, Col, Form, Input, Row } from "antd"
import React, { useEffect, useState } from "react"
import TextArea from "antd/lib/input/TextArea"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import TeamMessaage from "../../../assets/svg/TeamMessaage"
import { getChallangesResponse } from "../../../app/reducers/LearningSlice"
import { useAppSelector } from "../../../app/hooks"

import classes from "./styles.module.scss"

const formItemLayout = {
  labelCol: {
    span: 24
  }
}
function ResponseForm({
  ChallengeTitle,
  ChallangeID,
  questions,
  challengeForm,
  formValues,
  onFormValuesChange
}: any) {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const questionslist = questions
  const { user } = useAppSelector(state => state.user)
  const userId = user?.user?.id
  const [isDisable, setIsDisable] = useState(true)
  const { pageValue } = useAppSelector((state: [] | any) => state?.learningList)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pageValue])

  useEffect(() => {
    if (formValues) {
      form.setFieldsValue(formValues)
    }
  }, [formValues, form])
  const handleSubmit = () => {
    form.validateFields().then((values: { [key: string]: string }) => {
      const responseArray: { Question: string; Answer: string }[] = []
      Object.keys(values).forEach(key => {
        const Question = key?.trim()
        const Answer = values[key]
        responseArray.push({ Question, Answer })
      })

      const payloadChallenges = {
        ChallengeName: ChallengeTitle,
        UserId: userId,
        ChallengeId: ChallangeID,
        ChallengeResponse: responseArray,
        Username: user?.user?.username,
        Organization: user?.user?.organization
      }

      if (responseArray.length > 0) {
        dispatch<any>(getChallangesResponse(payloadChallenges))
      }
      if (challengeForm) {
        navigate("/")
      }
    })
  }
  useEffect(() => {
    if (Object.keys(formValues).length > 0) {
      setIsDisable(false)
    }
  }, [formValues])
  const handleInputChange = (e: any) => {
    setIsDisable(false)
    onFormValuesChange(form.getFieldsValue(formValues))
  }
  const handleInputTextAreaChange = (e: any) => {
    setIsDisable(false)
    form.setFieldsValue({
      [e.target.name]: e.target.value
    })
    onFormValuesChange(form.getFieldsValue(formValues))
  }

  return (
    <div>
      <div className={`${classes.container} ${classes.profileWrapper}`}>
        <Row>
          <Col span={24}>
            <div className={classes.box}>
              <Form form={form} {...formItemLayout} onFinish={handleSubmit}>
                {questionslist?.map((item: any) => {
                  let question
                  if (item.QuestionType === "Small") {
                    question = (
                      <Input
                        name="firstname"
                        onChange={handleInputChange}
                        prefix={
                          <span className={classes.inputIcon}>
                            <TeamMessaage />
                          </span>
                        }
                      />
                    )
                  }
                  if (item.QuestionType === "Large") {
                    question = (
                      <div className={classes.newTextArea}>
                        <span className={classes.textAreaicon}>
                          <TeamMessaage />
                        </span>
                        <Input.TextArea
                          name={item.Question}
                          rows={4}
                          onChange={handleInputTextAreaChange}
                          value={
                            (formValues && formValues[item.Question]) || ""
                          }
                        />
                      </div>
                    )
                  }
                  return (
                    <Form.Item
                      key={item.id}
                      label={item.Question}
                      name={item.Question}
                      rules={[
                        {
                          required: true,
                          message: "Please enter an answer"
                        }
                      ]}
                    >
                      {question}
                    </Form.Item>
                  )
                })}

                <Form.Item style={{ display: "flex", justifyContent: "end" }}>
                  <Button type="primary" htmlType="submit" disabled={isDisable}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ResponseForm
