/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

import axiosRequest from "../../api/axiosInterceptors"
import { APIS } from "../../api/ApisCollections"
import { LearningModulePath } from "../../pages/Home/type"
import { BASE_URL } from "../../api/config"

import { getSession } from "./authSlice"

const base64 = require("base-64")

export interface ExercisesModule {
  exercises: any
  excerciseDetail: any
  simulationsList: any
  stimulationDetails: any
  templates: any
  clone: any
  activeLab: any
  loading: boolean
  error: string | undefined
  activeLabList: any
  indexValue: number
  multiPage: boolean
  vncInfo: any
  assesmentResponse: any
  checkActiveLabData: any
  assignedLabList: any
}

export interface ExerciseList {
  id: number
  pathName: string
  paragraph: string
  duration: string
  category: string
  percent: number
  activated: boolean
  type: string
}

export interface Simulations {
  simulations: any[]
}

const initialState: ExercisesModule = {
  exercises: [],
  excerciseDetail: [],
  simulationsList: [],
  stimulationDetails: [],
  loading: false,
  error: "",
  templates: [],
  clone: [],
  activeLab: [],
  activeLabList: [],
  indexValue: 0,
  multiPage: false,
  vncInfo: [],
  assesmentResponse: [],
  checkActiveLabData: [],
  assignedLabList: []
}

export interface payloadSubmitYourResponseProps {
  data: {
    UserId: number
    PracticeLabName: string
    AssessmentResponse: any
    Username: string
    Organization: string
    PracticeLabId: number
  }
}

export const fetchExerciseList = createAsyncThunk(
  "exerciseModule/fetchExerciseList",
  async () => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get<ExerciseList[]>(
        `${APIS.practiceLabslist}`,
        { headers }
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)
export const fetchPracticeLabDetail = createAsyncThunk(
  "exerciseModule/fetchPracticeLabDetail",
  async (practiceId: number) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get<LearningModulePath[]>(
        `${APIS.practicelabDetails}/${practiceId}?populate[Category][populate]=*&populate[TeamType][populate]=*&populate[OtherModules][populate]=*&populate[PracticeAssessmentQuestions][populate]=*&populate[BriefInfo][populate]=*&populate[ImageLegend][populate]=*&populate[DetailsPage][populate]=*&populate=*&populate[PracticeLabDetailPages][populate]=*`,
        { headers }
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)

export const fetchSimulations = createAsyncThunk(
  "exerciseModule/fetchSimulations",
  async () => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get<ExerciseList[]>(
        `${APIS.stumulationList}`,
        { headers }
      )

      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)
export const fetchStiMulationDetails = createAsyncThunk(
  "exerciseModule/fetchStiMulationDetails",
  async (simulationId: number) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get<LearningModulePath[]>(
        `${APIS.stimulationDetails}/${simulationId}?populate[Category][populate]=*&populate[TeamType][populate]=*&populate[Objectives][populate]=*&populate[BriefingNote][populate]=*&populate[DetailsPage][populate]=*&populate[StepsToSimulationDetail][populate]=*&populate&populate=*`,

        { headers }
      )

      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)
// Labs
export const getTemplates = createAsyncThunk(
  "exerciseModule/getTemplates",
  async () => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }

    try {
      const response = await axios.get(`${BASE_URL}practice-lab/getTemplates`, {
        headers
      })

      return response?.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)
export const getCloneTemplate = createAsyncThunk(
  "exerciseModule/getCloneTemplate",
  async (payload: any) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get(
        `/practice-lab/getClone?user_cms_name=${payload.userName}&template_name=${payload.templatesName}`,
        { headers }
      )

      return response
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)

export const getActiveLab = createAsyncThunk(
  "exerciseModule/getActiveLab",
  async (userName: any) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get(
        `${BASE_URL}practice-lab/getActiveLab?user_cms_name=${userName}`,
        { headers }
      )
      if (response?.data?.result) {
        const element = document.createElement("a")
        const file = new Blob([`${response?.data?.result}`], {
          type: "text/plain"
        })
        element.href = URL.createObjectURL(file)
        element.download = `${userName}.vv`
        document.body.appendChild(element)
        element.click()
      }

      return response?.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)

export const checkActiveLab = createAsyncThunk(
  "exerciseModule/checkActiveLab",
  async (userName: any) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get(
        `${BASE_URL}practice-lab/checkActiveLab?user_cms_name=${userName}`,
        { headers }
      )

      return response?.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)

export const checkAssignedLab = createAsyncThunk(
  "exerciseModule/checkAssignedLab",
  async (userName: any) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get(
        `${BASE_URL}practice-lab/checkActiveLab?user_cms_name=${userName}`,
        { headers }
      )

      return response?.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)

export const getVNC = createAsyncThunk(
  "exerciseModule/getVNC",
  async (payload: any) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get(
        `/practice-lab/getVNC?user_cms_name=${payload}`,
        { headers }
      )
      if (response?.data?.result?.redirect_url) {
        window.open(
          `${response?.data?.result?.redirect_url}`,
          "_blank",
          "noopener,noreferrer"
        )
      }
      return response
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)

export const submittedAssementResponse = createAsyncThunk(
  "learningModule/submittedAssementResponse",
  async ({ payload }: { payload: payloadSubmitYourResponseProps }) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.post(
        `${BASE_URL}practice-lab-assessment-responses`,
        payload,
        { headers }
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)

export const exerciseSlice = createSlice({
  name: "exerciseModule",
  initialState,
  reducers: {
    resetExerciseState(state) {
      return initialState
    },
    resetCloneState(state) {
      state.clone = []
    },
    resetActiveLabState(state) {
      state.activeLab = []
    },
    resetTemplateState(state) {
      state.templates = []
    },
    resetCheckActiveLabState(state) {
      state.activeLabList = []
    },
    resetCheckActiveLabData(state) {
      state.checkActiveLabData = []
    },
    setIndexvalue(state, action) {
      state.indexValue = action.payload
    },
    setMultiPage(state, action) {
      state.multiPage = action.payload
    },
    resetVncInfoState(state) {
      state.vncInfo = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchExerciseList.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(fetchExerciseList.fulfilled, (state, action) => {
        state.loading = false
        state.exercises = action.payload
      })
      .addCase(fetchExerciseList.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchPracticeLabDetail.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(fetchPracticeLabDetail.fulfilled, (state, action) => {
        state.loading = false
        state.excerciseDetail = action.payload
      })
      .addCase(fetchPracticeLabDetail.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchSimulations.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(fetchSimulations.fulfilled, (state, action) => {
        state.loading = false
        state.simulationsList = action.payload
      })
      .addCase(fetchSimulations.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchStiMulationDetails.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(fetchStiMulationDetails.fulfilled, (state, action) => {
        state.loading = false
        state.stimulationDetails = action.payload
      })
      .addCase(fetchStiMulationDetails.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(getTemplates.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(getTemplates.fulfilled, (state, action) => {
        state.loading = false
        state.templates = action.payload
      })
      .addCase(getTemplates.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(getCloneTemplate.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(getCloneTemplate.fulfilled, (state, action) => {
        state.loading = false
        state.clone = action.payload
      })
      .addCase(getCloneTemplate.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(getActiveLab.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(getActiveLab.fulfilled, (state, action) => {
        state.loading = false
        state.activeLab = action.payload
      })
      .addCase(getActiveLab.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(checkActiveLab.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(checkActiveLab.fulfilled, (state, action) => {
        state.loading = false
        state.activeLabList = action.payload
        state.checkActiveLabData = action.payload
      })
      .addCase(checkActiveLab.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(checkAssignedLab.pending, (state, action) => {
        state.error = ""
      })
      .addCase(checkAssignedLab.fulfilled, (state, action) => {
        state.assignedLabList = action.payload
        state.checkActiveLabData = action.payload
      })
      .addCase(checkAssignedLab.rejected, (state, action) => {
        state.error = action.error.message
      })
      .addCase(getVNC.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(getVNC.fulfilled, (state, action) => {
        state.loading = false
        state.vncInfo = action.payload
      })
      .addCase(getVNC.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(submittedAssementResponse.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(submittedAssementResponse.fulfilled, (state, action) => {
        state.loading = false
        state.assesmentResponse = action.payload
      })
      .addCase(submittedAssementResponse.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export default exerciseSlice.reducer
export const {
  resetExerciseState,
  resetCloneState,
  resetActiveLabState,
  resetTemplateState,
  resetCheckActiveLabState,
  setIndexvalue,
  setMultiPage,
  resetCheckActiveLabData,
  resetVncInfoState
} = exerciseSlice.actions
