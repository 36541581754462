import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"

import { fetchQuiz } from "../../app/reducers/LearningSlice"
import { useAppDispatch } from "../../app/hooks"

import QuizSlides from "./QuizSlide/Slides"
import { QUIZ_LIST } from "./QuizObj"
import styles from "./style.module.scss"

function Quiz() {
  const { id } = useParams()

  const [titleNew, setTitleNew] = useState<string | null>("")
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const handleIndexChange = (index: number) => {
    setSelectedIndex(index)
  }

  const { loading, QuizList, quizList } = useSelector(
    (state: [] | any) => state.learningList
  )

  const dispatch = useAppDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      if (id && id !== "") {
        try {
          const parsedId = parseInt(id, 10)
          await dispatch(fetchQuiz(parsedId))
        } catch (error) {
          console.error("Error fetching practice lab detail:", error)
        }
      }
    }

    fetchData()
  }, [dispatch, id])

  const title = quizList?.data?.attributes?.Title
  const dataAssessment = quizList?.data?.attributes?.Assessment
  return (
    <div className={styles.background}>
      {" "}
      <div>
        {/* <HeaderTitle text={titleNew || ""} /> */}

        <QuizSlides
          sets={dataAssessment}
          titleNew={`${title}`}
          selectedIndex={selectedIndex}
          handleIndexChange={handleIndexChange}
          quizId={id}
        />
      </div>
    </div>
  )
}

export default Quiz
