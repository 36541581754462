import { useState } from "react"

import { CustomModal } from "../assets/css/styled"
import CloseIconSm from "../assets/svg/CloseIconSm"
import {
  Card,
  CustomCountdown,
  MainButton,
  MsTeamButton,
  OutlineButton,
  SecondaryButton
} from "../components"

function Component() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <div className="list-component">
      <h1 style={{ color: "#fff", textAlign: "center" }}>LIST COMPONENTS</h1>
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
          gap: 20,
          color: "black"
        }}
      >
        My Custom Countdown
        <CustomCountdown minute={0} second={5} />
      </div>
      <MainButton>Main Button</MainButton>
      <SecondaryButton>Secondary Button</SecondaryButton>
      <OutlineButton>Outline Button</OutlineButton>
      <MsTeamButton>MsTeam Button</MsTeamButton>
      <Card style={{ width: "30%", height: 200, margin: "auto" }}>Card</Card>
      <MainButton onClick={showModal}>Open CustomModal</MainButton>
      <CustomModal
        title="Basic Modal"
        closeIcon={<CloseIconSm />}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </CustomModal>
      <br />
      <br />
      <div style={{ backgroundColor: "white" }}>
        <CustomCountdown minute={10} />
        <div
          style={{
            height: 10,
            width: 215.63,
            backgroundColor: "red",
            margin: "0 auto"
          }}
        />
      </div>
    </div>
  )
}

export default Component
