/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Row, Typography } from "antd"

import DescTableIcon from "../../assets/svg/DescTableIcon"
import { HeaderTitle, SkillTooltip } from "../../components"

import classes from "./styles.module.scss"

const headerTable = [
  "Initial Access",
  "Execution",
  "Persistence",
  "Privilege Escalation",
  "Defense Evasion",
  "Credential Access",
  "Discovery",
  "Lateral Movement",
  "Collection"
]

const data = {
  col1: [
    {
      name: "Drive-by Compromise",
      isSub: true
    },
    {
      name: "Exploit Public-Facing App...",
      isSub: false
    },
    {
      name: "External Remote Services",
      color: "#FFE195",
      isSub: false
    },
    {
      name: "Phishing",
      isSub: false
    },
    {
      name: "Replication Through Removable Media",
      isSub: false
    },
    {
      name: "Supply Chain Compromise",
      color: "#F67384",
      isSub: false
    },
    {
      name: "Supply Chain Compromise",
      isSub: false
    },
    {
      name: "Trusted Relationship",
      isSub: false
    },
    {
      name: "Valid Accounts",
      isSub: false
    }
  ],
  col2: [
    {
      name: "Command and Scripting Interpreter",
      color: "#51BD7E",
      isSub: false
    },
    {
      name: "Inter-Process Communication",
      isSub: true
    },
    {
      name: "Native API",
      isSub: false
    },
    {
      name: "Schedule Task/Job",
      isSub: false
    },
    {
      name: "Shared Modules",
      isSub: false
    },
    {
      name: "Software Deployment Tools",
      isSub: false
    },
    {
      name: "Command and Scripting Interpreter",
      isSub: true
    },
    {
      name: "System Services",
      isSub: false
    },
    {
      name: "User Execution",
      isSub: false
    },
    {
      name: "Windows Management Ins...",
      isSub: false
    }
  ],
  col3: [
    {
      name: "Account Manipulation",
      isSub: false
    },
    {
      name: "BITS Jobs",
      isSub: false
    },
    {
      name: "Browser Extensions",
      isSub: true
    },
    {
      name: "Compromise Client Siftware Binary",
      color: "#F67384",
      isSub: false
    },
    {
      name: "Create Account",
      isSub: true
    },
    {
      name: "Create or Modify System Process",
      isSub: false
    },
    {
      name: "BITS Jobs",
      isSub: false
    },
    {
      name: "External Remote Services",
      isSub: false
    },
    {
      name: "Hijack Execution Flow",
      isSub: true
    },
    {
      name: "Implant Container Image",
      isSub: false
    },
    {
      name: "Office Application Startup",
      isSub: false
    }
  ],
  col4: [
    {
      isSub: true,
      name: "Abuse elevation Control Mechanism"
    },
    {
      name: "Access Token Manipulation",
      color: "#FFB795",
      isSub: false
    },
    {
      isSub: true,
      name: "Boot or Logon Initialization Scripts"
    },
    {
      isSub: true,
      name: "Created or Modify System Process"
    },
    {
      name: "Event Triggered Execution",
      color: "#51BD7E",
      isSub: false
    },
    {
      isSub: true,
      name: "Explitation for Privilege Escalation"
    },
    {
      isSub: true,
      name: "Group Policy Modification"
    },
    {
      isSub: true,
      name: "Hijack Execution Flow"
    },
    {
      isSub: true,
      name: "Process Injection"
    },
    {
      isSub: true,
      name: "Schedule Task/Job"
    },
    {
      isSub: true,
      name: "Valid Account"
    }
  ],
  col5: [
    {
      name: "Drive-by Compromise",
      isSub: false
    },
    {
      name: "Command and Scripting Interpreter",
      isSub: false
    },
    {
      name: "Account Manipulation",
      isSub: false
    },
    {
      name: "Abuse elevation Control Mechanism",
      isSub: false
    },
    {
      name: "Abuse elevation Control Mechanism",
      isSub: false
    },
    {
      name: "Brute Force",
      isSub: false
    },
    {
      name: "Account Discovery",
      isSub: false
    },
    {
      name: "Exploatation of Remote Services",
      isSub: false
    },
    {
      name: "Archive Collected Data",
      isSub: false
    },
    {
      name: "Access Token Manipulation",
      isSub: false
    },
    {
      name: "BITS Jobs",
      isSub: false
    },
    {
      name: "Deobfuscate/Decode Files or Informa...",
      isSub: false
    },
    {
      name: "Direct Volume Access",
      isSub: false
    },
    {
      name: "Execution Guardrails",
      isSub: false
    },
    {
      name: "Explitation for Defense Evasion",
      isSub: false
    },
    {
      name: "File and Directory Permissions Mo...",
      isSub: false
    },
    {
      name: "Hide Artifacts",
      isSub: false
    },
    {
      name: "Hijack Execution Flow",
      isSub: false
    },
    {
      name: "Access Token Manipulation",
      isSub: false
    },
    {
      name: "Impair Defense",
      isSub: false
    }
  ],
  col6: [
    {
      name: "Brute Force",
      isSub: false
    },
    {
      name: "Credentials From Password Stores",
      isSub: false
    },
    {
      name: "Exploitation for Credential Access",
      isSub: false
    },
    {
      name: "Forced Authentication",
      isSub: false
    },
    {
      name: "Input Capture",
      color: "#51BD7E",
      isSub: false
    },
    {
      name: "Man-in-the-Middle",
      isSub: false
    },
    {
      name: "Modify Authentication",
      color: "#FFB795",
      isSub: false
    },
    {
      name: "Network Sniffing",
      isSub: false
    },
    {
      name: "OS Credential Drumping",
      isSub: false
    },
    {
      name: "Steal Application Access Toke",
      isSub: false
    },
    {
      name: "Steal or Forge Kerberos Tickets",
      color: "#51BD7E",
      isSub: false
    },
    {
      name: "Unsecured Credentials",
      isSub: false
    }
  ],
  col7: [
    {
      name: "Account Discovery",
      isSub: true
    },
    {
      name: "Application Window Discovery",
      isSub: false
    },
    {
      name: "Browser Bookmark Discovery",
      isSub: true
    },
    {
      name: "Cloud Service Dashboard",
      isSub: false
    },
    {
      name: "Cloud Service Dashboard",
      isSub: true
    },
    {
      name: "Domain Trust Discovery",
      isSub: false
    },
    {
      name: "Domain Trust Discovery",
      isSub: true
    },
    {
      name: "Password Policy Discovery",
      isSub: false
    },
    {
      name: "Password Policy Discovery",
      isSub: true
    },
    {
      name: "System Network Configuration...",
      isSub: false
    },
    {
      name: "System Service Discovery",
      isSub: false
    },
    {
      name: "System Time Discove",
      isSub: false
    }
  ],
  col8: [
    {
      name: "Exploatation of Remote Services",
      isSub: false
    },
    {
      name: "Internal Sprearphishing",
      isSub: false
    },
    {
      name: "Remote Service Session Hijacking",
      color: "#FFE195",
      isSub: false
    },
    {
      name: "Remote Service Session Hijacking",
      isSub: false
    },
    {
      name: "Remote Services",
      isSub: false
    },
    {
      name: "Replication Through Remova...",
      isSub: false
    },
    {
      name: "Software Deployment Tools",
      isSub: false
    },
    {
      name: "Taint Shared Content",
      color: "#B2E5C7",
      isSub: false
    },
    {
      name: "User Alternate Authentification M...",
      isSub: false
    }
  ],
  col9: [
    {
      name: "Archive Collected Data",
      isSub: false
    },
    {
      name: "Audio Capture",
      isSub: true
    },
    {
      name: "Automated Collection",
      isSub: true
    },
    {
      name: "Clipboard Data",
      isSub: false
    },
    {
      name: "Data from Cloud Storage Object",
      isSub: false
    },
    {
      name: "Data from Information Repo...",
      isSub: true
    },
    {
      name: "Data from Local System",
      isSub: true
    },
    {
      name: "Data from Network Shared Drive",
      isSub: true
    },
    {
      name: "Data from Removable Media",
      isSub: true
    },
    {
      name: "Data Staged",
      isSub: false
    },
    {
      name: "Email Collection",
      isSub: false
    },
    {
      name: "Input Captur",
      isSub: true
    }
  ]
}

function Skills() {
  const maxItemCount = (input: any) =>
    Math.max(...Object.values(input).map((arr: any) => arr.length))

  const orderTableKey = "col1 col2 col3 col4 col5 col6 col7 col8 col9".split(
    " "
  )

  const getTableRow = (input: any) =>
    Array.from({ length: maxItemCount(input) }).map((row, index) =>
      orderTableKey.map(col => input[col][index] ?? null)
    )

  return (
    <div className={classes.container}>
      <Row>
        <Col span={24}>
          <HeaderTitle text="Skills" />
        </Col>
        <Col span={24} className={classes.flex}>
          <div className={classes.skillDesc}>
            <Typography.Text className={classes.completionRate}>
              Completion Rate
            </Typography.Text>
            <div className={classes.rate}>
              <Typography.Text>0%</Typography.Text>
              <DescTableIcon className={classes.DescTableIcon} />
              <Typography.Text>100%</Typography.Text>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className={classes.box}>
            <div className={classes.scroll}>
              <table className={classes.skillTable}>
                <thead>
                  <tr>
                    {headerTable.map(item => (
                      <th className={classes.titleTable} key={item}>
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {getTableRow(data).map((row: any) => (
                    <tr key={`row${Math.random()}`}>
                      {row.map((item: any) => {
                        if (item === null) {
                          return <td key={`item${Math.random()}`} />
                        }
                        if (item.isSub) {
                          return (
                            <td
                              key={`item${Math.random()}`}
                              style={{
                                opacity: 0.5
                              }}
                            >
                              {item?.name || ""}
                            </td>
                          )
                        }
                        return (
                          <SkillTooltip
                            title={item?.name || ""}
                            id="T1133"
                            link="https://attack.mitre.org/techniques/T113"
                            key={`item${Math.random()}`}
                          >
                            <td
                              style={{
                                backgroundColor: item?.color
                                  ? item?.color
                                  : "transparent",
                                cursor: "pointer"
                              }}
                            >
                              {item?.name || ""}
                            </td>
                          </SkillTooltip>
                        )
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
export default Skills
