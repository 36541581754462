import { Col, Row } from "antd"
import React, { useEffect, useState } from "react"

import { useAppDispatch, useAppSelector } from "../../../app/hooks"
// import {
//   fetchScoreBoard,
//   fetchUserCount
// } from "../../app/reducers/LearningSlice"
import { setSelectedAnswerList } from "../../../app/reducers/LearningSlice"

import classes from "./style.module.scss"

function QuestionOrder({ data, srNo }: any) {
  const dispatch = useAppDispatch()

  const { questionsList, loading, selectedAnswerList } = useAppSelector(
    state => state.learningList
  )

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  const handleReorder = (selectedId: any) => {
    const from = "re-order"
    dispatch(setSelectedAnswerList({ selectedId, srNo, from }))
  }

  const handleRemoveReorder = (selectedId: any) => {
    const from = "remove-re-order"
    dispatch(setSelectedAnswerList({ selectedId, srNo, from }))
  }

  return (
    <div>
      <Row>
        <Row className={classes.questionWrapper}>
          <Col lg={24} md={24}>
            <div className={classes.questionName}>
              {srNo}. {data?.Title}
            </div>
            <div className={classes.questionDescription}>
              {data?.Description}
            </div>
            {selectedAnswerList[srNo]?.map((selected: any) => (
              <span
                className={`${classes.questionOptionsOrderSelected} ${classes.cp}`}
                onClick={() => handleRemoveReorder(selected)}
                aria-hidden
              >
                {selected}
              </span>
            ))}
            {data.Options.map((item: any) => (
              <span
                title="click to re-arrange"
                className={`${classes.questionOptionsOrder} ${classes.cp} ${
                  selectedAnswerList[srNo]?.includes(item.Title)
                    ? `${classes.hide}`
                    : ``
                }`}
                onClick={() => handleReorder(item.Title)}
                aria-hidden
              >
                {item.Title}
              </span>
            ))}
          </Col>
        </Row>
      </Row>
    </div>
  )
}

export default QuestionOrder
