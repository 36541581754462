import classNames from "classnames/bind"

import styles from "../styles.module.scss"

const cx = classNames.bind(styles)

function RemainingStep5() {
  return (
    <div className={cx("remaining-mess-container")}>
      <p className={cx("remaining-mess")}>
        The attack has been contained. The attacker tried to increase chrorine
        level to levels which were considered to be unsafe, and of the operators
        was able to react and manually override the change.
      </p>
      <p className={cx("remaining-mess")}>
        Complete the simulation and proceed to the debrief section.
      </p>
    </div>
  )
}

export default RemainingStep5
