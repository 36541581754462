import { Button } from "antd"
import classNames from "classnames"

import styles from "./SecondaryButton.module.scss"

function SecondaryButton({ ...restProps }) {
  return (
    <Button
      {...restProps}
      className={classNames(styles.button, restProps.className)}
    />
  )
}

export default SecondaryButton
