function TickCircleIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00001 15.0001C11.6667 15.0001 14.6667 12.0546 14.6667 8.45463C14.6667 4.85463 11.6667 1.90918 8.00001 1.90918C4.33334 1.90918 1.33334 4.85463 1.33334 8.45463C1.33334 12.0546 4.33334 15.0001 8.00001 15.0001Z"
        fill="#CFCFCF"
      />
      <path
        d="M5.16666 8.45441L7.05332 10.3068L10.8333 6.60205"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TickCircleIcon
