import classNames from "classnames/bind"

import { Ellipse } from "../../../../assets/imgs"
import { CustomCountdown } from "../../../../components"

import styles from "./styles.module.scss"

const cx = classNames.bind(styles)

interface CountingdownProps {
  onFinish: () => void
}

function Countingdown({ onFinish }: CountingdownProps) {
  return (
    <>
      <p className={cx("launch-text")}>
        Your simulation is ready and will start soon.
        <br /> Waiting for your team mates to connect...
      </p>
      <div className={cx("loading-icon-container", "counting-down")}>
        <img className={cx("loading-icon")} src={Ellipse} alt="loading" />
        <p className={cx("loading-text")}>Loading...</p>
      </div>
      <div className={cx("countdown-container")}>
        <p className={cx("launch-text", "mb-20")}>
          Your Simulation Will Start in
        </p>
        <CustomCountdown minute={0} second={5} onComplete={onFinish} />
      </div>
    </>
  )
}

export default Countingdown
