function DurationIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.9">
        <path
          d="M17.2917 11.0417C17.2917 15.0667 14.025 18.3333 9.99999 18.3333C5.97499 18.3333 2.70833 15.0667 2.70833 11.0417C2.70833 7.01667 5.97499 3.75 9.99999 3.75C14.025 3.75 17.2917 7.01667 17.2917 11.0417Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 6.66667V10.8333"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 1.66667H12.5"
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default DurationIcon
