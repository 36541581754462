function DurationLgIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path
          d="M24.2087 15.4583C24.2087 21.0933 19.6353 25.6667 14.0003 25.6667C8.36533 25.6667 3.79199 21.0933 3.79199 15.4583C3.79199 9.82333 8.36533 5.25 14.0003 5.25C19.6353 5.25 24.2087 9.82333 24.2087 15.4583Z"
          stroke="#D3D3D5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 9.3335V15.1668"
          stroke="#D3D3D5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 2.3335H17.5"
          stroke="#D3D3D5"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default DurationLgIcon
