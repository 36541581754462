import React, { useState } from "react"
import classNames from "classnames/bind"

import { CustomModal } from "../../../../assets/css/styled"
import { Card } from "../../../../components"
import { CloseIconSm } from "../../../../assets/svg"

import styles from "./styles.module.scss"

import BriefingNote from "."

const cx = classNames.bind(styles)
interface BriefingNoteModalProps {
  breifingText: string
}
function BriefingNoteModal({ breifingText }: BriefingNoteModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOkModal = () => {
    setIsModalOpen(false)
  }
  const handleCancelModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Card className={cx("sidebar-content", "onHover")} onClick={showModal}>
        <div className={cx("briefing-button")}>Briefing note</div>
      </Card>
      <CustomModal
        title="Briefing note"
        centered
        closeIcon={<CloseIconSm />}
        open={isModalOpen}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        className={cx("briefing-modal")}
      >
        <BriefingNote modal breifingText={breifingText} />
      </CustomModal>
    </>
  )
}

export default BriefingNoteModal
