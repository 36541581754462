/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit"

const labsState = {
  isValidated: false,
  answers: {}
}

export const labsSlice = createSlice({
  name: "labs",
  initialState: labsState,
  reducers: {
    setValidated: (state, action) => {
      state.isValidated = action.payload
    },
    setAnswers: (state, action) => {
      state.answers = action.payload
    }
  }
})
export const labsActions = labsSlice.actions
export default labsSlice.reducer
