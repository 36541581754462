function KeyboardOpenIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.9">
        <path
          d="M6.04998 1.66666H13.9417C14.4833 1.66666 14.9667 1.68335 15.4 1.74169C17.7083 2.00002 18.3333 3.08334 18.3333 6.05001V11.3167C18.3333 14.2833 17.7083 15.3667 15.4 15.625C14.9667 15.6833 14.4917 15.7 13.9417 15.7H6.04998C5.50832 15.7 5.02498 15.6833 4.59165 15.625C2.28332 15.3667 1.65833 14.2833 1.65833 11.3167V6.05001C1.65833 3.08334 2.28332 2.00002 4.59165 1.74169C5.02498 1.68335 5.50832 1.66666 6.04998 1.66666Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3167 6.93333H14.3833"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.61665 11.7583H5.63331H14.3917"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.83333 18.3333H14.1667"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.99559 6.91667H6.00307"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.74542 6.91667H8.7529"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
export default KeyboardOpenIcon
