import { useNavigate } from "react-router-dom"

import styles from "./styles.module.scss"

function Label({ ...restProps }) {
  const navigate = useNavigate()
  const handleNavigate = () => {
    if (restProps.to) navigate(restProps.to)
  }
  return (
    <div
      onClick={handleNavigate}
      className={styles.label}
      style={{
        background: restProps.outline ? "transparent" : restProps.color,
        width: "auto",
        padding: "0px 15px",
        border: `1px solid ${
          restProps.outline ? restProps.outline : "transparent"
        }`,
        color: restProps.outline ? restProps.outline : "white",
        cursor: restProps.cursor ? "pointer" : "default"
      }}
      {...restProps}
      aria-hidden
    >
      {restProps.icon && <div className={styles.icon}>{restProps.icon}</div>}
      {restProps.name}
    </div>
  )
}

export default Label
