/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Progress, Row, Typography } from "antd"
import { useNavigate } from "react-router-dom"

import {
  BlueTeamIcon,
  CategoryIcon,
  DurationIcon,
  RedTeamIcon
} from "../../../assets/svg"
import { Card, Label } from "../../../components"
import { percentToColor } from "../../../helpers/percentToColor"
import { LearningModulePath } from "../../Home/type"
import { extractTime, uniqueId } from "../../../utils/Common/timeextract"
import {
  setModuleType,
  setSubModuleId
} from "../../../app/reducers/LearningSlice"
import { useAppDispatch } from "../../../app/hooks"

import classes from "./styles.module.scss"

function LessonItem({
  data,
  labtype,
  completedModule
}: {
  labtype: string
  data: any
  completedModule: any
}) {
  const category = data?.attributes?.Category?.data?.attributes?.Name
  const labelTeam = data?.attributes?.TeamType?.data?.attributes?.Name
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const handleNavigate = (id: number) => {
    dispatch(setSubModuleId(id))
    dispatch(setModuleType(labtype))
    switch (labelTeam && labtype) {
      case "Red Team" && "PracticeLab":
        navigate(`/labs/red/${id}`)
        break
      case "Blue Team" && "PracticeLab":
        navigate(`/labs/blue/${id}`)
        break
      case "Red Team" && "Simulation":
        navigate(`/simulations/${id}`)
        break
      case "Blue Team" && "Simulation":
        navigate(`/simulations/${id}`)
        break
      default:
        break
    }
    window.scroll(0, 0)
  }
  const filteredCompletedModules = completedModule.data.filter(
    (module: any) =>
      module.attributes.ModuleType === labtype &&
      module.attributes.Completion === true &&
      module.attributes.SubModuleId === `${data.id}`
  )
  return (
    <Card
      className={`${classes.card} ${
        filteredCompletedModules.length > 0 ? classes.backgroundClr : ""
      }  ${labelTeam ? classes.onHover : ""}`}
      style={{ position: "relative" }}
      onClick={() => handleNavigate(data.id)}
    >
      <div className={classes.topRight}>
        {filteredCompletedModules.length > 0 ? (
          <span className={classes.completedLabel}>COMPLETED</span>
        ) : (
          <span className={classes.newLabel}>NEW</span>
        )}
      </div>
      <Typography className={classes.pathName}>
        {data?.attributes?.Title}
      </Typography>
      <Row>
        <Col xl={13} lg={13} md={12} sm={24} xs={24} className={classes.info}>
          <Typography.Paragraph className={classes.paragraph}>
            {data?.attributes?.Description}
          </Typography.Paragraph>
          <div className={classes.labelContainer}>
            {labtype === "PracticeLab" && (
              <Label name="Practice Lab" color="#D5550D" to="/labs/red" />
            )}

            {labtype === "Simulation" && (
              <Label name="Simulations" color="#4fa3cb" to="/labs/blue" />
            )}
            {labelTeam === "Red Team" && (
              <Label
                name={labelTeam}
                icon={<RedTeamIcon />}
                outline="#ed223c"
              />
            )}
            {labelTeam === "Blue Team" && (
              <Label
                name={labelTeam}
                icon={<BlueTeamIcon />}
                outline="#4fa3cb"
              />
            )}
          </div>
        </Col>
        <Col xl={7} lg={7} md={8} sm={24} xs={24} className={classes.para}>
          <div style={{ paddingLeft: "30px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <div>
                <DurationIcon />
                <Typography.Text className={classes.paraCourseName}>
                  Duration:
                </Typography.Text>
              </div>
              <Typography.Text className={classes.paraCourseValue}>
                {data?.attributes?.Duration
                  ? extractTime(data.attributes.Duration)
                  : ""}
                {}
              </Typography.Text>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <div>
                <CategoryIcon />
                <Typography.Text className={classes.paraCourseName}>
                  Category:
                </Typography.Text>
              </div>
              <Typography.Text className={classes.paraCourseValue}>
                {category}
              </Typography.Text>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  )
}
export default LessonItem
