/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, List, Progress, Row, Typography } from "antd"
import classNames from "classnames"
import { useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"

import {
  CategoryIcon,
  DurationIcon,
  KeyboardOpenIcon,
  MinusIcon,
  PracticeLabIcon,
  RealTimeIcon
} from "../../../../assets/svg"
import ExpandIcon from "../../../../assets/svg/ExpandIcon"
import Label from "../../../../components/Label"
import { percentToColor } from "../../../../helpers/percentToColor"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { extractTime } from "../../../../utils/Common/timeextract"
import { setModuleId } from "../../../../app/reducers/LearningSlice"
import { challanges } from "../../data"

import styles from "./styles.module.scss"

interface ItemPathProps {
  data: any
  background: string
  index: number
}

function LearningPathsCourse({ data, background, index }: ItemPathProps) {
  const [categoryData, setcategory] = useState()
  const [durationData, setDuration] = useState()
  const [simulationsData, setSimulations] = useState(0)
  const [labData, setLab] = useState(0)
  const [assesmentData, setAssessments] = useState(0)
  const [learning, setLearning] = useState(0)
  const [Challenges, setChallanges] = useState(0)
  const { myModule, completedModule } = useAppSelector(
    (state: [] | any) => state?.learningList
  )

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (myModule?.data) {
      const category = myModule?.data?.map(
        (item: any) => item?.attributes?.Category?.data?.attributes?.Name
      )
      setcategory(category[index])

      const duration = myModule.data.map((item: any) =>
        item?.attributes?.Duration
          ? extractTime(item?.attributes?.Duration)
          : ""
      )
      setDuration(duration[index])

      const simulations =
        myModule.data[index]?.attributes?.Simulation?.data?.length
      setSimulations(simulations)

      const labs = myModule.data[index]?.attributes?.PracticeLab?.data?.length
      setLab(labs)

      const assessments =
        myModule.data[index]?.attributes?.Assessment?.data?.length
      setAssessments(assessments)
      const learningData =
        myModule.data[index]?.attributes?.LearningUnit?.data?.length
      setLearning(learningData)
      const challangesData =
        myModule.data[index]?.attributes?.Challenges?.data?.length
      setChallanges(challangesData)
    }
  }, [myModule.data, index])
  const parameterCourse = [
    {
      id: uuidv4(),
      name: "Category",
      icon: <CategoryIcon />,
      value: categoryData
    },
    {
      id: uuidv4(),
      name: "Duration",
      icon: <DurationIcon />,
      value: durationData
    },
    {
      id: uuidv4(),
      name: "Real-time simulations",
      icon: <RealTimeIcon />,
      value: simulationsData
    },
    {
      id: uuidv4(),
      name: "Practice Labs",
      icon: <PracticeLabIcon />,
      value: labData
    },
    {
      id: uuidv4(),
      name: "Assessments",
      icon: <KeyboardOpenIcon />,
      value: assesmentData
    },
    {
      id: uuidv4(),
      name: "Learning Units",
      icon: <KeyboardOpenIcon />,
      value: learning
    }
  ]
  const [isExpand, setIsExpand] = useState(false)
  const handleExpand = () => {
    setIsExpand(prev => !prev)
    if (isExpand === true) {
      dispatch(setModuleId(data.id))
    } else {
      dispatch(setModuleId(data.id))
    }
  }

  const getCommonValues = (obj1: any, obj2: any) => {
    let count = 0

    const commonValues = new Set(
      obj1.map((obj: any) => parseInt(obj?.attributes?.SubModuleId, 10))
    )

    for (let i = 0; i < obj2.length; i += 1) {
      if (commonValues.has(obj2[i].id)) {
        count += 1
      }
    }

    return count
  }

  const getCompletedCount = (moduleData: any) => {
    let totalCompletedCount = 0
    const assementCnt = getCommonValues(
      completedModule?.data?.filter(
        (completed: any) => completed.attributes.ModuleType === `Assessment`
      ),
      moduleData?.attributes?.Assessment.data
    )
    const learningCnt = getCommonValues(
      completedModule?.data?.filter(
        (completed: any) => completed.attributes.ModuleType === `LearningUnit`
      ),
      moduleData?.attributes?.LearningUnit.data
    )
    const practiceCnt = getCommonValues(
      completedModule?.data?.filter(
        (completed: any) => completed.attributes.ModuleType === `PracticeLab`
      ),
      moduleData?.attributes?.PracticeLab.data
    )
    const simulationCnt = getCommonValues(
      completedModule?.data?.filter(
        (completed: any) => completed.attributes.ModuleType === `Simulation`
      ),
      moduleData?.attributes?.Simulation.data
    )

    totalCompletedCount =
      assementCnt + learningCnt + practiceCnt + simulationCnt
    return totalCompletedCount
  }

  const totalCount =
    simulationsData + labData + assesmentData + learning + Challenges

  // const completedCounts = myModule?.data?.map((module: any) => {
  //   const moduleId = module.id
  // const completedCount = completedModule?.data?.filter(
  //   (completed: any) => completed.attributes.ModuleID === `${moduleId}`
  // )?.length
  //   return { moduleId, completedCount }
  // })
  // const completedCountEntry = completedCounts.find(
  //   (entry: any) => entry.moduleId === data.id
  // )

  // const completedCount = completedCountEntry
  //   ? completedCountEntry.completedCount
  //   : 0

  const completedCount = getCompletedCount(data)
  const formatData: any = `${completedCount}/${totalCount}`

  const passedFromat: number = completedCount / totalCount
  const percent = Math.min(100, Math.round(passedFromat * 100))
  return (
    <Row
      className={classNames(styles.path, styles.onHover)}
      style={{ backgroundColor: background }}
      onClick={handleExpand}
    >
      <Col span={24}>
        <button type="button" className={styles.expand}>
          {!isExpand ? <ExpandIcon /> : <MinusIcon />}
        </button>
        <Typography className={styles.pathName}>
          {data?.attributes?.Title}
        </Typography>
        <Row>
          <Col
            xl={13}
            lg={13}
            md={24}
            sm={24}
            xs={24}
            className={`${styles.info} ${
              Challenges > 0 ? styles.verticleLine : ""
            } `}
          >
            {Challenges > 0 ? null : (
              <Typography.Paragraph
                className={classNames(
                  styles.paragraph,
                  !isExpand ? styles.more : null
                )}
              >
                {data?.attributes?.Description}
              </Typography.Paragraph>
            )}

            <Row>
              <Col
                xl={24}
                lg={24}
                md={24}
                sm={!isExpand ? 24 : 0}
                xs={!isExpand ? 24 : 0}
              >
                <div
                  className={classNames(
                    styles.listLabel,
                    !isExpand ? styles.borderBottom : ""
                  )}
                >
                  {Challenges !== undefined && Challenges > 0 ? (
                    <Label name="Challenges" color="#E5895E" />
                  ) : (
                    <>
                      {simulationsData !== undefined && simulationsData > 0 ? (
                        <Label name="Simulations" color="#4FA3CB" />
                      ) : null}
                      {labData !== undefined && labData > 0 ? (
                        <Label name="Practice Labs" color="#D5550D" />
                      ) : null}
                      {learning !== undefined && learning > 0 ? (
                        <Label name="Learning Units" color="#328C57" />
                      ) : null}
                      {assesmentData !== undefined && assesmentData > 0 ? (
                        <Label name="Assessments" color="#E5895E" />
                      ) : null}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            xl={8}
            lg={8}
            md={12}
            sm={!isExpand ? 24 : 0}
            xs={!isExpand ? 24 : 0}
          >
            {Challenges ? null : (
              <List className={styles.listPara}>
                {parameterCourse.map(item => (
                  <List.Item key={item.id} className={styles.paraCourse}>
                    <div className={styles.paraCourseLeft}>
                      {item.icon}
                      <Typography.Text className={styles.paraCourseName}>
                        {item.name}
                      </Typography.Text>
                    </div>
                    <Typography.Text className={styles.paraCourseValue}>
                      {item.value}
                    </Typography.Text>
                  </List.Item>
                ))}
              </List>
            )}
          </Col>
          <Col xl={3} lg={3} md={12} sm={0} xs={0} className={styles.progress}>
            {Challenges ? null : (
              <Progress
                strokeLinecap="butt"
                type="circle"
                format={() => formatData}
                percent={percent}
                className={styles.circle}
                strokeColor={percentToColor(passedFromat)}
                strokeWidth={8}
                trailColor="#35353D"
                width={135}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default LearningPathsCourse
