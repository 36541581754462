import classNames from "classnames/bind"

import styles from "./styles.module.scss"

const cx = classNames.bind(styles)
interface BriefingNoteProps {
  breifingText: string
  modal?: boolean
}
function BriefingNote({ modal, breifingText }: BriefingNoteProps) {
  return (
    <p
      className={cx(
        modal ? "briefing-modal-text" : "briefing-card-text",
        "mb-15"
      )}
      dangerouslySetInnerHTML={{
        __html: breifingText
      }}
    />
  )
}
BriefingNote.defaultProps = {
  modal: false
}

export default BriefingNote
