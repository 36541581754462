import classNames from "classnames/bind"
import { useState } from "react"
import Wavesurfer from "react-wavesurfer.js"

import { CIOMessage } from "../../../../../assets/audio"
import { CustomModal } from "../../../../../assets/css/styled"
import { ICS1, ICS2 } from "../../../../../assets/imgs"
import { CloseIconSm } from "../../../../../assets/svg"
import { OutlineButton } from "../../../../../components"
import styles from "../styles.module.scss"

const cx = classNames.bind(styles)
function RemainingStep2() {
  const [isModalTakeCallOpen, setIsModalTakeCallOpen] = useState(false)
  const [isModalMailOpen, setIsModalMailOpen] = useState(false)
  const [keyReplay, setKeyReplay] = useState(0)

  const handleOkModalTakeCall = () => {
    setKeyReplay(prev => prev + 1)
  }
  const handleCancelModalTakeCall = () => {
    setIsModalTakeCallOpen(false)
  }

  const handleOkModalMail = () => {
    setIsModalMailOpen(false)
  }
  const handleCancelModalMail = () => {
    setIsModalMailOpen(false)
  }

  return (
    <div className={cx("remaining-mess-container")}>
      <p className={cx("remaining-mess")}>
        As you still keep working through the detections, you have received an
        email from your CIO - apparently, someone have just added a post to
        Twitter, saying that your water plant management system has been
        compromised!
      </p>
      <h3 className={cx("remaining-mess-h3")}>
        Your CIO is calling you now to follow-up:
      </h3>
      <OutlineButton
        className={cx("take-the-call-button")}
        onClick={() => setIsModalTakeCallOpen(true)}
      >
        Take the Call
      </OutlineButton>
      {isModalTakeCallOpen && (
        <CustomModal
          title="Incoming call from your CIO:"
          centered
          closeIcon={<CloseIconSm />}
          open
          onOk={handleOkModalTakeCall}
          okText="Replay"
          cancelText="Close"
          onCancel={handleCancelModalTakeCall}
          className={cx("take-call-modal")}
        >
          <Wavesurfer key={keyReplay} src={CIOMessage} playing />
        </CustomModal>
      )}
      <OutlineButton
        className={cx("open-mail--btn")}
        onClick={() => setIsModalMailOpen(true)}
      >
        Open the Mail
      </OutlineButton>
      <CustomModal
        title="Email message from your CIO:"
        centered
        closeIcon={<CloseIconSm />}
        open={isModalMailOpen}
        onOk={handleOkModalMail}
        okText="Close"
        onCancel={handleCancelModalMail}
        className={cx("mail-modal")}
      >
        <div className={cx("greeting-part")}>
          <p>Good Morning,</p>
          <p>
            Thank you for taking my call. Here is the tweet and the screenshots
            that I mentioned:
          </p>
          <p>
            https://twitter.com/PeopleChernobyl/status/1584344975705534465?s=20&t=xSmsnNDyY7OlTEk6tjBD8g
          </p>
        </div>
        <div className={cx("body-part")}>
          <img src={ICS1} alt="ics1" className={cx("ic-1")} />
          <img src={ICS2} alt="ics2" className={cx("ic-2")} />
        </div>
        <div className={cx("end-part")}>
          <p>Appreciate you looking into it.</p>
          <p>Thanks, Nathan.</p>
        </div>
      </CustomModal>
      <p className={cx("remaining-mess")}>
        Review the leaked screenshots and advise your IT Team how to stop the
        attack. Update your incident notes.
      </p>
    </div>
  )
}

export default RemainingStep2
