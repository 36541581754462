import { Col, Pagination, Row } from "antd"
import React, { useEffect, useState } from "react"

import { HeaderTitle } from "../../components"
import LogoSchool from "../../assets/imgs/logoscholl.png"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  fetchScoreBoard,
  fetchUserCount
} from "../../app/reducers/LearningSlice"
import { simulationsActions } from "../Simulations/SimulationsSlice"
import { IMAGE_BASE_URL } from "../../api/config"
import { Ellipse } from "../../assets/imgs"

import "./commonStyle.css"
import classes from "./style.module.scss"

// const headerTable = [
//   {
//     id: 1,
//     title: "Position"
//   },
//   {
//     id: 2,
//     title: "Team"
//   },
//   {
//     id: 3,
//     title: "School"
//   },
//   {
//     id: 4,
//     property: "center",
//     title: "Round 1"
//   },
//   {
//     id: 5,
//     property: "center",
//     title: "Round 2"
//   },
//   {
//     id: 6,
//     property: "center",
//     title: "Round 3"
//   },
//   {
//     id: 7,
//     property: "center",
//     title: "Total"
//   }
// ]

const headerTable = [
  {
    id: 1,
    title: "Position"
  },
  {
    id: 2,
    title: "School"
  },
  {
    id: 3,
    title: "Team"
  },
  {
    id: 4,
    property: "center",
    title: "Team Score"
  }
]

// const innerContent = [
//   {
//     id: 1,
//     position: 1,
//     team: "Binary Brains",
//     imgName: LogoSchool,
//     school: "Brisbane Grammar School",
//     round1: "0",
//     round2: "0",
//     round3: "0",
//     total: "0"
//   },
//   {
//     id: 2,
//     position: 2,
//     team: "Digital Sentinels",
//     imgName: LogoSchool,
//     school: "The Hills School",
//     round1: "0",
//     round2: "0",
//     round3: "0",
//     total: "0"
//   },
//   {
//     id: 3,
//     position: 3,
//     team: "Binary Brains",
//     imgName: LogoSchool,
//     school: "Brisbane Grammar School",
//     round1: "0",
//     round2: "0",
//     round3: "0",
//     total: "0"
//   },
//   {
//     id: 4,
//     position: 4,
//     team: "Digital Sentinels",
//     imgName: LogoSchool,
//     school: "The Hills School",
//     round1: "0",
//     round2: "0",
//     round3: "0",
//     total: "0"
//   }
// ]

const innerContent = [
  {
    id: 1,
    position: 1,
    school: "Merrimac State High School",
    team: "Merrimac 5",
    teamScore: "318.4"
  },
  {
    id: 2,
    position: 2,
    school: "Merrimac State High School",
    team: "Merrimac 1",
    teamScore: "312.5"
  },
  {
    id: 3,
    position: 3,
    school: "St Bede's College",
    team: "St Bede's 1",
    teamScore: "299.5"
  },
  {
    id: 4,
    position: 4,
    school: "Brisbane Girls' Grammar School",
    team: "BGGS 2",
    teamScore: "276.5"
  },
  {
    id: 5,
    position: 5,
    school: "Cannon Hill Anglican College",
    team: "CHAC 1",
    teamScore: "265.3"
  },
  {
    id: 6,
    position: 6,
    school: "Brisbane Grammar School",
    team: "BGS 1",
    teamScore: "263.25"
  },
  {
    id: 7,
    position: 7,
    school: "Tintern Grammar",
    team: "Tintern 1",
    teamScore: "261.5"
  },
  {
    id: 8,
    position: 8,
    school: "Benowa State High School",
    team: "Benowa SHS1",
    teamScore: "259.5"
  },
  {
    id: 9,
    position: 9,
    school: "Marcellin College Bulleen",
    team: "Marcellin 2",
    teamScore: "251.6"
  },
  {
    id: 10,
    position: 10,
    school: "Merrimac State High School",
    team: "Merrimac 3",
    teamScore: "248"
  },
  {
    id: 11,
    position: 11,
    school: "Anglican Church Grammar School",
    team: "Churchie 3",
    teamScore: "227.5"
  },
  {
    id: 12,
    position: 12,
    school: "Southern Cross Catholic College",
    team: "Southern Cross 2",
    teamScore: "207.5"
  },
  {
    id: 13,
    position: 13,
    school: "The King's School",
    team: "King's School 4",
    teamScore: "193.25"
  },
  {
    id: 14,
    position: 14,
    school: "Cannon Hill Anglican College",
    team: "CHAC 2",
    teamScore: "191"
  },
  {
    id: 15,
    position: 15,
    school: "Cannon Hill Anglican College",
    team: "CHAC 3",
    teamScore: "191"
  },
  {
    id: 16,
    position: 16,
    school: "Merrimac State High School",
    team: "Merrimac 4",
    teamScore: "166.625"
  }
]

function ScoreBoard() {
  const [currentPage, setCurrentPage] = useState(1)
  const [uniqueScoreBoardList, setUniqueScoreBoardList] = useState([])
  const dispatch = useAppDispatch()
  const { scoreBoard, userCount, loading } = useAppSelector(
    state => state.learningList
  )
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])
  // const displayRecord = 10

  // useEffect(() => {
  //   dispatch(simulationsActions.reset())
  // }, [dispatch])

  // useEffect(() => {
  //   dispatch<any>(fetchScoreBoard())
  // }, [dispatch])
  // useEffect(() => {
  //   const keyToCheck = "team"
  //   const uniqueValues = new Set()

  //   const uniqueScoreBoard = scoreBoard.filter((item: any) => {
  //     if (!uniqueValues.has(item[keyToCheck]) && item[keyToCheck] !== null) {
  //       uniqueValues.add(item[keyToCheck])
  //       return true
  //     }
  //     return false
  //   })
  //   setUniqueScoreBoardList(uniqueScoreBoard)
  // }, [scoreBoard])

  // if (loading) {
  //   return (
  //     <div className={classes.loadingIconContainer}>
  //       <img className={classes.loadingicon} src={Ellipse} alt="loading" />
  //     </div>
  //   )
  // }
  return (
    <div>
      <div className={classes.container}>
        <Row>
          <Col span={24}>
            <HeaderTitle text="Scoreboard" />
          </Col>

          <Col span={24}>
            <div className={classes.box}>
              <div className={classes.scroll}>
                {/* <table className={classes.skillTable}>
                  <thead>
                    <tr>
                      {headerTable.map(item =>
                        item?.property ? (
                          <th
                            className={classes.scoreHeader}
                            style={{
                              textAlign: item?.property ? "center" : "unset"
                            }}
                            key={item.id}
                          >
                            {item.title}
                          </th>
                        ) : (
                          <th className={classes.scoreHeader} key={item.id}>
                            {item.title}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {uniqueScoreBoardList
                      ?.slice(
                        currentPage === 1
                          ? 0
                          : (currentPage - 1) * displayRecord,
                        currentPage === 1
                          ? displayRecord
                          : currentPage * displayRecord + 1
                      )
                      .map((item: any, i: number) => (
                        <tr key={item.id}>
                          <td className={classes.scoreBodyCell}>
                            {(currentPage === 0
                              ? currentPage
                              : currentPage - 1) *
                              displayRecord +
                              i +
                              1}
                          </td>
                          <td className={classes.scoreBodyCell}>
                            {item?.team}
                          </td>
                          <td className={`${classes.scoreBodyCell}`}>
                            <div className={classes.scoolImage}>
                              {item?.logo?.url ? (
                                <img
                                  src={`${IMAGE_BASE_URL}${item?.logo?.url}`}
                                  className={classes.imgScore}
                                  alt="schoologo"
                                />
                              ) : null}
                              {item?.organization}
                            </div>
                          </td>
                          <td
                            style={{ textAlign: "center" }}
                            className={classes.scoreBodyCell}
                          >
                            0
                          </td>
                          <td
                            style={{ textAlign: "center" }}
                            className={classes.scoreBodyCell}
                          >
                            0
                          </td>
                          <td
                            style={{ textAlign: "center" }}
                            className={classes.scoreBodyCell}
                          >
                            0
                          </td>
                          <td
                            style={{ textAlign: "center" }}
                            className={classes.scoreBodyCell}
                          >
                            0
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table> */}
                <table className={classes.skillTable}>
                  <thead>
                    <tr>
                      {headerTable.map(item =>
                        item?.property ? (
                          <th
                            className={classes.scoreHeader}
                            style={{
                              textAlign: item?.property ? "center" : "unset"
                            }}
                            key={item.id}
                          >
                            {item.title}
                          </th>
                        ) : (
                          <th className={classes.scoreHeader} key={item.id}>
                            {item.title}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {innerContent.map((item: any, i: number) => (
                      <tr key={item.id}>
                        <td className={classes.scoreBodyCell}>
                          {item.position}
                        </td>
                        <td className={`${classes.scoreBodyCell}`}>
                          {item?.school}
                        </td>
                        <td className={classes.scoreBodyCell}>{item?.team}</td>
                        <td
                          style={{ textAlign: "center" }}
                          className={classes.scoreBodyCell}
                        >
                          {item.teamScore}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <div
            className={`${classes.box} ${classes.pagination}`}
            style={{ width: "100%", padding: "15px" }}
          >
            <span>Total Teams - {uniqueScoreBoardList.length}</span>
            <Pagination
              defaultCurrent={1}
              total={uniqueScoreBoardList.length}
              onChange={page => setCurrentPage(page)}
              defaultPageSize={displayRecord}
            />
          </div>
        </Row> */}
      </div>
    </div>
  )
}

export default ScoreBoard
