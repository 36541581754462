function CloseIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8943 8.16638C21.1872 7.87348 21.1872 7.39861 20.8943 7.10572C20.6014 6.81282 20.1265 6.81282 19.8336 7.10572L14 12.9393L8.16637 7.10572C7.87348 6.81282 7.3986 6.81282 7.10571 7.10572C6.81282 7.39861 6.81282 7.87348 7.10571 8.16638L12.9393 14L7.10571 19.8336C6.81282 20.1265 6.81282 20.6014 7.10571 20.8943C7.3986 21.1872 7.87348 21.1872 8.16637 20.8943L14 15.0607L19.8336 20.8943C20.1265 21.1872 20.6014 21.1872 20.8943 20.8943C21.1872 20.6014 21.1872 20.1265 20.8943 19.8336L15.0607 14L20.8943 8.16638Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default CloseIcon
