import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  fetchLearningUnitsDetails,
  fetchQuestionsList
} from "../../app/reducers/LearningSlice"

import Slides from "./Slide1/Slides"
import styles from "./style.module.scss"

function Presentation() {
  const params = useParams<{ id: string; practiceId: string }>()
  const dispatch = useAppDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { id, practiceId } = params

  const [titleNew, setTitleNew] = useState<string | null>("")
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const { myModule, learningUnitDetail } = useAppSelector(
    state => state.learningList
  )
  const handleIndexChange = (index: number) => {
    setSelectedIndex(index)
  }

  const title = learningUnitDetail?.data?.attributes?.Title

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const parsedId = parseInt(id, 10)
          await dispatch(fetchLearningUnitsDetails(parsedId))
          await dispatch(fetchQuestionsList(parsedId))
        } catch (error) {
          console.error("Error fetching learning detail:", error)
        }
      }
    }
    fetchData()
  }, [dispatch, id])

  function handleNavigation(arg0: string): void {
    throw new Error("Function not implemented.")
  }

  return (
    <div className={styles.background}>
      {" "}
      <div>
        {/* <HeaderTitle text={titleNew || ""} /> */}
        <Slides
          sets={[learningUnitDetail?.data?.attributes] || []}
          titleNew={`${title}`}
          selectedIndex={selectedIndex}
          handleIndexChange={handleIndexChange}
        />
      </div>
    </div>
  )
}
export default Presentation
