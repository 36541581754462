import classNames from "classnames/bind"

import styles from "../styles.module.scss"

const cx = classNames.bind(styles)

function RemainingStep3() {
  return (
    <>
      <div className={cx("remaining-mess-container")}>
        <p className={cx("remaining-mess")}>
          Here is another message from the IT Team:
        </p>
        <p className={cx("remaining-mess")}>
          <b>
            It has been identified that a privileged user account was
            compromised, with multiple unathorised access events recorded over
            last few weeks.
          </b>
        </p>
        <p className={cx("remaining-mess", "mb-0")}>
          <b>
            The affected user is Callum Wilson (cwilson@tarangaroo-water.com.au)
          </b>
        </p>
      </div>
      <p className={cx("remaining-mess", "mt-15")}>
        Review security events associated with this user and see if this account
        could be used for the attack on your operational network.
      </p>
    </>
  )
}

export default RemainingStep3
