import { Col, Row } from "antd"
import classNames from "classnames/bind"

import { CarouselWrapper } from "../../../../assets/css/styled"
import { Card } from "../../../../components"
import styles from "../../Debrief.module.scss"

import OverallScore from "./OverallScore"
import ScoreItem, { ScoreItemProps } from "./ScoreItem"
import ScorePercent from "./ScorePercent"

const cx = classNames.bind(styles)

function TeamScore({
  data,
  team = "A"
}: {
  data: ScoreItemProps[]
  team: string
}) {
  const overallScore =
    data.reduce((prev, current) => {
      let fixedScore
      if (current.scoreScale === 10) {
        fixedScore = current.score
      } else {
        fixedScore = (current.score * 10) / 3
      }
      return prev + fixedScore
    }, 0) / data.length

  return (
    <Card className={cx("team-card")}>
      <p className={cx("team-name")}>Team {team}</p>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={16}>
          {data.map(item => (
            <ScoreItem {...item} key={Math.random()} />
          ))}
        </Col>
        <Col xs={24} sm={24} md={8}>
          <OverallScore overallScore={+overallScore.toFixed(1)} />
        </Col>
      </Row>
      <Row>
        <div className={cx("score-percent")}>
          {data.map(item => (
            <ScorePercent
              title={item.title}
              percent={Math.floor((item.score / item.scoreScale) * 100)}
              key={Math.random()}
            />
          ))}
        </div>
        <Col xs={24} sm={24} md={0}>
          <CarouselWrapper>
            {data.map(item => (
              <ScorePercent
                title={item.title}
                percent={Math.floor((item.score / item.scoreScale) * 100)}
                key={Math.random()}
              />
            ))}
          </CarouselWrapper>
        </Col>
      </Row>
    </Card>
  )
}

export default TeamScore
