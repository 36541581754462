import { Col, Row, Checkbox } from "antd"
import React from "react"

import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { setSelectedAnswerList } from "../../../app/reducers/LearningSlice"
import { IMAGE_BASE_URL } from "../../../api/config"

import classes from "./style.module.scss"

const checked = true
const unchecked = false

function QuestionCheckbox({ data, srNo }: any) {
  const dispatch = useAppDispatch()

  const { selectedAnswerList } = useAppSelector(state => state.learningList)

  const onSelectOption = (event: any, selectedId: any) => {
    if (event.target.checked) {
      const from = "multiple"
      dispatch(setSelectedAnswerList({ selectedId, srNo, from }))
    } else {
      const from = "remove-multiple"
      dispatch(setSelectedAnswerList({ selectedId, srNo, from }))
    }
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          <Row className={classes.questionWrapper}>
            <Col lg={24} md={24}>
              <div className={classes.questionName}>
                {srNo}. {data?.Title}
              </div>
              <div className={classes.questionDescription}>
                {data?.Description}
              </div>
              {data.Options.map((item: any) => (
                <div
                  className={`${classes.questionOptionsCheckbox} ${classes.checkboxWrapper}`}
                >
                  <Checkbox
                    onClick={e => onSelectOption(e, item.id)}
                    checked={
                      selectedAnswerList[srNo]?.includes(item.id)
                        ? checked
                        : unchecked
                    }
                  >
                    <span className={classes.optionName}>{item.Title}</span>
                  </Checkbox>
                  <div>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "32px",
                        height: "32px",
                        marginLeft: "8px",
                        padding: "0px"
                      }}
                      src={`${IMAGE_BASE_URL}${item?.Icon?.data?.attributes?.url}`}
                      alt="option icon"
                    />
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default QuestionCheckbox
