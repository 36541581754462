import Login from "../pages/Authentication"
import GoogleAuthCallback from "../pages/Authentication/GoogleauthCallback"
import MfaSetup from "../pages/Authentication/MFA"
import MfaVerification from "../pages/Authentication/MFA/mfa-verification"
import ResetPassword from "../pages/Authentication/ResetPassword"
import ForgotPassword from "../pages/Authentication/forgotPassword"
import Download from "../pages/Download"

const publicRoutes = [
  {
    id: 1,
    path: "/login",
    conponent: Login
  },
  {
    id: 2,
    path: "/forgot-password",
    conponent: ForgotPassword
  },
  {
    id: 3,
    path: "/reset-password",
    conponent: ResetPassword
  },
  {
    id: 4,
    path: "/mfa-setup",
    conponent: MfaSetup
  },
  // {
  //   id: 5,
  //   path: "/api/auth/google/callback",
  //   conponent: GoogleAuthCallback
  // },
  {
    id: 6,
    path: "/mfa-verification",
    conponent: MfaVerification
  },
  {
    id: 7,
    path: "/download/:fileName",
    conponent: Download
  }
]
export { publicRoutes }
