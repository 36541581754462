import classNames from "classnames/bind"
import { v4 as idv4 } from "uuid"

import { BluePoint, EndPoint, RedPoint, StartPoint } from "./Points"
import styles from "./styles.module.scss"

const cx = classNames.bind(styles)

const MINUTES_PER_HOUR = 60

const TIMELINE = [
  {
    id: idv4(),
    label: "red",
    time: "14:02",
    title: "A phishing email was sent"
  },
  {
    id: idv4(),
    label: "red",
    time: "14:05",
    title: "User account compromised"
  },
  { id: idv4(), label: "red", time: "14:08", title: "Scheduled task created" },
  { id: idv4(), label: "red", time: "14:16", title: "Domain administration" },
  { id: idv4(), label: "red", time: "14:49", title: "Data exfiltration" },
  {
    id: idv4(),
    label: "green",
    time: "15:00",
    title: "Simulation APT 29 Finished"
  },
  {
    id: idv4(),
    label: "green",
    time: "14:00",
    title: "Simulation APT 29 Started"
  },
  { id: idv4(), label: "blue", time: "14:07", title: "AIncident opened" },
  {
    id: idv4(),
    label: "blue",
    time: "14:12",
    title: "A compromised user account blocked"
  },
  {
    id: idv4(),
    label: "blue",
    time: "14:40",
    title: "Administrator password changed"
  }
]

//  Input: "10:20" => Output: 10*60 + 20 = 620
const toMinute = (time: string) => {
  const result = time.split(":").map(string => Number(string))
  const hours = result[0]
  const minutes = result[1]
  return hours * MINUTES_PER_HOUR + minutes
}

const TIMELINE_SORTED = TIMELINE.sort(
  (a, b) => toMinute(a.time) - toMinute(b.time)
)

const START_POINT = TIMELINE_SORTED[0]
const END_POINT = TIMELINE_SORTED[TIMELINE_SORTED.length - 1]
const BETWEEN_POINTS = TIMELINE_SORTED.slice(1, -1)
const betweenPointsLength = BETWEEN_POINTS.length
const percentPerPoint = 100 / (betweenPointsLength + 1)

function Timeline() {
  return (
    <div className={cx("line-wrapper")}>
      <div className={cx("line")}>
        <StartPoint
          point={START_POINT}
          onTop={BETWEEN_POINTS[0].label === "blue"}
        />
        {BETWEEN_POINTS.map((point, index) => {
          if (point.label === "blue") {
            return (
              <BluePoint
                key={point.id}
                position={percentPerPoint * (index + 1)}
                point={point}
              />
            )
          }
          return (
            <RedPoint
              key={point.id}
              position={percentPerPoint * (index + 1)}
              point={point}
            />
          )
        })}

        <EndPoint
          point={END_POINT}
          onBottom={BETWEEN_POINTS[betweenPointsLength - 1].label === "red"}
        />
      </div>
    </div>
  )
}

export default Timeline
