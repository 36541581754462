/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line prettier/prettier
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import rootReducer from "./rootReducer"

const persistConfig = {
  key: "root",
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer
