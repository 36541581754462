import React, { useEffect, useState } from "react"
import { Image, Col, Row, Button, Divider } from "antd"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { HeaderTitle, MainButton, OutlineButton } from "../../../components"
import { IMAGE_BASE_URL } from "../../../api/config"
import homeIcon from "../../../assets/icons/Home.png"
import backIcon from "../../../assets/icons/back.png"
import nextIcon from "../../../assets/icons/next.png"
import ResponseForm from "../components/ResponseForm"
import {
  setFormValues,
  setIndexvalue,
  setpageValue
} from "../../../app/reducers/LearningSlice"
import { CustomModal } from "../../../assets/css/styled"
import { CloseIconSm } from "../../../assets/svg"
import { Ellipse } from "../../../assets/imgs"

import styles from "./styles.module.scss"

function Slides({ sets, titleNew, ChallengeId }: any) {
  const [showThankYou, setShowThankYou] = useState(false)
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false)
  const [initialRender, setInitialRender] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isPageRefresh, setIsPageRefresh] = useState(true)
  const [confirmNavigation, setConfirmNavigation] = useState(true)
  const { user, logohead } = useAppSelector(state => state.user)
  const [pages, setPages] = useState(1)
  const { challengeForm, pageValue, formValues, loading } = useAppSelector(
    (state: [] | any) => state?.learningList
  )

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pageValue])

  const handleNext = () => {
    dispatch(setpageValue(2))
    setInitialRender(true)
  }

  const handleBack = () => {
    if (pageValue === 1) {
      navigate(-1)
    } else {
      setInitialRender(true)
      dispatch(setpageValue(1))
    }
  }

  const showModalConfirm = () => {
    setIsModalConfirmOpen(true)
  }

  const handleCancelModalConfirm = () => {
    setIsModalConfirmOpen(false)
    setIsPageRefresh(false)
    setConfirmNavigation(false)
  }

  const handleSubmitModalConfirm = () => {
    setIsModalConfirmOpen(false)
    setIsPageRefresh(false)
    setConfirmNavigation(false)
    navigate("/")
  }

  useEffect(() => {
    if (initialRender) {
      localStorage.setItem("shouldOpenModal", "true")
    }
  }, [initialRender])

  useEffect(() => {
    const shouldOpenModal = localStorage.getItem("shouldOpenModal")

    if (shouldOpenModal === "true") {
      setIsModalConfirmOpen(true)
    }
    return () => {
      localStorage.removeItem("shouldOpenModal")
      dispatch(setpageValue(1))
      dispatch(setFormValues({}))
    }
  }, [dispatch])

  useEffect(() => {
    if (pageValue === 2) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [pageValue])

  useEffect(() => {
    if (sets?.Question?.length > 0) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [sets?.Question?.length])

  const handleFormValuesChange = (values: any) => {
    dispatch(setFormValues(values))
  }

  if (loading) {
    return (
      <div className={styles.loadingIconContainer}>
        <img className={styles.loadingicon} src={Ellipse} alt="loading" />
      </div>
    )
  }

  return (
    <div>
      <div className={styles.body}>
        <div className={classNames(styles.container)}>
          <Row
            className={styles.positionheader}
            justify="center"
            align="middle"
          >
            <Col
              xs={24}
              sm={24}
              md={15}
              lg={17}
              xl={19}
              className={styles.headerLeft}
            >
              <p className={styles.headingleftTitle}>
                <HeaderTitle
                  text={
                    (pageValue === 1 && titleNew) ||
                    (pageValue === 2 && "Submit Your Response")
                  }
                />
              </p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={9}
              lg={7}
              xl={5}
              className={styles.colHomeSecond}
            >
              <div className={styles.btn}>
                <Button className={styles.backBtn} onClick={showModalConfirm}>
                  <div className={styles.btnHome}>
                    <span>
                      <img src={homeIcon} alt="" />
                    </span>
                    <p>Home</p>
                  </div>
                </Button>
                <Button className={styles.backBtn} onClick={handleBack}>
                  <div className={styles.btnback}>
                    <span>
                      <img src={backIcon} alt="" />
                    </span>
                    <p>Back</p>
                  </div>
                </Button>
                <Button
                  className={styles.nextBtn}
                  onClick={handleNext}
                  disabled={isDisabled}
                >
                  <div style={{ height: "3px" }} className={styles.btnNext}>
                    <p>Next</p>
                    <span className={styles.default}>
                      <img src={nextIcon} alt="" />
                    </span>
                    <span className={styles.defaulthover}>
                      <img src={backIcon} alt="" />
                    </span>
                  </div>
                </Button>
                {sets?.Question?.length > 0 ? (
                  <div className={styles.pages}>{`${pageValue} / ${2}`}</div>
                ) : (
                  <div className={styles.pages}>{`${1} / ${1}`}</div>
                )}
              </div>
            </Col>
            <Divider
              style={{
                backgroundColor: "white",
                opacity: "0.5",
                marginTop: "20px"
              }}
            />
          </Row>
          {pageValue === 1 && (
            <Row className={styles.header}>
              <Col lg={12} className={styles.info}>
                <div className={styles.descul}>
                  <p
                    className={styles.paragraph}
                    dangerouslySetInnerHTML={{
                      __html: sets?.ChallengeContent
                    }}
                  />
                </div>
              </Col>

              <Col lg={12} className={styles.colImg}>
                <div style={{ display: "grid" }}>
                  {sets?.Image?.data?.attributes?.url && (
                    <Image
                      className={styles.imgPosition}
                      src={`${IMAGE_BASE_URL}${sets?.Image?.data?.attributes?.url}`}
                      preview={false}
                      loading="lazy"
                      alt=""
                    />
                  )}
                </div>
              </Col>
            </Row>
          )}
          {pageValue === 2 && sets?.Question?.length > 0 && (
            <ResponseForm
              ChallengeTitle={titleNew}
              ChallangeID={ChallengeId}
              questions={sets?.Question}
              challengeForm={challengeForm}
              formValues={formValues}
              onFormValuesChange={handleFormValuesChange}
            />
          )}
        </div>
      </div>
      <CustomModal
        title="Modal confirm"
        centered
        footer={null}
        closeIcon={<CloseIconSm />}
        open={isModalConfirmOpen}
        onCancel={handleCancelModalConfirm}
        className={styles.modalConfirm}
      >
        <div className={styles.modalConfirmWrap}>
          <p className={styles.modalConfirmText}>
            Do you want to exit this Challenge and return to the home page?
          </p>
          <Divider className={styles.divider} />
          <div className={styles.modalButton}>
            <MainButton onClick={handleSubmitModalConfirm}>Yes</MainButton>
            <OutlineButton onClick={handleCancelModalConfirm}>No</OutlineButton>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}
export default Slides
