function BlueTeamIcon() {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_223_2233)">
        <path
          d="M10.136 0L10.512 0.0959961C11.1551 0.272669 11.7164 0.66822 12.099 1.21443C12.4816 1.76064 12.6617 2.42325 12.608 3.08799C12.5509 3.72377 12.2842 4.32259 11.8498 4.79031C11.4154 5.25803 10.8379 5.56817 10.208 5.672C10.664 5.8 11.144 5.912 11.616 6.072C12.101 6.25631 12.5483 6.5274 12.936 6.872H6.59204C6.97495 6.54297 7.41394 6.28555 7.88804 6.11201C8.36004 5.95201 8.84804 5.84001 9.28804 5.71201C9.16804 5.71201 8.99203 5.64001 8.83203 5.58401C8.22672 5.37695 7.70845 4.97266 7.3603 4.43594C7.01216 3.89921 6.85431 3.26114 6.91204 2.624C6.97417 1.9925 7.24313 1.39921 7.67712 0.936279C8.11112 0.473352 8.68585 0.166698 9.31204 0.0639893C9.34666 0.0664152 9.38142 0.0664152 9.41604 0.0639893L10.136 0Z"
          fill="#2787E0"
        />
        <path
          d="M0 14.008C0.134261 13.5252 0.413503 13.0951 0.8 12.776C1.312 12.272 1.80799 11.744 2.33599 11.176V15.936C2.0974 16.0042 1.84742 16.0229 1.60134 15.9911C1.35526 15.9593 1.11826 15.8776 0.904834 15.751C0.691409 15.6245 0.506036 15.4557 0.360083 15.2551C0.21413 15.0544 0.110653 14.8261 0.0559815 14.584C0.0633019 14.5633 0.0633019 14.5407 0.0559815 14.52L0 14.008Z"
          fill="#2787E0"
        />
        <path
          d="M16.024 15.976H3.47998V7.97595H16.024V15.976ZM11.464 11.976C11.464 11.5239 11.2861 11.09 10.9687 10.7681C10.6513 10.4463 10.22 10.2623 9.76799 10.256C9.31383 10.2559 8.8779 10.4347 8.55452 10.7536C8.23114 11.0725 8.0463 11.5058 8.03999 11.96C8.042 12.4114 8.2206 12.8441 8.53755 13.1655C8.8545 13.4869 9.28464 13.6716 9.73599 13.68C10.1861 13.6801 10.6185 13.5047 10.9412 13.1909C11.2639 12.8772 11.4515 12.4499 11.464 12V11.976Z"
          fill="#2787E0"
        />
        <path
          d="M17.1919 11.2C17.8479 11.88 18.5039 12.512 19.1039 13.184C19.3179 13.4214 19.4559 13.7174 19.5001 14.0339C19.5443 14.3505 19.4927 14.673 19.3519 14.96C19.2354 15.2619 19.03 15.5215 18.7629 15.7043C18.4958 15.8871 18.1796 15.9846 17.8559 15.984C17.6399 15.984 17.4239 15.984 17.1919 15.984V11.2Z"
          fill="#2787E0"
        />
        <path
          d="M10.3199 11.984C10.3221 12.0596 10.3089 12.1348 10.2812 12.2051C10.2536 12.2754 10.2119 12.3395 10.1589 12.3933C10.1058 12.4471 10.0424 12.4896 9.97245 12.5183C9.9025 12.5469 9.82749 12.5611 9.75191 12.56C9.6777 12.5611 9.60401 12.5474 9.53514 12.5197C9.46627 12.492 9.40359 12.4509 9.35074 12.3988C9.29789 12.3467 9.25592 12.2846 9.22728 12.2162C9.19864 12.1477 9.18389 12.0742 9.1839 12C9.18049 11.9235 9.19307 11.847 9.22081 11.7756C9.24855 11.7042 9.29085 11.6393 9.34503 11.5851C9.39921 11.531 9.46408 11.4886 9.53551 11.4609C9.60693 11.4332 9.68335 11.4206 9.7599 11.424C9.90842 11.424 10.0509 11.483 10.1559 11.588C10.2609 11.693 10.3199 11.8355 10.3199 11.984Z"
          fill="#2787E0"
        />
      </g>
      <defs>
        <clipPath id="clip0_223_2233">
          <rect width="19.52" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BlueTeamIcon
