function RealTimeGreenIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 11.0176V14.1594"
        stroke="#51BD7E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9.30908V15.8676"
        stroke="#51BD7E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 11.0176V14.1594"
        stroke="#51BD7E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22.0729H15C20 22.0729 22 20.1093 22 15.2002V9.30925C22 4.40016 20 2.43652 15 2.43652H9C4 2.43652 2 4.40016 2 9.30925V15.2002C2 20.1093 4 22.0729 9 22.0729Z"
        stroke="#51BD7E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RealTimeGreenIcon
