function PointIcon({
  color,
  className
}: {
  color?: string
  className?: string
}) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="6.5" cy="6.5" r="6.5" fill={color} />
    </svg>
  )
}
PointIcon.defaultProps = {
  color: "#51BD7E",
  className: ""
}

export default PointIcon
