/* eslint-disable import/no-cycle */
import { v4 as uuidv4 } from "uuid"

import { Label } from "../../components"
import { BlueTeamIcon, RedTeamIcon } from "../../assets/svg"

import {
  AvailableLearningPathsType,
  LearningPathsItemType,
  MyLearningPathsType
} from "./type"

// ===== Const =====

export const LAB_RED = "lab/red"
export const LAB_BLUE = "lab/blue"
export const SIMULATION_RED = "simulation/red"
export const SIMULATION_BLUE = "simulation/blue"
export const PRESENTATION_RED = "learning"
export const QUIZ = "quiz"
export const challanges = "Challanges"

// ===== MyLearningPaths =====

// const INTRODUCTION_LIST: LearningPathsItemType[] = [
//   {
//     id: uuidv4(),
//     pathName: "Basic Concepts",
//     paragraph: `An overview of basic security concepts, terminology, and key cybersecurity domains.`,
//     duration: "20 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 87,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Cyber Security Hygiene",
//     paragraph: `This unit teaches students the essentials of safe online habits. Topics include safe online browsing, the importance of regular software updates, and best practices for protecting personal information on the internet.`,
//     duration: "24 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 56,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Common Attack Types",
//     paragraph: `Students will gain an understanding of various cyber threats such as phishing, viruses, ransomware, data theft and others.The unit helps in recognising these threats and preparing strategies to defend against them`,
//     duration: "36 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Social Engineering",
//     paragraph: `An exploration of how hackers use psychological manipulation in cyber attacks. This unit discusses different social engineering tactics and provides guidance on how to recognize and counter these deceptive techniques.`,
//     duration: "20 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Introduction to Phishing",
//     paragraph: `Students will learn to identify and protect themselves from phishing attacks. The unit covers various phishing techniques, their potential impact, and practical strategies to avoid falling victim to such scams.
//     Turn on screen reader support`,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Business Email Compromise",
//     paragraph: `Focusing on the growing issue of email frauds, especially in business contexts. Students will learn about methods used by attackers and how to implement measures to protect organizational email communications`,
//     duration: "18 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Access Control and Passwords",
//     paragraph: `This unit discusses the importance of controlling access to digital resources. Students will learn about various access control models and how they help in safeguarding information systems`,
//     duration: "22 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: true,
//     type: PRESENTATION_RED
//   },
//   {
//     id: uuidv4(),
//     pathName: "Introduction to Cyber Security",
//     paragraph: `A quiz designed to test students' understanding of cybersecurity basics covered in the initial module. It's a crucial step in ensuring a solid foundational knowledge of the field.`,
//     duration: "20 minutes",
//     category: "Beginner",
//     label: <Label name="Assessment" color="rgb(229, 137, 94)" />,
//     percent: 0,
//     activated: true,
//     type: QUIZ
//   },
//   {
//     id: uuidv4(),
//     pathName: "Password Cracking",
//     paragraph: `In this interactive lab, students explore password cracking methods. The session aims to teach the importance of strong password policies and how to counteract password-based attacks.
//     Turn on screen reader support`,
//     category: "Beginner",
//     duration: "30 minutes",
//     label: (
//       <>
//         <Label name="Practice Lab" color="#D5550D" to="/labs/red" />
//         <Label name="Red Team" icon={<RedTeamIcon />} outline="#ed223c" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: LAB_RED
//   }
// ]
// const PENETRATION_LIST: LearningPathsItemType[] = [
//   {
//     id: uuidv4(),
//     pathName: "Penetration Testing Introduction",
//     paragraph: "Introduction and overview",
//     duration: "24 minutes",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 87,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Operating System Exploitation",
//     paragraph:
//       "In this lab, you will have an opportunity to learn about common operating system vulnerabilities",
//     duration: "24 minutes",
//     label: (
//       <>
//         <Label name="Practice Lab" color="#D5550D" to="/labs/red" />
//         <Label name="Red Team" icon={<RedTeamIcon />} outline="#ED223C" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: LAB_RED
//   }
// ]
// const CYBER_SECURITY: LearningPathsItemType[] = [
//   {
//     id: uuidv4(),
//     pathName: "Malware",
//     paragraph: `An exploration of malicious software, including viruses, worms, and ransomware. Students will understand how malware operates, how it can impact systems, and the methods used for its detection and removal.
//     `,
//     duration: "30 minutes",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     category: "Beginner",
//     percent: 87,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Endpoint Protection",
//     paragraph: `This unit covers the security of endpoint devices like computers, mobile phones, and tablets. Students will learn about the strategies and tools used to protect these devices from cyber threats.
//     Turn on screen reader support`,
//     duration: "20 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 87,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Network Security",
//     paragraph: `This unit focuses on protecting data during transmission across networks. Students will explore different network security measures, protocols, and practices to safeguard information in transit.
//     Turn on screen reader support`,
//     duration: "20 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 87,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Introduction to Firewalls",
//     paragraph: `Students will learn about firewalls as a fundamental security tool. The unit covers how firewalls work to block unauthorized access, different types of firewalls, and their role in protecting networked systems.
//     Turn on screen reader support`,
//     duration: "20 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 87,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Security Zones",
//     paragraph: `Students will learn about firewalls as a fundamental security tool. The unit covers how firewalls work to block unauthorized access, different types of firewalls, and their role in protecting networked systems.
//     Turn on screen reader support`,
//     duration: "10 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 87,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Introduction to Firewalls",
//     paragraph: `An introduction to the concept of security zones in network design. This unit explains how segregating networks into zones enhances security and helps in managing different levels of access and control.
//     Turn on screen reader support`,
//     duration: "20 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 87,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Intrusion Detection and Prevention Systems",
//     paragraph: `This unit delves into systems designed to detect and prevent unauthorized access or attacks. Students will learn how these systems work, their importance, and the difference between detection and prevention functionalities.
//     Turn on screen reader support`,
//     duration: "20 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 87,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Cyber Threat Detection",
//     paragraph: `A quiz designed to test students' understanding of common cyber security threats, attacks and threat prevention and detection mechanisms.
//     Turn on screen reader support`,
//     duration: "20 minutes",
//     category: "Beginner",
//     label: <Label name="Assessment" color="rgb(229, 137, 94)" />,
//     percent: 87,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Phishing Detection",
//     paragraph: `A practical lab session where students apply their knowledge to identify phishing attempts. This hands-on experience is crucial in enhancing their skills in detecting and responding to real-world phishing threats.
//      Turn on screen reader support`,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: (
//       <>
//         <Label name="Practice Lab" color="#D5550D" to="/labs/blue" />
//         <Label name="Blue Team" icon={<BlueTeamIcon />} outline="#2787e0" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: LAB_BLUE
//   }
// ]
// const CYBER_SECURITY_CONTROLS: LearningPathsItemType[] = [
//   {
//     id: uuidv4(),
//     pathName: "System Hardening",
//     paragraph: `Students will learn about the process of securing a system by reducing its attack surface This unit covers techniques like patch management, the removal of unnecessary software, and setting configurations to the most secure settings to protect systems against threats.
//     `,
//     category: "Beginner",
//     duration: "20 minutes",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "User Authentication",
//     paragraph: `This unit delves into the methods and technologies used to verify the identity of users accessing systems. Students will explore different authentication mechanisms, including passwords, biometrics, and multi-factor authentication, understanding their roles in securing access to information systems.
//     Turn on screen reader support`,
//     category: "Beginner",
//     duration: "20 minutes",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Vulnerability Management",
//     paragraph: `Students will learn about identifying, evaluating, treating, and reporting on security vulnerabilities in systems.
//     Turn on screen reader support`,
//     category: "Beginner",
//     duration: "20 minutes",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Australia’s ACSC Essential Eight",
//     paragraph: `Focused on the Australian Cyber Security Centre's Essential Eight strategies, this module educates students on these critical mitigation strategies. It's an introductory look at how these strategies are designed to protect systems against a range of cyber threats and enhance overall cybersecurity posture in an Australian context.
//     Turn on screen reader support`,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },

//   {
//     id: uuidv4(),
//     pathName: "Cyber Security Controls",
//     paragraph: `A quiz designed to test students' understandng of common cyber security controls and safeguards.
//     Turn on screen reader support`,
//     category: "Beginner",
//     duration: "20 minutes",
//     label: <Label name="Assessment" color="rgb(229, 137, 94)" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Port Scanning",
//     paragraph: `In this interactive lab session, students will delve into the practice of network port scanning, a crucial technique in cybersecurity. They will learn how to identify open ports on a network, understand the services running on these ports, and recognize the potential vulnerabilities associated with them. This hands-on experience will equip students with a fundamental skill for network analysis and security assessment, providing practical insights into how attackers might explore network vulnerabilities and how to defend against such reconnaissance activities.
//     Turn on screen reader support`,
//     category: "Beginner",
//     duration: "30 minutes",
//     label: (
//       <>
//         <Label name="Practice Lab" color="#D5550D" to="/labs/red" />
//         <Label name="Red Team" icon={<RedTeamIcon />} outline="#ED223C" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: LAB_RED
//   }
// ]
// const PENETRATION_TESTING: LearningPathsItemType[] = [
//   {
//     id: uuidv4(),
//     pathName: "Introduction to Ethical Hacking",
//     paragraph: `This unit introduces  students to ethical hacking, highlighting the distinction between ethical and malicious hacking. Learners will discover the critical role ethical hackers play in safeguarding digital assets.
//     Turn on screen reader support`,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "System Attacks",
//     paragraph: `This unit segment provides students with an insight into penetration testing methodologies and the common types of system attacks. Participants will learn how to simulate attacks to identify vulnerabilities within information systems, understanding the importance of proactive security measures
//     Turn on screen reader support`,
//     duration: "45 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Web Application Attacks",
//     paragraph: `

//     This course unit introduces the critical field of penetration testing, focusing specifically on attacks against web applications`,
//     duration: "45 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Capture the Flag Exercises",
//     paragraph: `This course unit introduces students in the dynamic world of cybersecurity competitions. CTFs are simulated cyber security challenges that mirror real-world scenarios, designed to teach participants to think like attackers and defenders in a controlled environment. `,
//     duration: "15 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },

//   {
//     id: uuidv4(),
//     pathName: "Penetration Testing",
//     paragraph: `A quiz designed to students' understandng of penetration testing concepts and methods.`,
//     duration: "20 minutes",
//     category: "Beginner",
//     label: <Label name="Assessment" color="rgb(229, 137, 94)" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Capture The Flag",
//     paragraph: `This engaging and interactive lab introduces students to the exciting world of Capture The Flag (CTF) in cybersecurity`,
//     duration: "60 minutes",
//     category: "Beginner",
//     label: (
//       <>
//         <Label name="Practice Lab" color="#D5550D" to="/labs/red" />
//         <Label name="Red Team" icon={<RedTeamIcon />} outline="#ED223C" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: LAB_RED
//   }
// ]
// const SECURITY_OPERATIONS: LearningPathsItemType[] = [
//   {
//     id: uuidv4(),
//     pathName: "Security Operation Centers",
//     paragraph: `This unit offers a glimpse into Security Operation Centers (SOCs), the nerve centers for monitoring, assessing, and defending against cyber threats. Students will learn about the roles and responsibilities within a SOC, including real-time incident response, threat intelligence, and proactive threat hunting.
//     `,
//     duration: "60 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Incident Response",
//     paragraph: `This init demystifies the critical process of Incident Response (IR), a key component in managing and mitigating cybersecurity breaches. Students will explore the steps involved in responding to a cyber incident, including preparation, detection, containment, eradication, recovery, and post-incident analysis.`,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Threat Hunting",
//     paragraph: `

//     This course introduces Threat Hunting, the proactive search for cyber threats lurking undetected in a network. Students will learn the fundamentals of identifying and mitigating potential breaches before they escalate. The course covers key concepts, methodologies, and tools used in threat hunting, emphasizing analytical skills and the importance of threat intelligence. `,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Cyber Security Operations",
//     paragraph: `A quiz designed to test students' understandng of Security Operations concepts.`,
//     duration: "20 minutes",
//     category: "Beginner",
//     label: <Label name="Assessment" color="rgb(229, 137, 94)" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Cyber Threat Detection",
//     paragraph: `

//     In this practical lab module, students will be introduced to the fundamentals of detecting cyber threats. The lab focuses on real-world scenarios, helping students understand how to monitor systems and networks for suspicious activity and potential vulnerabilities.`,
//     duration: "60 minutes",
//     category: "Beginner",
//     label: (
//       <>
//         <Label name="Practice Lab" color="#D5550D" to="/labs/blue" />
//         <Label name="Blue Team" icon={<BlueTeamIcon />} outline="#2787e0" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: LAB_BLUE
//   }
// ]
// const THREAT_OPERATIONS: LearningPathsItemType[] = [
//   {
//     id: uuidv4(),
//     pathName: "Introduction to Threat Intelligence",
//     paragraph: `

//     This unit introduces students to Threat Intelligence, a critical aspect of cybersecurity that involves preemptively recognizing and mitigating cyber threats. It covers the basics of gathering, analyzing, and leveraging information on potential cyber attacks to enhance security protocols.`,
//     duration: "20 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Threat Actors",
//     paragraph: `This unit provides an overview of Threat Actors, detailing the various types of adversaries in the cybersecurity landscape, from cybercriminals and hacktivists to state-sponsored attackers. `,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "MITRE ATT&CK",
//     paragraph: `

//     This unit introduces the MITRE ATT&CK framework, a globally recognized knowledge base of adversary tactics and techniques based on real-world observations. Students will learn how to navigate the framework to understand the various stages of cyber attacks and the methods employed by threat actors. `,
//     duration: "20 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Dark Web",
//     paragraph: `This unit offers an introductory look into the Dark Web, a hidden part of the internet not indexed by conventional search engines, often associated with anonymity and illicit activities. Students will learn about the structure of the Dark Web, including its legitimate uses and the risks it poses.`,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },

//   {
//     id: uuidv4(),
//     pathName: "Ransomware Operators",
//     paragraph: `This unit explores Ransomware Operators, focusing on the perpetrators behind ransomware attacks that encrypt victims' data to extort payment. Students will learn about the tactics, techniques, and procedures these cybercriminals use, their targeting strategies, and the impact of their operations on individuals and organizations.
//     `,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },

//   {
//     id: uuidv4(),
//     pathName: "Advanced Threats",
//     paragraph: `This unit delves into Advanced Threats, focusing on sophisticated cyber attacks that often evade traditional security measures. Students will learn about the characteristics of advanced persistent threats (APTs), including their targeted and stealthy nature, the use of novel techniques, and their objectives, ranging from data theft to espionage. `,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },

//   {
//     id: uuidv4(),
//     pathName: "Threat Intelligence",
//     paragraph: `A quiz designed to students' understandng of penetration testing concepts and methods.`,
//     duration: "20 minutes",
//     category: "Beginner",
//     label: <Label name="Assessment" color="rgb(229, 137, 94)" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Ransomware Attacks",
//     paragraph: `

//     This lab module provides students with a hands-on understanding of ransomware, one of the most prevalent and damaging types of cyber attacks. Students will explore how ransomware infects systems, its impact, and the methods used by cybercriminals to demand ransoms. The lab includes simulations that allow students to safely observe how ransomware operates and teaches them effective strategies for prevention, response, and recovery. This practical experience is crucial for comprehending the seriousness of ransomware threats and the importance of robust cybersecurity measures to protect against them.`,
//     duration: "1 hour 30 minutes",
//     category: "Beginner",
//     label: (
//       <>
//         <Label name="Practice Lab" color="#D5550D" to="/labs/blue" />
//         <Label name="Blue Team" icon={<BlueTeamIcon />} outline="#2787e0" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: LAB_BLUE
//   }
// ]
// const INSFRASTRUCTURE_ATTACKS: LearningPathsItemType[] = [
//   {
//     id: uuidv4(),
//     pathName: "Operational Technology",
//     paragraph: `This unit introduces Operational Technology (OT) Cybersecurity, focusing on protecting industrial and critical infrastructure systems from cyber threats. Students will learn about the unique challenges and risks associated with securing OT environments`,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "SCADA Systems",
//     paragraph: `This unit provides an introduction to cybersecurity for SCADA (Supervisory Control and Data Acquisition) Systems, which are crucial for monitoring and controlling industrial processes and infrastructure. Students will explore the significance of SCADA systems in critical sectors, the unique cybersecurity challenges they face, and the potential consequences of cyber attacks on these systems. `,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Critical Infrastructure Attacks",
//     paragraph: `This unit introduces Critical Infrastructure Attacks, focusing on cyber threats targeting essential services such as energy, water and transportation.`,
//     duration: "20 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Critical Infrastructure Attack Walkthrough",
//     paragraph: `In this simulation module, students will walk through a simulated attack on critical infrastructure systems. This interactive experience aims to demonstrate the potential impact of cyber attacks on essential services and the broader society. Students will learn about the types of threats these infrastructures face, the tactics attackers might use, and the strategies for defending against such attacks. The simulation provides a realistic context for understanding the importance of cybersecurity in protecting critical infrastructure and highlights the roles and responsibilities of cyber defenders in these scenarios.
//     Turn on screen reader support`,
//     duration: "2 Hours",
//     category: "Beginner",
//     label: (
//       <>
//         <Label name="Simulations" color="#4FA3CB" to="simulations" />
//         <Label name="Blue Team" icon={<BlueTeamIcon />} outline="#61B3FF" />
//       </>
//     ),
//     percent: 0,
//     activated: false,
//     type: SIMULATION_BLUE
//   }
// ]
// const CAREER_CYBER_SECURITY: LearningPathsItemType[] = [
//   {
//     id: uuidv4(),
//     pathName: "Cyber Security Industry in Australia",
//     paragraph: `This unit provides an overview of the Cyber Security Industry in Australia, highlighting its importance in the national economy and its role in protecting both public and private sector information systems. Students will explore the landscape of cyber security in Australia, including key players, government policies, regulatory frameworks, and the challenges and opportunities within the industry.
//     Turn on screen reader support`,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Cyber Security Career Pathes",
//     paragraph: `This unit introduces students to the diverse and evolving landscape of Cyber Security Career Paths. It outlines the various roles within the field, from ethical hackers and security analysts to compliance officers and cyber policy analysts. Students will learn about the skills and qualifications necessary for each role, the importance of continuous learning and certifications, and the growing demand for cyber security professionals across industries.`,
//     duration: "45 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Cyber Security Certifications",
//     paragraph: `This unit offers an introduction to Cyber Security Certifications, essential for professionals looking to validate their skills and advance their careers in the cybersecurity field. `,
//     duration: "45 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Next Steps",
//     paragraph: `This unit provides an overview of higher education cyber security programs, including bachelor's and master's degrees, as well as specialized diplomas and certificates in cybersecurity. The unit highlights key considerations for selecting the right program, such as curriculum relevance, faculty expertise, and opportunities for hands-on learning through labs and internships. `,
//     duration: "15 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   }
// ]
// const MY_LEARNING_PATHS: MyLearningPathsType[] = [
//   {
//     id: uuidv4(),
//     pathName: "Introduction to Cyber Security",
//     paragraph: `This unit introduces  students to ethical hacking, highlighting the distinction between ethical and malicious hacking. Learners will discover the critical role ethical hackers play in safeguarding digital assets.
//     Turn on screen reader support`,
//     category: "Beginner",
//     duration: "30 hours",
//     realTime: 0,
//     labs: 1,
//     assess: 3,
//     percent: 0,
//     lessonList: INTRODUCTION_LIST,
//     LearningUnit: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },
//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   },
//   {
//     id: uuidv4(),
//     pathName: "Cyber Threat Detection",
//     paragraph:
//       "Students will learn how to categorize and identify potential cyber threats. This module describes various cyber threat categories and provides an introduction to various detection methods, tools, and techniques used to recognize early signs of cyber attacks. ",
//     category: "Beginner",
//     duration: "2 hours 50 minutes",
//     realTime: 0,
//     labs: 5,
//     assess: 0,
//     percent: 0,
//     lessonList: CYBER_SECURITY,
//     LearningUnit: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },
//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   },
//   {
//     id: uuidv4(),
//     pathName: "Cyber Security Controls",
//     paragraph: `This unit introduces students to the various controls and measures implemented to protect information systems from cyber threats.
//       Turn on screen reader support`,
//     category: "Beginner",
//     duration: "2 hours 20 minutes",
//     realTime: 0,
//     labs: 5,
//     assess: 0,
//     percent: 0,
//     lessonList: CYBER_SECURITY_CONTROLS,
//     LearningUnit: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },
//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   },
//   {
//     id: uuidv4(),
//     pathName: `Introduction to Penetration Testing (Red Team)`,
//     paragraph: `This module introduces the fundamentals of Penetration Testing from a Red Team perspective, focusing on ethical hacking techniques used to assess and improve system security by simulating real-world attacks. Students will learn the basics of planning, executing, and reporting on penetration tests, gaining insight into the attacker's mindset to better defend against cyber threats.
//     `,
//     category: "Beginner",
//     duration: "2 hours 50 minutes",
//     realTime: 0,
//     labs: 5,
//     assess: 0,
//     percent: 0,
//     lessonList: PENETRATION_TESTING,
//     LearningUnit: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },
//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   },
//   {
//     id: uuidv4(),
//     pathName: `Introduction to Security Operations (Blue Team)`,
//     paragraph: `This module provides an overview of Security Operations from a Blue Team perspective, emphasizing defensive strategies to protect information systems against cyber threats. Students will explore key concepts in identifying, responding to, and recovering from security incidents, along with the tools and techniques used to maintain robust cybersecurity defenses.`,
//     category: "Beginner",
//     duration: "2 hours 50 minutes",
//     realTime: 0,
//     labs: 5,
//     assess: 0,
//     percent: 0,
//     lessonList: SECURITY_OPERATIONS,
//     LearningUnit: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },
//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   },
//   {
//     id: uuidv4(),
//     pathName: `Threat Intelligence`,
//     paragraph: `This module introduces the concept of Threat Intelligence, focusing on how to gather, analyze, and apply information about emerging cyber threats to preemptively protect against potential attacks. Students will learn the importance of actionable intelligence in developing a proactive cybersecurity posture.
//     `,
//     category: "Intermediate",
//     duration: "2 hours 50 minutes",
//     realTime: 0,
//     labs: 5,
//     assess: 0,
//     percent: 0,
//     lessonList: THREAT_OPERATIONS,
//     LearningUnit: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },

//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   },
//   {
//     id: uuidv4(),
//     pathName: `Critical Infrastructure Attacks`,
//     paragraph: `This module covers Critical Infrastructure Attacks, highlighting the vulnerabilities and risks faced by essential services and the strategies to protect them from cyber threats. Students will learn about the impact of attacks on national security and public safety, along with mitigation and response techniques.
//     `,
//     category: "Intermediate",
//     duration: "4 hours 00 minutes",
//     realTime: 0,
//     labs: 5,
//     assess: 0,
//     percent: 0,
//     lessonList: INSFRASTRUCTURE_ATTACKS,
//     LearningUnit: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },
//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   },
//   {
//     id: uuidv4(),
//     pathName: `Career in Cyber Security`,
//     paragraph: `

//     This module explores the diverse pathways and opportunities within the Cyber Security field, providing insights into the skills, roles, and career progression options available. Students will learn about the demand for cybersecurity professionals and how to navigate their career journey in this dynamic and essential industry.`,
//     category: "Beginner",
//     duration: "1 hours 50 minutes",
//     realTime: 0,
//     labs: 5,
//     assess: 0,
//     percent: 0,
//     lessonList: CAREER_CYBER_SECURITY,
//     LearningUnit: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },
//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   }
// ]

// // ===== AvailableLearningPaths =====

// const INTRODUCTION: LearningPathsItemType[] = [
//   {
//     id: uuidv4(),
//     pathName: "System and Network Attacks Introduction",
//     paragraph: "Introduction and overview",
//     duration: "24 minutes",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 87,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Adversarial Behavior",
//     paragraph: "Introduction and overview",
//     duration: "24 minutes",
//     category: "Beginner",

//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 56,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Attack Classification and attribution",
//     paragraph: "Introduction and overview",
//     duration: "24 minutes",
//     category: "Beginner",

//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Cyber kill chain",
//     paragraph: "Introduction and overview",
//     duration: "56min",
//     category: "Beginner",
//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   }
// ]
// const SYSTEM_ATTACKS: LearningPathsItemType[] = [
//   {
//     id: uuidv4(),
//     pathName: "Operating System Attack Indicators",
//     paragraph: "Introduction and overview",
//     duration: "24 minutes",
//     category: "Beginner",

//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Operating System Attack Indicators",
//     paragraph: "Introduction and overview",
//     duration: "24 minutes",
//     category: "Beginner",

//     label: (
//       <>
//         <Label name="Practice Lab" color="#D5550D" to="/labs/blue" />
//         <Label name="Blue Team" icon={<BlueTeamIcon />} outline="#61B3FF" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: LAB_BLUE
//   },
//   {
//     id: uuidv4(),
//     pathName: "Operating System Attack Indicators",
//     paragraph: "Introduction and overview",
//     duration: "24 minutes",
//     category: "Beginner",

//     label: (
//       <>
//         <Label name="Simulations" color="#4FA3CB" to="/simulations" />
//         <Label name="Blue Team" icon={<BlueTeamIcon />} outline="#61B3FF" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: SIMULATION_BLUE
//   }
// ]
// const NETWORK_ATTACKS: LearningPathsItemType[] = [
//   {
//     id: uuidv4(),
//     pathName: "Network Attack Indicators",
//     paragraph: "Introduction and overview",
//     duration: "24 minutes",
//     category: "Beginner",

//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 0,
//     activated: false
//   },
//   {
//     id: uuidv4(),
//     pathName: "Network Attack Indicators",
//     paragraph: "Introduction and overview",
//     duration: "24 minutes",
//     category: "Beginner",

//     label: (
//       <>
//         <Label name="Practice Lab" color="#D5550D" to="/labs/blue" />
//         <Label name="Blue Team" icon={<BlueTeamIcon />} outline="#61B3FF" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: LAB_BLUE
//   },
//   {
//     id: uuidv4(),
//     pathName: "Operating System Attack Indicators",
//     paragraph: "Introduction and overview",
//     duration: "24 minutes",
//     category: "Beginner",

//     label: (
//       <>
//         <Label name="Simulations" color="#4FA3CB" to="/simulations" />
//         <Label name="Blue Team" icon={<BlueTeamIcon />} outline="#61B3FF" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: SIMULATION_BLUE
//   }
// ]
// const DEFENDING_INDUSTRIAL_CS: LearningPathsItemType[] = [
//   {
//     id: uuidv4(),
//     pathName: "Industrial Systems Attacks",
//     paragraph: "Introduction and overview",
//     duration: "24 minutes",
//     category: "Beginner",

//     label: (
//       <>
//         <Label name="Simulations" color="#4FA3CB" to="/simulations" />
//         <Label name="Blue Team" icon={<BlueTeamIcon />} outline="#61B3FF" />
//       </>
//     ),
//     percent: 87,
//     activated: true,
//     type: SIMULATION_BLUE
//   },
//   {
//     id: uuidv4(),
//     pathName: "Critical Infrastructure Attack",
//     paragraph:
//       "In this lab, you will have an opportunity to learn about common operating system vulnerabilities",
//     duration: "24 minutes",
//     category: "Beginner",

//     label: <Label name="Learning Unit" color="#328C57" />,
//     percent: 87,
//     activated: false
//   }
// ]
// const AVAILABLE_LEARNING_PATHS: AvailableLearningPathsType[] = [
//   {
//     id: uuidv4(),
//     pathName: "System and Network Attacks Simulation",
//     paragraph:
//       "You will go through a number of simulated scenarios of real-workd cyber attacks on terminal hosts, servers and the internal networks. You will learng how to identify and categorise system and network threat vectors, identify attacker actions, and develop effective countermeasures.",
//     category: "Intermediate",
//     duration: "7 hours 52 minutes",
//     realTime: 1,
//     labs: 5,
//     assess: 1,
//     percent: 0,
//     lessonList: [
//       {
//         lessonId: uuidv4(),
//         title: "Introduction",
//         lessonItems: INTRODUCTION
//       },
//       {
//         lessonId: uuidv4(),
//         title: "System Attacks",
//         lessonItems: SYSTEM_ATTACKS
//       },
//       {
//         lessonId: uuidv4(),
//         title: "Network Attacks",
//         lessonItems: NETWORK_ATTACKS
//       }
//     ],
//     LearningUnit: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },
//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   },
//   {
//     id: uuidv4(),
//     pathName: "Defending Industrial Control Systems",
//     paragraph:
//       "You will go through a number of simulated scenarios of real-workd cyber attacks on industrial control systems (SCADA systems). You will learng how to identify and categorise system and network threat vectors, identify attacker actions, and develop effective countermeasures.",
//     category: "Advanced",
//     duration: "14 hours 30 minutes",
//     realTime: 2,
//     labs: 0,
//     assess: 1,
//     percent: 0,

//     lessonList: [
//       {
//         lessonId: uuidv4(),
//         title: "",
//         lessonItems: DEFENDING_INDUSTRIAL_CS
//       }
//     ],
//     LearningUnit: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },
//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   },
//   {
//     id: uuidv4(),
//     pathName: "Cyber Breach Simulation and Crisis Management",
//     paragraph:
//       "On this learning path, you will learn how to manage and respond to cyber security incidents, implement recovery and containment threats techniques on enterprise networks, and communicate requirements and constraints with internal and external stakeholders",
//     category: "Beginner",
//     duration: "48 hours",
//     realTime: 1,
//     labs: 5,
//     assess: 1,
//     percent: 0,
//     lessonList: [],
//     LearningUnit: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },
//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   },
//   {
//     id: uuidv4(),
//     pathName: "Cyber Incident Threat Detection and Prevention",
//     paragraph:
//       "An introduction to cyber security threat detection and prevention. You will learn about MITRE ATT&CK, threat detection techniques, adversary actions and behaviors.",
//     category: "Beginner",
//     duration: "64 hours",
//     realTime: 1,
//     labs: 5,
//     assess: 1,
//     percent: 0,
//     lessonList: [],
//     LearningUnit: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },
//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   },
//   {
//     id: uuidv4(),
//     pathName: "Penetration Testing, Ethical Hacking and Offensive Security",
//     paragraph:
//       "You will learn how to perform vulnerability scanning and reconnaissance on a range of devices and protocols, as well how to apply ethical procedures to exploit and communicate vulnerabilities.",
//     category: "Intermediate",
//     duration: "60 hours",
//     realTime: 3,
//     labs: 5,
//     assess: 2,
//     percent: 0,
//     lessonList: [],
//     LearningUnit: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },
//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   },
//   {
//     id: uuidv4(),
//     pathName: "Digital Forensics Introduction",
//     paragraph:
//       "This unit provides a general introduction to the concepts, theories, principles, and practice of digital forensics. Topics include data acquisition and validation, forensic methodologies, file systems  examination, graphics file investigation, network and email investigation, legal issues, professionalism and ethics, and also the current development in the field.",
//     category: "Intermediate",
//     duration: "7 hours 52 minutes",
//     realTime: 1,
//     labs: 5,
//     assess: 1,
//     percent: 0,
//     lessonList: [],
//     LearningUnit: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },

//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   },
//   {
//     id: uuidv4(),
//     pathName: "Cyber Incidence Response Skillset",
//     paragraph:
//       "You will go through a number of simulated scenarios of real-workd cyber attacks on terminal hosts, servers and the internal networks. You will learng how to identify and categorise system and network threat vectors, identify attacker actions, and develop effective countermeasures.",
//     category: "Intermediate",
//     duration: "7 hours 52 minutes",
//     realTime: 1,
//     labs: 5,
//     assess: 1,
//     percent: 0,
//     lessonList: [],
//     LearningUnit: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },
//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   },
//   {
//     id: uuidv4(),
//     pathName: "Cyber Security Insider Risks and Threats",
//     paragraph:
//       "You will go through a number of simulated scenarios of real-workd cyber attacks on terminal hosts, servers and the internal networks. You will learng how to identify and categorise system and network threat vectors, identify attacker actions, and develop effective countermeasures.",
//     category: "Advanced",
//     duration: "7 hours 52 minutes",
//     realTime: 1,
//     labs: 5,
//     assess: 1,
//     percent: 0,
//     lessonList: [],
//     LearningUnit: {
//       data: []
//     },
//     PracticeLab: {
//       data: []
//     },
//     Simulation: {
//       data: []
//     },
//     Assessment: {
//       data: []
//     },
//     attributes: {
//       LearningUnit: {
//         data: []
//       },
//       PracticeLab: {
//         data: []
//       },
//       Simulation: {
//         data: []
//       },
//       Assessment: {
//         data: []
//       }
//     }
//   }
// ]

// // ===== Exercises =====

// export const PRACTICE_LABS_LIST: LearningPathsItemType[] = [
//   {
//     id: uuidv4(),
//     pathName: "Password Cracking",
//     paragraph: `

//     In this interactive lab, students explore password cracking methods. The session aims to teach the importance of strong password policies and how to counteract password-based attacks.`,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: (
//       <>
//         <Label name="Practice Lab" color="#D5550D" to="/labs/red" />
//         <Label name="Red Team" icon={<RedTeamIcon />} outline="#ed223c" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: LAB_RED
//   },

//   {
//     id: uuidv4(),
//     pathName: "Phishing Detection",
//     paragraph: `A practical lab session where students apply their knowledge to identify phishing attempts. This hands-on experience is crucial in enhancing their skills in detecting and responding to real-world phishing threats.
//      `,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: (
//       <>
//         <Label name="Practice Lab" color="#D5550D" to="/labs/red" />
//         <Label name="Red Team" icon={<RedTeamIcon />} outline="#ED223C" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: LAB_RED
//   },
//   {
//     id: uuidv4(),
//     pathName: "Port Scanning",
//     paragraph: `

//       In this interactive lab session, students will delve into the practice of network port scanning, a crucial technique in cybersecurity. They will learn how to identify open ports on a network, understand the services running on these ports, and recognize the potential vulnerabilities associated with them. This hands-on experience will equip students with a fundamental skill for network analysis and security assessment, providing practical insights into how attackers might explore network vulnerabilities and how to defend against such reconnaissance activities.`,
//     duration: "30 minutes",
//     category: "Beginner",
//     label: (
//       <>
//         <Label name="Practice Lab" color="#D5550D" to="/labs/red" />
//         <Label name="Red Team" icon={<RedTeamIcon />} outline="#ED223C" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: LAB_RED
//   },
//   {
//     id: uuidv4(),
//     pathName: "Capture The Flag",
//     paragraph: `This engaging and interactive lab introduces students to the exciting world of Capture The Flag (CTF) in cybersecurity`,
//     duration: "1 hour",
//     category: "Beginner",
//     label: (
//       <>
//         <Label name="Practice Lab" color="#D5550D" to="/labs/red" />
//         <Label name="Red Team" icon={<RedTeamIcon />} outline="#ED223C" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: LAB_RED
//   },
//   {
//     id: uuidv4(),
//     pathName: "Cyber Threat Detection",
//     paragraph: `

//       In this practical lab module, students will be introduced to the fundamentals of detecting cyber threats. The lab focuses on real-world scenarios, helping students understand how to monitor systems and networks for suspicious activity and potential vulnerabilities. `,
//     duration: "1 hour",
//     category: "Beginner",
//     label: (
//       <>
//         <Label name="Practice Lab" color="#D5550D" to="/labs/blue" />
//         <Label name="Blue Team" icon={<BlueTeamIcon />} outline="#61B3FF" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: LAB_BLUE
//   },
//   {
//     id: uuidv4(),
//     pathName: "Ransomware Attacks",
//     paragraph: `

//     This lab module provides students with a hands-on understanding of ransomware, one of the most prevalent and damaging types of cyber attacks. Students will explore how ransomware infects systems, its impact, and the methods used by cybercriminals to demand ransoms. The lab includes simulations that allow students to safely observe how ransomware operates and teaches them effective strategies for prevention, response, and recovery. This practical experience is crucial for comprehending the seriousness of ransomware threats and the importance of robust cybersecurity measures to protect against them.`,
//     duration: "1 hour 30 minutes",
//     category: "Intermediate",
//     label: (
//       <>
//         <Label name="Practice Lab" color="#D5550D" to="/labs/blue" />
//         <Label name="Blue Team" icon={<BlueTeamIcon />} outline="#2787e0" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: LAB_BLUE
//   }
// ]
// export const SIMULATIONS_LIST: LearningPathsItemType[] = [
//   {
//     id: uuidv4(),
//     pathName: "Critical Infrastructure Attack Walkthrough",
//     paragraph: `

//       In this simulation module, students will walk through a simulated attack on critical infrastructure systems. This interactive experience aims to demonstrate the potential impact of cyber attacks on essential services and the broader society. Students will learn about the types of threats these infrastructures face, the tactics attackers might use, and the strategies for defending against such attacks. The simulation provides a realistic context for understanding the importance of cybersecurity in protecting critical infrastructure and highlights the roles and responsibilities of cyber defenders in these scenarios.`,
//     duration: "2 hours",
//     category: "Intermediate",
//     label: (
//       <>
//         <Label name="Simulations" color="#4FA3CB" to="simulations" />
//         <Label name="Blue Team" icon={<BlueTeamIcon />} outline="#61B3FF" />
//       </>
//     ),
//     percent: 0,
//     activated: true,
//     type: SIMULATION_BLUE
//   }
// ]

// const ALL_LESSON = [
//   ...INTRODUCTION_LIST,
//   ...CYBER_SECURITY,
//   ...CYBER_SECURITY_CONTROLS,
//   ...PENETRATION_TESTING,
//   ...SECURITY_OPERATIONS,
//   ...THREAT_OPERATIONS,
//   ...INSFRASTRUCTURE_ATTACKS,
//   ...CAREER_CYBER_SECURITY,
//   ...INTRODUCTION,
//   ...SYSTEM_ATTACKS,
//   ...NETWORK_ATTACKS,
//   ...DEFENDING_INDUSTRIAL_CS,
//   ...SIMULATIONS_LIST,
//   ...PRACTICE_LABS_LIST
// ]

// export { MY_LEARNING_PATHS, AVAILABLE_LEARNING_PATHS, ALL_LESSON }
