import { Col, Row } from "antd"
import { useSelector } from "react-redux"

import { CarouselWrapper } from "../../../assets/css/styled"
import { LearningModulePath, LearningPathsItemType } from "../../Home/type"
import LessonItem from "../LessonItem"
import nodataFound from "../../../assets/imgs/nodata.png"

import classes from "./styles.module.scss"

function ExerciseList({
  data,
  labtype
}: {
  labtype: string
  data: LearningModulePath[]
}) {
  const { completedModule } = useSelector(
    (state: [] | any) => state.learningList
  )
  return (
    <Row className={classes.main}>
      <Col xs={0} sm={0} md={24}>
        {data?.length > 0 ? (
          data?.map(lesson => (
            <LessonItem
              key={lesson.id}
              data={lesson}
              labtype={labtype}
              completedModule={completedModule}
            />
          ))
        ) : (
          <div className={classes.nodata}>
            <img src={nodataFound} alt="" />
            <p style={{ color: "#fff" }}>No records found</p>
          </div>
        )}
      </Col>
      <Col xs={24} sm={24} md={0}>
        <CarouselWrapper>
          {data?.length > 0 ? (
            data?.map(lesson => (
              <LessonItem
                key={lesson.id}
                data={lesson}
                labtype={labtype}
                completedModule={completedModule}
              />
            ))
          ) : (
            <div className={classes.nodata}>
              <img src={nodataFound} alt="" />
              <p style={{ color: "#fff" }}>No records found</p>
            </div>
          )}
        </CarouselWrapper>
      </Col>
    </Row>
  )
}

export default ExerciseList
