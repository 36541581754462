import axios, { AxiosInstance } from "axios"

import { BASE_URL } from "./config"

const axiosRequest: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
})

export default axiosRequest
