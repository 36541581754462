// eslint-disable-next-line react/prop-types
function TeamMessaage({ fill = "#7E7E89" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3334 1.6665H6.66669C3.33335 1.6665 1.66669 3.33317 1.66669 6.6665V17.4998C1.66669 17.9582 2.04169 18.3332 2.50002 18.3332H13.3334C16.6667 18.3332 18.3334 16.6665 18.3334 13.3332V6.6665C18.3334 3.33317 16.6667 1.6665 13.3334 1.6665Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83331 7.9165H14.1666"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83331 12.083H11.6666"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TeamMessaage
