import React, { useEffect, useState } from "react"
import { Button, Checkbox, Form, Input } from "antd"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"

import {
  forgotPasswordapi,
  loginlogoHead
} from "../../../app/reducers/authSlice"
import "../auth.scss"
import { useAppSelector } from "../../../app/hooks"
import LOGoimage from "../../../assets/imgs/logoNSB.png"
import { ProfileNameIcon } from "../../../assets/svg"
import { IMAGE_BASE_URL } from "../../../api/config"
import { Ellipse } from "../../../assets/imgs"

interface LoginFormState {
  email: string
}

const initialFormState: LoginFormState = {
  email: ""
}

function ForgotPassword() {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState<LoginFormState>({
    email: ""
  })
  const [showEmailError, setShowEmailError] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isError, setIsError] = useState("")
  const { loading, user, logohead } = useAppSelector(state => state.user)
  const [showInvalidEmailError, setShowInvalidEmailError] =
    useState<boolean>(false)
  const dispatch = useDispatch()
  const validateEmail = (rule: any, value: string) => {
    if (!value) {
      setShowEmailError(true)
      setShowInvalidEmailError(false)
      return Promise.reject(new Error("Please enter your email"))
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(value)) {
      setShowEmailError(false)
      setShowInvalidEmailError(true)
      return Promise.reject(new Error("Please enter a valid email!"))
    }
    setShowEmailError(false)
    setShowInvalidEmailError(false)
    return Promise.resolve()
  }
  useEffect(() => {
    if (user?.ok) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [user])
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }))
    setIsDisabled(false)
  }
  useEffect(() => {
    dispatch<any>(loginlogoHead())
  }, [dispatch])

  const handleSubmit = (values: LoginFormState) => {
    dispatch<any>(
      forgotPasswordapi({ values, setIsDisabled, form, setIsError })
    )
  }
  const Imagelogo = logohead?.data?.attributes?.LoginLogo?.data?.attributes?.url
  const loginText = logohead?.data?.attributes?.LoginText
  return (
    <div className="bground">
      <div className="miniuese">
        <div className="form_range">
          <img
            style={{ width: "200px" }}
            src={`${IMAGE_BASE_URL}${Imagelogo || LOGoimage}`}
            alt=""
          />

          <h5>{loginText}</h5>
          <h2>Forgot Password?</h2>
        </div>
        <p
          style={{
            marginBottom: "10px",
            textAlign: "center",
            fontFamily: "Satoshi-Variable"
          }}
          dangerouslySetInnerHTML={{
            __html: isError
          }}
        />
        <Form form={form} className="form_box" onFinish={handleSubmit}>
          <p>Enter email address</p>
          <Form.Item
            name="email"
            rules={[{ validator: validateEmail }]}
            validateStatus={
              showEmailError || showInvalidEmailError ? "error" : undefined
            }
            help={
              (showEmailError || showInvalidEmailError) &&
              (showEmailError
                ? "Please enter your email!"
                : "Please enter a valid email!")
            }
          >
            <Input
              prefix={
                <span className="inputIcon">
                  <ProfileNameIcon />
                </span>
              }
              placeholder="Enter Email"
              onChange={handleInputChange}
              name="email"
            />
          </Form.Item>

          <div className="newone">
            <Link to="/login">Back to Login</Link>
          </div>
          <div className="open_btn">
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              style={{ background: "#ED223C", width: "100%", color: "#fff" }}
              disabled={isDisabled}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default ForgotPassword
