import { Col, Collapse, Row } from "antd"
import { useSelector } from "react-redux"

import { CarouselWrapper } from "../../../../assets/css/styled"
import {
  LAB_BLUE,
  LAB_RED,
  PRESENTATION_RED,
  QUIZ,
  SIMULATION_BLUE,
  SIMULATION_RED,
  challanges
} from "../../data"
import LearningPathsCourse from "../LearningPathsCourse"
import MyLearningPathsLesson from "../MyLearningPathsLesson"
import { MyLearningPathsType } from "../../type"
import { Ellipse } from "../../../../assets/imgs"
import nodataFound from "../../../../assets/imgs/nodata.png"

import classes from "./styles.module.scss"

function MyLearningPaths({
  myModule,
  completedModule
}: {
  myModule: any
  completedModule: any
}) {
  if (myModule?.data?.length === 0) {
    return (
      <div className={classes.nodata}>
        <img src={nodataFound} alt="" />
        <p style={{ color: "#fff" }}>No records found</p>
      </div>
    )
  }

  const renderLesson = (lesson: any, name: string, team: string) => (
    <MyLearningPathsLesson
      key={`lesson${Math.random()}`}
      data={lesson}
      background="#1E1E23"
      name={name}
      team={team}
      completedModule={completedModule}
    />
  )

  return (
    <Row className={classes.main}>
      <Col xl={24} lg={24} md={24} sm={0} xs={0}>
        <Collapse defaultActiveKey={[0]} accordion bordered={false} ghost>
          {myModule?.data?.map((item: MyLearningPathsType, i: number) => {
            let lessonsFound = false
            // const renderLesson = (lesson: any, name: string, team: string) => (
            //   <MyLearningPathsLesson
            //     key={`lesson${Math.random()}`}
            //     data={lesson}
            //     background="#1E1E23"
            //     name={name}
            //     team={team}
            //   />
            // )

            return (
              <Collapse.Panel
                key={item.id}
                showArrow={false}
                header={
                  <LearningPathsCourse
                    data={item}
                    index={i}
                    background="#1E1E23"
                  />
                }
              >
                {item?.attributes?.Challenges?.data.length > 0 ? (
                  item?.attributes?.Challenges?.data?.map((lesson: any) => {
                    lessonsFound = true
                    return renderLesson(
                      lesson,
                      "Challenges",
                      lesson?.attributes?.TeamType?.data?.attributes?.Name ??
                        challanges
                    )
                  })
                ) : (
                  <>
                    {item?.attributes?.LearningUnit?.data?.map(
                      (lesson: any) => {
                        lessonsFound = true
                        return renderLesson(
                          lesson,
                          "LearningUnit",
                          lesson?.attributes?.TeamType?.data?.attributes
                            ?.Name ?? PRESENTATION_RED
                        )
                      }
                    )}

                    {item?.attributes?.PracticeLab?.data?.map((lesson: any) => {
                      lessonsFound = true
                      return renderLesson(
                        lesson,
                        "PracticeLab",
                        lesson?.attributes?.TeamType?.data?.attributes?.Name
                          ? LAB_RED
                          : LAB_BLUE
                      )
                    })}

                    {item?.attributes?.Simulation?.data?.map((lesson: any) => {
                      lessonsFound = true
                      return renderLesson(
                        lesson,
                        "Simulation",
                        lesson?.attributes?.TeamType?.data?.attributes?.Name
                          ? SIMULATION_RED
                          : SIMULATION_BLUE
                      )
                    })}

                    {item?.attributes?.Assessment?.data?.map((lesson: any) => {
                      lessonsFound = true
                      return renderLesson(
                        lesson,
                        "Assessment",
                        lesson?.attributes?.TeamType?.data?.attributes?.Name ??
                          QUIZ
                      )
                    })}
                  </>
                )}

                {!lessonsFound && (
                  <p className={classes.lessonInform}>
                    This course has no lessons
                  </p>
                )}
              </Collapse.Panel>
            )
          })}
        </Collapse>
      </Col>
      <Col xl={0} lg={0} md={0} sm={24} xs={24}>
        <CarouselWrapper>
          {myModule?.data?.map((item: MyLearningPathsType, i: number) => {
            let lessonsFound = false
            return (
              <Collapse
                defaultActiveKey={[0]}
                bordered={false}
                ghost
                key={item.id}
              >
                <Collapse.Panel
                  key={item.id}
                  showArrow={false}
                  header={
                    <LearningPathsCourse
                      index={i}
                      data={item}
                      background="#1E1E23"
                    />
                  }
                >
                  {item?.attributes?.LearningUnit?.data?.map((lesson: any) => {
                    lessonsFound = true
                    return renderLesson(
                      lesson,
                      "LearningUnit",
                      lesson?.attributes?.TeamType?.data?.attributes?.Name ??
                        PRESENTATION_RED
                    )
                  })}

                  {item?.attributes?.PracticeLab?.data?.map((lesson: any) => {
                    lessonsFound = true
                    return renderLesson(
                      lesson,
                      "PracticeLab",
                      lesson?.attributes?.TeamType?.data?.attributes?.Name
                        ? LAB_RED
                        : LAB_BLUE
                    )
                  })}

                  {item?.attributes?.Simulation?.data?.map((lesson: any) => {
                    lessonsFound = true
                    return renderLesson(
                      lesson,
                      "Simulation",
                      lesson?.attributes?.TeamType?.data?.attributes?.Name
                        ? SIMULATION_RED
                        : SIMULATION_BLUE
                    )
                  })}

                  {item?.attributes?.Assessment?.data?.map((lesson: any) => {
                    lessonsFound = true
                    return renderLesson(
                      lesson,
                      "Assessment",
                      lesson?.attributes?.TeamType?.data?.attributes?.Name ??
                        QUIZ
                    )
                  })}

                  {item?.attributes?.Challenges?.data?.map((lesson: any) => {
                    lessonsFound = true
                    return renderLesson(
                      lesson,
                      "Challenges",
                      lesson?.attributes?.TeamType?.data?.attributes?.Name ??
                        challanges
                    )
                  })}

                  {!lessonsFound && (
                    <p className={classes.lessonInform}>
                      This course has no lessons
                    </p>
                  )}
                </Collapse.Panel>
              </Collapse>
            )
          })}
        </CarouselWrapper>
      </Col>
    </Row>
  )
}

export default MyLearningPaths
