import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { fetchChallengesDetails } from "../../app/reducers/LearningSlice"

import Slides from "./Slide1/Slides"
import styles from "./style.module.scss"

function ChallengesComponent() {
  const params = useParams<{ id: string; practiceId: string }>()
  const dispatch = useAppDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { id } = params

  const { challengesDetails } = useAppSelector(state => state.learningList)
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const parsedId = parseInt(id, 10)
          await dispatch(fetchChallengesDetails(parsedId))
        } catch (error) {
          console.error("Error fetching learning detail:", error)
        }
      }
    }
    fetchData()
  }, [dispatch, id])

  return (
    <div className={styles.background}>
      <div>
        <Slides
          sets={challengesDetails?.data?.attributes}
          titleNew={`${challengesDetails?.data?.attributes?.Title}`}
          ChallengeId={challengesDetails?.data?.id}
        />
      </div>
    </div>
  )
}
export default ChallengesComponent
