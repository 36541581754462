/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { notification } from "antd"

import axiosRequest from "../../api/axiosInterceptors"
import { APIS } from "../../api/ApisCollections"
import { BASE_URL } from "../../api/config"

import { getSession } from "./authSlice"

export interface LearningModule {
  learningModuleList: any
  myModule: any
  quizList: any
  isCompletion: any
  score: any
  completedModule: any
  headersList: any
  learningPath: any
  QuizList: any
  labsSkillSet: any
  question: QuestionList[] | any
  loading: boolean
  error: string | undefined
  learningUnitDetail: any
  ChallengesModuleDetails: any
  scoreBoard: any
  userCount: any
  moduleId: string
  SubModuleId: string
  moduleType: string
  indexValue: number
  pageValue: number
  formValues: object
  selectedOptions: {
    [key: number]: { option: string; value: string }
  }
  challengesDetails: any
  challengeForm: any
  questionsList: any
  selectedAnswerList: any
}
export interface LearningPaths {
  learningPaths: any[]
}
export interface QuizList {
  QuizList: any[]
}
export interface LabsSkillSet {
  labsSkillSet: any[]
}
export interface QuestionList {
  question: any[]
}
export interface payloadProps {
  data: {
    UserID: string
    ModuleID: string
    ModuleType: string
    Username: string
    Completion: boolean
    SubModuleId: string
  }
}
export interface scoreProps {
  data: {
    UserID: string
    ModuleID: string
    ModuleType: string
    Username: string
    SubModuleId: string
    Scorepercentage: string
  }
}

const initialState: LearningModule = {
  learningModuleList: [],
  myModule: [],
  quizList: [],
  headersList: [],
  learningPath: [],
  learningUnitDetail: [],
  QuizList: [],
  labsSkillSet: [],
  question: [],
  isCompletion: [],
  score: [],
  completedModule: [],
  loading: false,
  indexValue: 0,
  pageValue: 1,
  error: "",
  moduleId: "",
  SubModuleId: "",
  moduleType: "",
  selectedOptions: {},
  challengesDetails: [],
  ChallengesModuleDetails: [],
  challengeForm: {},
  formValues: {},
  scoreBoard: [],
  userCount: [],
  questionsList: [],
  selectedAnswerList: {}
}

export const headerData = createAsyncThunk(
  "learningModule/headerData",
  async () => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const [headerResponse, landingResponse] = await Promise.all([
        axiosRequest.get<LearningModule>(`${APIS.headersApi}`, { headers }),
        axiosRequest.get<LearningModule>(`${APIS.landingApi}`, { headers })
      ])
      const headerDataApi = headerResponse.data
      const landingDataApi = landingResponse.data
      return { headerDataApi, landingDataApi }
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)

export const fetchMyModule = createAsyncThunk(
  "learningModule/fetchMyModule",
  async () => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get<LearningModule>(
        `${APIS.myModuleAPI}`,
        { headers }
      )

      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)
export const fetchCompletedModule = createAsyncThunk(
  "learningModule/fetchCompletedModule",
  async (userId: { userId: any }) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get<LearningModule>(
        `${BASE_URL}is-completeds?filters[UserID][$eq]=${userId}`,
        { headers }
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)
export const fetchLearningUnitsDetails = createAsyncThunk(
  "learningModule/fetchLearningUnitsDetails",
  async (practiceId: number) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get<LearningModule[]>(
        `${APIS.learningUnitDetails}/${practiceId}?populate[Category][populate]=*&populate[ModuleDetail][populate]=*&populate[ModuleName][populate]=*&populate=*`,
        { headers }
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)

export const fetchQuiz = createAsyncThunk(
  "learningModule/fetchQuiz",
  async (quizId: number) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get<LearningModule[]>(
        `${APIS.quizApi}/${quizId}?populate=*`,
        { headers }
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)
export const fetchChallengesDetails = createAsyncThunk(
  "learningModule/fetchChallengesDetails",
  async (practiceId: number, { dispatch }) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get<LearningModule[]>(
        `${APIS.ChallangesApi}/${practiceId}?populate[ChallengeContent][populate]=*&populate[Image][populate]=*&populate[Question][populate]=*&populate=*`,
        { headers }
      )
      dispatch(setChallangesResponse(response.data))
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)

export const fetchiscompleted = createAsyncThunk(
  "learningModule/fetchiscompleted",
  async ({ payload }: { payload: payloadProps }) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.post<payloadProps[]>(
        `${BASE_URL}is-completeds`,
        payload,
        { headers }
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)
export const fetchAssesstmentScoreApi = createAsyncThunk(
  "learningModule/fetchAssesstmentScoreApi",
  async ({ scorePayload }: { scorePayload: scoreProps }) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.post<scoreProps[]>(
        `${BASE_URL}assesstment-scores`,
        scorePayload,
        { headers }
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)
export const getChallangesResponse = createAsyncThunk(
  "learningModule/getChallangesResponse",
  async (payloadChallenges: any, { dispatch }) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }

    try {
      const response = await axiosRequest.post(
        `${BASE_URL}challenge-responses`,
        { data: payloadChallenges },
        { headers }
      )
      if (response.data) {
        notification.success({
          message: "Your response recorded successfully",
          key: "updatable"
        })
        dispatch(setPostChallangeFormResponse(response.data))
      }
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)

export const fetchUserCount = createAsyncThunk(
  "learningModule/fetchUserCount",
  async () => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get<LearningModule[]>(
        `${BASE_URL}users/count`,
        { headers }
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)
export const fetchScoreBoard = createAsyncThunk(
  "learningModule/fetchScoreBoard",
  async () => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get<LearningModule[]>(
        // `${BASE_URL}users?start=${payload?.page}&limit=${payload?.limit}&populate=*`,
        `${BASE_URL}users?populate=*`,
        { headers }
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)

export const fetchQuestionsList = createAsyncThunk(
  "learningModule/fetchQuestionsList",
  async (learningUnitId: any) => {
    const { token } = getSession()
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get(
        `questions?populate[QuestionsData][populate][Options][populate][Icon][populate]=*&populate[LearningUnit][populate]=*&filters[LearningUnit][id][$eq]=${learningUnitId}&populate=*`,
        { headers }
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)

export const learningSlice = createSlice({
  name: "learningModule",
  initialState,
  reducers: {
    resetLearningState(state) {
      return initialState
    },
    setModuleId(state, action) {
      state.moduleId = action.payload
    },
    setSubModuleId(state, action) {
      state.SubModuleId = action.payload
    },
    setModuleType(state, action) {
      state.moduleType = action.payload
    },
    setIndexvalue(state, action) {
      state.indexValue = action.payload
    },
    setpageValue(state, action) {
      state.pageValue = action.payload
    },
    setFormValues(state, action) {
      state.formValues = action.payload
    },
    setSelectedOptions(state, action) {
      state.selectedOptions = action.payload
    },
    setChallangesResponse(state, action) {
      state.challengesDetails = action.payload
    },
    setPostChallangeFormResponse(state, action) {
      state.challengeForm = action.payload
    },
    setSelectedAnswerList(state, action) {
      const currentArray = state.selectedAnswerList
      if (action.payload.from === "single") {
        const finalArray = {
          ...currentArray,
          [action.payload.srNo]: action.payload.selectedId
        }
        state.selectedAnswerList = finalArray
      }

      if (action.payload.from === "multiple") {
        const subArray = currentArray[action.payload.srNo]
        if (subArray === undefined) {
          const finalArray = {
            ...currentArray,
            [action.payload.srNo]: [action.payload.selectedId]
          }
          state.selectedAnswerList = finalArray
        } else {
          const finalArray = {
            ...currentArray,
            [action.payload.srNo]: [...subArray, action.payload.selectedId]
          }
          state.selectedAnswerList = finalArray
        }
      }

      if (action.payload.from === "remove-multiple") {
        const subArray = currentArray[action.payload.srNo]

        const index = subArray.indexOf(action.payload.selectedId)
        subArray.splice(index, 1)

        const finalArray = {
          ...currentArray,
          [action.payload.srNo]: subArray
        }
        state.selectedAnswerList = finalArray
      }

      if (action.payload.from === "re-order") {
        const subArray = currentArray[action.payload.srNo]
        if (subArray === undefined) {
          const finalArray = {
            ...currentArray,
            [action.payload.srNo]: [action.payload.selectedId]
          }
          state.selectedAnswerList = finalArray
        } else {
          const finalArray = {
            ...currentArray,
            [action.payload.srNo]: [...subArray, action.payload.selectedId]
          }
          state.selectedAnswerList = finalArray
        }
      }

      if (action.payload.from === "remove-re-order") {
        const subArray = currentArray[action.payload.srNo]

        const index = subArray.indexOf(action.payload.selectedId)
        subArray.splice(index, 1)

        const finalArray = {
          ...currentArray,
          [action.payload.srNo]: subArray
        }
        state.selectedAnswerList = finalArray
      }
    },
    resetSelectedAnswerList(state) {
      state.selectedAnswerList = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(headerData.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(headerData.fulfilled, (state, action) => {
        state.loading = false
        state.headersList = action.payload
      })
      .addCase(headerData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchMyModule.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(fetchMyModule.fulfilled, (state, action) => {
        state.loading = false
        state.myModule = action.payload
      })
      .addCase(fetchMyModule.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchQuiz.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(fetchQuiz.fulfilled, (state, action) => {
        state.loading = false
        state.quizList = action.payload
      })
      .addCase(fetchQuiz.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchiscompleted.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(fetchiscompleted.fulfilled, (state, action) => {
        state.loading = false
        state.isCompletion = action.payload
        state.moduleId = ""
        state.SubModuleId = ""
        state.moduleType = ""
      })
      .addCase(fetchiscompleted.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchAssesstmentScoreApi.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(fetchAssesstmentScoreApi.fulfilled, (state, action) => {
        state.loading = false
        state.score = action.payload
      })
      .addCase(fetchAssesstmentScoreApi.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchCompletedModule.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(fetchCompletedModule.fulfilled, (state, action) => {
        state.loading = false
        state.completedModule = action.payload
      })
      .addCase(fetchCompletedModule.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })

      .addCase(fetchLearningUnitsDetails.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(fetchLearningUnitsDetails.fulfilled, (state, action) => {
        state.loading = false
        state.learningUnitDetail = action.payload
      })
      .addCase(fetchLearningUnitsDetails.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchChallengesDetails.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(fetchChallengesDetails.fulfilled, (state, action) => {
        state.loading = false
        state.ChallengesModuleDetails = action.payload
      })
      .addCase(fetchChallengesDetails.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchUserCount.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(fetchUserCount.fulfilled, (state, action) => {
        state.loading = false
        state.userCount = action.payload
      })
      .addCase(fetchUserCount.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchScoreBoard.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(fetchScoreBoard.fulfilled, (state, action) => {
        state.loading = false
        state.scoreBoard = action.payload
      })
      .addCase(fetchScoreBoard.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchQuestionsList.pending, (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addCase(fetchQuestionsList.fulfilled, (state, action) => {
        state.loading = false
        state.questionsList = action.payload
      })
      .addCase(fetchQuestionsList.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export default learningSlice.reducer
export const {
  resetLearningState,
  setModuleId,
  setSubModuleId,
  setModuleType,
  setIndexvalue,
  setSelectedOptions,
  setChallangesResponse,
  setPostChallangeFormResponse,
  setpageValue,
  setFormValues,
  setSelectedAnswerList,
  resetSelectedAnswerList
} = learningSlice.actions
