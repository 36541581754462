import classNames from "classnames/bind"

import styles from "../styles.module.scss"

const cx = classNames.bind(styles)

function RemainingStep4() {
  return (
    <div className={cx("remaining-mess-container")}>
      <p className={cx("remaining-mess")}>
        The operational team confirmed that the water treatment system was
        accidentally exposed to the public access over the Internet:
      </p>
      <a
        href="http://scada.tarangaroo-water.com.au:8055"
        target="_blank"
        rel="noreferrer"
      >
        http://scada.tarangaroo-water.com.au:8055
      </a>
      <p className={cx("remaining-mess", "mt-25")}>
        They are reporting some unexpected activity on the system right now.
        Check recent security events and identify malicious activity.
      </p>
    </div>
  )
}

export default RemainingStep4
