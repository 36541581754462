import React from "react"
import { Navigate } from "react-router-dom"

import { getSession } from "../app/reducers/authSlice"
import { useAppSelector } from "../app/hooks"

interface PrivateRouteProps {
  children: JSX.Element[] | JSX.Element
}

function PrivateRoute({ children }: PrivateRouteProps) {
  const { isAuthenticated } = useAppSelector(state => state.user)

  if (isAuthenticated) {
    return <div>{children}</div>
  }

  return <Navigate to="/login" replace />
}

export default PrivateRoute
