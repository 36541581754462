/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { notification } from "antd"

import axiosRequest from "../../api/axiosInterceptors"
import { BASE_URL } from "../../api/config"
import { simulationsActions } from "../../pages/Simulations/SimulationsSlice"

interface UserState {
  user: any
  isAuthenticated: boolean
  logohead: any
  loading: boolean
  error: string | undefined
  jwt: string
  saveuser: any
  profileChanges: any
  logoUploaded: any
  fileImg: any
  logodata: string
  activeHeaderMenu: string
}

const initialState: UserState = {
  user: null,
  isAuthenticated: false,
  logohead: {},
  saveuser: null,
  profileChanges: null,
  loading: false,
  error: "",
  jwt: "",
  fileImg: null,
  logodata: "",
  logoUploaded: [],
  activeHeaderMenu: ""
}

export const UserSigin = createAsyncThunk(
  "user/UserSigin",
  async (values: any) => {
    try {
      const response = await axiosRequest.post<UserState>(`/auth/local`, values)
      if (response.data.jwt) {
        saveSession(response.data)
      }
      // notification.success({
      //   message: "User login successfully",
      //   key: "updatable"
      // })
      // if (response.data) {
      //   dispatch(setUser(response.data.user))
      // }
      return response.data
    } catch (error: any) {
      notification.error({
        message: `${error.response.data.error.message}`,
        key: "updatable"
      })

      throw error
    }
  }
)
export const forgotPasswordapi = createAsyncThunk(
  "user/forgotPassword",
  async ({
    values,
    setIsDisabled,
    form,
    setIsError
  }: {
    values: any
    setIsDisabled: (arg0: boolean) => void
    form: any
    setIsError: (arg0: string) => void
  }) => {
    try {
      const responseemail = await axiosRequest.get(`${BASE_URL}users`)
      const responsedEmail = responseemail.data
      const emailExists = responsedEmail.find(
        (user: any) => user.email === values.email
      )
      if (emailExists === undefined) {
        setIsError(
          `If an account exists for <b>${values?.email}</b>, you will get an email with instructions on resetting your password. If it doesn't arrive, be sure to check your spam folder.`
        )

        setIsDisabled(false)
      } else if (emailExists && emailExists.provider === "google") {
        setIsError(
          `If an account exists for ${values?.email}, you will get an email with instructions on resetting your password. If it doesn't arrive, be sure to check your spam folder.`
        )
      }

      if (emailExists.email && emailExists.provider === "local") {
        try {
          const response = await axiosRequest.post<UserState>(
            `/auth/forgot-password`,

            {
              email: emailExists.email
            }
          )
          notification.success({
            message: "Change password link sent successfully",
            key: "updatable"
          })
          if (response.data) {
            form.resetFields()
            setIsDisabled(true)
          }
          return response.data
        } catch (error: any) {
          notification.error({
            message: `${error.response.data.error.message}`,
            key: "updatable"
          })
          throw error
        }
      }
    } catch (error: any) {
      console.log(error)
      throw error
    }
  }
)

export const ChangePasswordApi = createAsyncThunk(
  "user/ChangePasswordApi",
  async ({
    formData,
    form,
    setIsPasswordDisabled
  }: {
    formData: any
    form: any
    setIsPasswordDisabled: any
  }) => {
    const { token } = getSession()
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    }
    try {
      const response = await axiosRequest.post(
        `/auth/change-password`,

        formData,
        { headers }
      )
      notification.success({
        message: "Password changed successfully",
        key: "updatable"
      })
      if (response.data) {
        form.resetFields()
        setIsPasswordDisabled(true)
      }

      return response.data
    } catch (error: any) {
      notification.error({
        message: `${error.response.data.error.message}`,
        key: "updatable"
      })
      throw error
    }
  }
)

export const loginlogoHead = createAsyncThunk(
  "user/loginlogoHead",
  async () => {
    try {
      const response = await axiosRequest.get<UserState>(
        `/login-info?populate=*`
      )

      return response.data
    } catch (error: any) {
      notification.error({
        message: `${error.response.data.error.message}`,
        key: "updatable"
      })
      throw error
    }
  }
)
export const profilechanges = createAsyncThunk(
  "user/profilechanges",
  async ({ changedValues, userId, setUserResponse }: any) => {
    const { token } = getSession()

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }

    try {
      const response = await axiosRequest.put<UserState>(
        `/users/${userId}`,
        changedValues,
        { headers }
      )
      if (response.data) {
        notification.success({
          message: "Profile update successfully",
          key: "updatable"
        })
        // saveSession(userUpdate)
      }
      setUserResponse(response.data)
      return response.data
    } catch (error: any) {
      notification.error({
        message: `${error.response.data.error.message}`,
        key: "updatable"
      })
      throw error
    }
  }
)
export const profileLogoUpload = createAsyncThunk(
  "user/profileLogoUpload",
  async (formData: any, { dispatch }) => {
    const { token } = getSession()

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`
    }

    try {
      const response = await axiosRequest.post(`upload/`, formData, {
        headers
      })
      if (response.data) {
        dispatch(setLogoiD(response.data[0].id))
      }
      return response
    } catch (error: any) {
      notification.error({
        message: `${error.response.data.error.message}`,
        key: "updatable"
      })
      throw error
    }
  }
)

export const getUserDetail = createAsyncThunk(
  "user/getUserDetail",
  async (userId: number, { dispatch }) => {
    const { token } = getSession()

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`
    }
    try {
      const response = await axiosRequest.get(`users/${userId}?populate=*`, {
        headers
      })
      if (response.data) {
        dispatch(setUser(response.data))
      }
      return response.data
    } catch (error: any) {
      if (error?.response?.data?.error?.status === 401) {
        dispatch(logoutApi(navigator))
      }
      notification.error({
        message: `${error.response.data.error.message}`,
        key: "updatable"
      })
      throw error
    }
  }
)

export const saveSession = (data: any) => {
  localStorage.setItem("token", data?.jwt)
}
export const getSession = () => {
  const token = localStorage.getItem("token")

  return {
    token
  }
}
export const ResetPasswordapi = createAsyncThunk(
  "user/ResetPasswordapi",
  async ({
    formData,
    newCode,
    navigate
  }: {
    formData: any
    newCode: string | null
    navigate: any
  }) => {
    try {
      const response = await axiosRequest.post(`auth/reset-password`, {
        code: newCode,
        password: formData.password,
        passwordConfirmation: formData.passwordConfirmation
      })
      notification.success({
        message: "Password changed successfullly",
        key: "updatable"
      })
      if (response.data) {
        navigate("/login")
      }
      return response.data
    } catch (error: any) {
      console.log(error.response.data.error.status)
      if (error.response.data.error.status === 400) {
        notification.error({
          message: `${"Link is expired"}`,
          key: "updatable"
        })
      } else {
        notification.error({
          message: `${error.response.data.error.message}`,
          key: "updatable"
        })
      }

      throw error
    }
  }
)
export const clearSession = () => {
  localStorage.removeItem("token")
  localStorage.removeItem("persist:root")
}
export const logoutApi = createAsyncThunk(
  "user/logoutApi",
  async (navigate: any, { dispatch }) => {
    try {
      if (navigate) {
        dispatch<any>(removeUser())
        dispatch(simulationsActions.reset())
        clearSession()

        navigate(`/login`)

        // notification.success({
        //   message: "Logout successfully",
        //   key: "updatable"
        // })
      }
    } catch (error: any) {
      notification.error({
        message: `${error.response.data.error.message}`,
        key: "updatable"
      })
      throw error
    }
  }
)

const userSlice = createSlice({
  name: "exerciseModule",
  initialState,
  reducers: {
    removeUser: state => ({
      ...state,
      isAuthenticated: false,
      user: null,
      saveuser: null
    }),
    googleLogin: state => ({
      ...state,
      isAuthenticated: true
    }),
    setUser: (state, action) => ({
      ...state,
      saveuser: action.payload
    }),
    setImagelogo: (state, action) => ({
      ...state,
      fileImg: action.payload
    }),
    setLogoiD: (state, action) => ({
      ...state,
      logodata: action.payload
    }),
    setActiveHeaderMenu: (state, action) => ({
      ...state,
      activeHeaderMenu: action.payload
    })
  },
  extraReducers: builder => {
    builder
      .addCase(loginlogoHead.pending, state => ({
        ...state,
        loading: false
      }))
      .addCase(loginlogoHead.fulfilled, (state, action) => ({
        ...state,
        loading: false,

        logohead: action.payload
      }))
      .addCase(loginlogoHead.rejected, (state, action) => ({
        ...state,
        loading: false,

        error: action.error.message
      }))

      .addCase(UserSigin.pending, state => ({
        ...state,
        loading: true,
        isAuthenticated: false,
        error: ""
      }))
      .addCase(UserSigin.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload
      }))
      .addCase(UserSigin.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error.message
      }))
      .addCase(profilechanges.pending, state => ({
        ...state,
        loading: true,
        // isAuthenticated: false,
        error: ""
      }))
      .addCase(profilechanges.fulfilled, (state, action) => ({
        ...state,
        loading: false,

        saveuser: action.payload,
        logodata: "",
        fileImg: null
      }))
      .addCase(profilechanges.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error.message
      }))
      .addCase(profileLogoUpload.pending, state => ({
        ...state,
        loading: true,
        // isAuthenticated: false,
        error: ""
      }))
      .addCase(profileLogoUpload.fulfilled, (state, action) => ({
        ...state,
        loading: false,

        logoUploaded: action.payload,
        logodata: ""
      }))
      .addCase(profileLogoUpload.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error.message
      }))
      .addCase(forgotPasswordapi.pending, state => ({
        ...state,
        loading: true,
        error: ""
      }))
      .addCase(forgotPasswordapi.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        user: action.payload
      }))
      .addCase(forgotPasswordapi.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error.message
      }))
      .addCase(getUserDetail.pending, state => ({
        ...state,
        loading: true,
        error: ""
      }))
      .addCase(getUserDetail.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        saveuser: action.payload
      }))
      .addCase(getUserDetail.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error.message
      }))
      .addCase(logoutApi.pending, state => ({
        ...state,
        loading: false,
        error: ""
      }))
      .addCase(logoutApi.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null
      }))
      .addCase(logoutApi.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error.message
      }))
  }
})

export default userSlice.reducer
export const {
  removeUser,
  googleLogin,
  setUser,
  setImagelogo,
  setLogoiD,
  setActiveHeaderMenu
} = userSlice.actions
