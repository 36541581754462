import React, { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import { useDispatch } from "react-redux"
import { Icon } from "@iconify/react"
import "./auth.scss"
import { Button } from "antd"

import { googleLogin, saveSession, setUser } from "../../app/reducers/authSlice"
import { BASE_URL } from "../../api/config"
import { useAppSelector } from "../../app/hooks"

interface AuthResponse {
  jwt: string
  user: {
    id: string
    provider: string
  }
}

function GoogleAuthCallback() {
  const [auth, setAuth] = useState<AuthResponse | null>(null)
  const [statusCode, setStatusCode] = useState<number | null>(null)
  const [messageone, setMessageone] = useState("")
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isAuthenticated } = useAppSelector(state => state.user)
  const handleGoogleAuthRedirect = () => {
    navigate(`/login`)
  }
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    // eslint-disable-next-line consistent-return
    const callBack = async () => {
      try {
        const response = await axios.get<AuthResponse>(
          `${BASE_URL}auth/google/callback/?id_token=${searchParams}`
        )
        setAuth(response.data)
        return response.data
      } catch (error: any) {
        if (error.response.data.error.status === 400) {
          setStatusCode(error.response.data.error.status)
          setMessageone(error.response.data.error.message)
        }
      }
    }
    callBack()
  }, [location])
  useEffect(() => {
    if (auth?.jwt) {
      dispatch<any>(googleLogin())
      if (isAuthenticated) {
        saveSession(auth)
        dispatch(setUser(auth?.user))
        navigate("/")
      }
    }
  }, [auth, navigate, isAuthenticated, dispatch])

  return (
    <div>
      {statusCode === 400 && (
        <div className="google_unregistered">
          <div style={{ textAlign: "center" }}>
            <Icon icon="tabler:user-x" />
            <h5>{messageone}</h5>
            <Button
              type="primary"
              size="large"
              style={{ background: "brown", color: "#fff" }}
              onClick={handleGoogleAuthRedirect}
            >
              <span>Back</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default GoogleAuthCallback
