import { Col, Row, Button, Divider, Typography } from "antd"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { HeaderTitle } from "../../components"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import backIcon from "../../assets/icons/back.png"
import nextIcon from "../../assets/icons/next.png"
import {
  fetchQuestionsList,
  resetSelectedAnswerList
} from "../../app/reducers/LearningSlice"

import classes from "./style.module.scss"
import ComponentProvider from "./components/ComponentProvider"

function Questions() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(2)
  const [totalPage, setTotalPages] = useState(0)
  const { questionsList } = useAppSelector(state => state.learningList)
  useEffect(() => {
    window.scrollTo(0, 0)
    const learningUnitId = window?.location?.pathname?.split("/")[2]
    dispatch(fetchQuestionsList(learningUnitId))
    return () => {
      dispatch(resetSelectedAnswerList())
    }
  }, [dispatch])

  useEffect(() => {
    if (questionsList?.data?.length > 0) {
      setTotalPages(
        Math.round(
          questionsList.data[0].attributes.QuestionsData.length / perPage
        )
      )
    } else {
      setTotalPages(0)
    }
  }, [questionsList, perPage])

  const goNext = () => {
    if (currentPage <= totalPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  const goBack = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleBackToHome = () => {
    dispatch(resetSelectedAnswerList())
    navigate("/")
  }

  return (
    <div>
      <div className={classes.container}>
        <Row>
          <Col span={24}>
            <HeaderTitle text="Questions" />
          </Col>
        </Row>
        <Row className={classes.box}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {questionsList?.data[0]?.attributes?.QuestionsData?.slice(
              currentPage === 1 ? 0 : (currentPage - 1) * perPage,
              currentPage === 1 ? perPage : currentPage * perPage + 1 - 1
            ).map((component: any, index: number) => (
              <ComponentProvider
                component={component}
                key={component?.id}
                srNo={(currentPage - 1) * perPage + index + 1}
              />
            ))}
            {/* {currentPage > totalPage ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "50vh"
                }}
              >
                <div className={classes.thankYouDiv}>
                  {" "}
                  <HeaderTitle text="Thank You!" />
                  <Divider style={{ backgroundColor: "white" }} />
                  <Typography.Paragraph className={classes.paragraph}>
                    This unit has been completed. You can get back to it anytime
                    if you want to review this information again.
                  </Typography.Paragraph>
                  <Button
                    className={classes.nextBtn}
                    style={{
                      display: "block",
                      fontWeight: "500",
                      fontSize: "18px"
                    }}
                    onClick={handleBackToHome}
                  >
                    Get back to the home screen
                  </Button>
                </div>
              </div>
            ) : null} */}
          </Col>
        </Row>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className={
              currentPage !== 1 ? classes.colHomeSecond : classes.colBtnRight
            }
          >
            {currentPage !== 1 && currentPage <= totalPage && (
              <Button className={classes.backBtn} onClick={goBack}>
                <div className={classes.btnback}>
                  <span>
                    <img src={backIcon} alt="" />
                  </span>
                  <p>Back</p>
                </div>
              </Button>
            )}

            {currentPage < totalPage && (
              <Button className={classes.nextBtn} onClick={goNext}>
                <div style={{ height: "3px" }} className={classes.btnNext}>
                  <p>Next</p>
                  <span className={classes.default}>
                    <img src={nextIcon} alt="" />
                  </span>
                  <span className={classes.defaulthover}>
                    <img src={backIcon} alt="" />
                  </span>
                </div>
              </Button>
            )}

            {currentPage === totalPage && (
              <Button className={classes.nextBtn} onClick={handleBackToHome}>
                <div style={{ height: "3px" }} className={classes.btnNext}>
                  Done
                </div>
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Questions
