import { Carousel, Modal, Row } from "antd"
import styled from "styled-components"

export const CarouselWrapper = styled(Carousel)`
  > .slick-dots-bottom {
    bottom: -6px;
  }
  > .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
  > .slick-dots li.slick-active button {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #ed223c;
  }
`
export const CustomModal = styled(Modal)`
  .ant-modal-content {
    background: #2b2b31;
    border-radius: 12px;
    margin-right: 20px;
    margin-left: 20px;
    .ant-modal-header {
      background: #2b2b31;
      border: none;
      border-radius: 12px;
      padding: 20px 30px;
      .ant-modal-title {
        color: #fff;
        font-size: 20px;
      }
    }
    .ant-modal-body {
      padding: 1px 30px;
    }
    .ant-modal-footer {
      display: flex;
      justify-content: center;
      border: none;
      padding: 30px;
      .ant-btn-default {
        display: none;
      }
      .ant-btn-primary {
        background-color: #ed223c;
        border-radius: 10px;
        padding: 10px 20px;
        min-width: 150px;
        height: 55px;
        span {
          font-size: 16px;
          color: #fff;
          margin: 0 auto;
        }
        &:hover {
          background: #ef4c61;
        }
        &:focus {
          background: #cf2138;
        }
      }
    }
    .ant-modal-close-x {
      svg {
        margin-top: 10px;
      }
    }
  }
`

export const MaxHeightRow = styled(Row)`
  height: 100%;
`
export const MinHeightFullScreenRow = styled(Row)`
  min-height: calc(100vh - 222px);
`
