import { combineReducers } from "@reduxjs/toolkit"

// Reducers
import simulationsReducer from "../pages/Simulations/SimulationsSlice"
import labsReducer from "../pages/Labs/LabsSlice"

import exerciseReducer from "./reducers/ExercisesSlice"
import learningReducer from "./reducers/LearningSlice"
import userReducer from "./reducers/authSlice"

const rootReducer = combineReducers({
  simulations: simulationsReducer,
  labs: labsReducer,
  exerciseList: exerciseReducer,
  learningList: learningReducer,
  user: userReducer
})

// export
export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
