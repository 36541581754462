import { Col, Collapse, Divider, Row, Typography } from "antd"
import classNames from "classnames"
import { useEffect, useState } from "react"

import {
  fetchCompletedModule,
  fetchMyModule,
  headerData
} from "../../app/reducers/LearningSlice"
import { DownSquare, UpSquare } from "../../assets/icons"
import { Ellipse } from "../../assets/imgs"
import { BaseButton, HeaderTitle } from "../../components"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { getUserDetail } from "../../app/reducers/authSlice"
import { simulationsActions } from "../Simulations/SimulationsSlice"

import { AvailableLearningPaths, MyLearningPaths } from "./components"
import styles from "./styles.module.scss"

const cx = classNames.bind(styles)

function LearningPath() {
  const dispatch = useAppDispatch()
  const [isExpandMyLearningPaths, setIsExpandMyLearningPaths] = useState(true)
  const [isExpandAvailableLearningPaths, setIsExpandAvailableLearningPaths] =
    useState(true)
  const { learningModuleList, loading, myModule, completedModule } =
    useAppSelector(state => state.learningList)

  const { headersList } = useAppSelector((state: any) => state.learningList)
  const { user } = useAppSelector(state => state.user)
  const userId = user?.user?.id
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    dispatch(headerData())
    dispatch(fetchMyModule())
    dispatch(fetchCompletedModule(userId))
    dispatch(getUserDetail(userId))
    dispatch(simulationsActions.reset())
  }, [dispatch, userId])

  if (loading) {
    return (
      <div className={classNames(styles.loadingIconContainer)}>
        <img
          className={classNames(styles.loadingicon)}
          src={Ellipse}
          alt="loading"
        />
      </div>
    )
  }

  return (
    <div className={styles.body}>
      <div
        className={classNames(styles.container, styles.learningPathsContainer)}
      >
        <Row className={styles.header}>
          <Col
            xs={24}
            sm={24}
            md={15}
            lg={17}
            xl={19}
            className={styles.headerLeft}
          >
            <HeaderTitle
              text={headersList?.landingDataApi?.data?.attributes.Heading}
            />
            <Typography.Text className={styles.pageSubtitle}>
              {headersList?.landingDataApi?.data?.attributes.Description}
            </Typography.Text>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={9}
            lg={7}
            xl={5}
            className={styles.colHomeSecond}
          >
            {/* <BaseButton className={styles.buttonHeader}>
              <span className="text">Design a Custom Learning Path</span>
            </BaseButton> */}
          </Col>
        </Row>
        <Divider plain className={styles.homeDivider} />
        <Collapse bordered={false} defaultActiveKey={[1]} ghost>
          <Collapse.Panel
            showArrow={false}
            key="1"
            header={
              <Row onClick={() => setIsExpandMyLearningPaths(prev => !prev)}>
                <Col span={24} className={styles.title}>
                  <div className={styles.titleContainer}>
                    {isExpandMyLearningPaths ? (
                      <img src={UpSquare} alt="up square icon" />
                    ) : (
                      <img src={DownSquare} alt="down square icon" />
                    )}
                    <Typography className={styles.titleName}>
                      My Modules
                    </Typography>
                  </div>
                </Col>
              </Row>
            }
          >
            <MyLearningPaths
              myModule={myModule}
              completedModule={completedModule}
            />
          </Collapse.Panel>
        </Collapse>
      </div>
      {/* <div
        className={classNames(
          styles.container,
          styles.bgcolor1E1E23,
          styles.learningPathsContainer
        )}
      >
        <Collapse bordered={false} defaultActiveKey={[1]} ghost>
          <Collapse.Panel
            showArrow={false}
            key="1"
            header={
              <Row
                onClick={() => setIsExpandAvailableLearningPaths(prev => !prev)}
              >
                <Col span={24} className={styles.title}>
                  <div className={styles.titleContainer}>
                    {isExpandAvailableLearningPaths ? (
                      <img src={UpSquare} alt="up square icon" />
                    ) : (
                      <img src={DownSquare} alt="down square icon" />
                    )}
                    <Typography className={styles.titleName}>
                      Available Learning Paths
                    </Typography>
                  </div>
                </Col>
              </Row>
            }
          >
            <AvailableLearningPaths />
          </Collapse.Panel>
        </Collapse>
      </div> */}
    </div>
  )
}

export default LearningPath
