import classNames from "classnames/bind"

import { RealTimeGreenIcon } from "../../assets/svg"
import MsTeamButton from "../Button/MsTeamButton/MsTeamButton"
import Card from "../Card/Card"

import styles from "./styles.module.scss"

const cx = classNames.bind(styles)

function MyTeam() {
  return (
    <Card className={cx("sidebar-content")}>
      <div>
        <div className={cx("sidebar-content-header")}>
          <RealTimeGreenIcon />
          <h2 className={cx("sidebar-content-title")}>My Team</h2>
        </div>
        <MsTeamButton className={cx("msteam-button")}>
          Microsoft Teams Channel
        </MsTeamButton>
      </div>
    </Card>
  )
}

export default MyTeam
