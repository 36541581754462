import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import { notification } from "antd"
import { useNavigate, useParams } from "react-router-dom"

import classes from "./style.module.scss"

function VideoPlayer() {
  return (
    <div className={classes.videoPlayer}>
      <ReactPlayer url="https://vimeo.com/10679287" controls />
    </div>
  )
}

export default VideoPlayer
