import classNames from "classnames/bind"

import {
  AttAndCkIcon,
  RealTimeGreenIcon,
  TickCircleIcon
} from "../../../../assets/svg"
import { Card } from "../../../../components"

import styles from "./styles.module.scss"

const cx = classNames.bind(styles)
const ADVERSARY_OBJECTIVES = [
  "Initial Access  - was obtained via cwilson user (phishing)",
  "Establish Persistence -  rootkit installed to PC-0098",
  "Lateral Movement - enumeration of Microsoft AD, Powershell",
  "Escalation of Privileges - Domain Administrator Access",
  "Data Exfiltration - exfiltration with DNS"
]

function DebriefSidebar() {
  return (
    <Card className={cx("sidebar-content")}>
      <div>
        <div className={cx("sidebar-content-header")}>
          <RealTimeGreenIcon />
          <h2 className={cx("sidebar-content-title")}>Adversary ActiviTy</h2>
        </div>
        <p className={cx("sidebar-text")}>
          In this simulation, the adversary was a skilled and resourceful group
          - APT29, also known as Fancy Bear.
        </p>
        <p className={cx("sidebar-text", "mt-20")}>
          The adversary invested significant amount of time into reconnaissance
          and pre-attack deployment, and acted decisively during the actual
          attack phase, quickly moving towards their final objectives.
        </p>
      </div>
      <div className={cx("sidebar-divider")} />
      <div>
        <div className={cx("sidebar-content-header")}>
          <RealTimeGreenIcon />
          <h2 className={cx("sidebar-content-title")}>Adversary Objectives</h2>
        </div>
        <ul className={cx("sidebar-content-list")}>
          {ADVERSARY_OBJECTIVES.map(item => (
            <li
              key={item}
              className={cx("sidebar-content-item", "sidebar-text", "bold")}
            >
              <div>
                <TickCircleIcon />
              </div>
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className={cx("sidebar-divider")} />
      <div>
        <div className={cx("sidebar-content-header")}>
          <RealTimeGreenIcon />
          <h2 className={cx("sidebar-content-title")}>Adversary Techniques</h2>
        </div>
        <Card shadow="true" className={cx("att-and-ck-card")}>
          <AttAndCkIcon />
        </Card>
      </div>
    </Card>
  )
}

export default DebriefSidebar
