import classNames from "classnames/bind"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { notification } from "antd"

import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { CustomCountdown, MainButton } from "../../../../components"
import { simulationsActions } from "../../SimulationsSlice"
import {
  fetchiscompleted,
  payloadProps
} from "../../../../app/reducers/LearningSlice"

import {
  RemainingStep1,
  RemainingStep2,
  RemainingStep3,
  RemainingStep4,
  RemainingStep5
} from "./components"
import styles from "./styles.module.scss"

const remainingStepList = [
  <RemainingStep1 />,
  <RemainingStep2 />,
  <RemainingStep3 />,
  <RemainingStep4 />,
  <RemainingStep5 />
]

const cx = classNames.bind(styles)
interface RemainingProps {
  id: number
  Description: string
}
function Remaining({
  stepsToSimulationDetails,
  setPagesNo,
  simulationId
}: {
  stepsToSimulationDetails: RemainingProps[]
  setPagesNo: (remainingStep: number) => void
  simulationId: any
}) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { user, logohead } = useAppSelector(state => state.user)
  const { moduleId, SubModuleId, moduleType, completedModule } = useAppSelector(
    (state: [] | any) => state?.learningList
  )
  const [remainingStep, setRemainingStep] = useState(1)
  const numberOfSteps = stepsToSimulationDetails.length
  const FilterModule = completedModule?.data?.filter(
    (item: any) =>
      item.attributes.ModuleID === `${moduleId}` &&
      item.attributes.ModuleType === "Simulation" &&
      item.attributes.SubModuleId === `${simulationId}`
  )

  const handleContinue = async () => {
    if (remainingStep < numberOfSteps) {
      const updatedStep = remainingStep + 1
      setRemainingStep(updatedStep)
      setPagesNo(updatedStep) // Pass the updated step value
    } else {
      const payload: payloadProps = {
        data: {
          UserID: `${user?.user?.id}`,
          ModuleID: `${moduleId}`,
          ModuleType: `${moduleType}`,
          Username: `${user?.user?.username}`,
          Completion: true,
          SubModuleId: `${SubModuleId}`
        }
      }
      try {
        if (
          FilterModule?.[0]?.attributes.Completion === true &&
          FilterModule?.[0]?.attributes.ModuleType === "Simulation"
        ) {
          setRemainingStep(1)
          dispatch(simulationsActions.reset())
          navigate("/debrief")
        } else {
          await dispatch(fetchiscompleted({ payload }))
          setRemainingStep(1)
          dispatch(simulationsActions.reset())
          navigate("/debrief")
        }
      } catch (error) {
        console.error("Error while fetching iscompleted:", error)
      }
    }
  }

  return (
    <>
      <div
        className={cx("description-container")}
        style={{ marginBottom: "20px", fontFamily: "Satoshi-Variable" }}
        dangerouslySetInnerHTML={{
          __html: stepsToSimulationDetails[remainingStep - 1].Description
        }}
      />

      <div className={cx("countdown-container")}>
        <p className={cx("launch-text", "mb-20")}>
          Time Remaining in This Stage
        </p>
        <CustomCountdown onComplete={handleContinue} key={remainingStep} />
        <MainButton className={cx("continue-button")} onClick={handleContinue}>
          {remainingStep === numberOfSteps ? "End the Scenario" : "Continue"}
        </MainButton>
      </div>
    </>
  )
}

export default Remaining
