/* eslint-disable import/no-cycle */
import { Avatar } from "antd"
import { Outlet } from "react-router-dom"
import classNames from "classnames/bind"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { useState } from "react"

import { SarahBlack } from "../../assets/imgs"

import TraineeTooltip from "./TraineeAvatar/TraineeTooltip"
import Header from "./Header/Header"
import styles from "./styles.module.scss"
/* eslint-disable react/no-unescaped-entities */
const COLOR_LIST = ["#ED223C", "#407FB9", "#E0865C", "#309D83"]
const cx = classNames.bind(styles)
function AppLayout() {
  const [isOpenSideBar, setIsOpenSidebar] = useState(false)
  return (
    <div className={cx("container")}>
      <Header />
      <div className={cx("app-body")}>
        {/* <div className={cx("side-bar")}>
          <div
            aria-hidden
            className={cx(
              "arrow-btn",
              !isOpenSideBar ? "arrow-btn--hide-sidebar" : ""
            )}
            onClick={() => setIsOpenSidebar(isOpen => !isOpen)}
          >
            {isOpenSideBar ? (
              <LeftOutlined className={cx("arrow-icon")} />
            ) : (
              <RightOutlined className={cx("arrow-icon")} />
            )}
          </div>
          <div
            className={cx(
              "side-bar__inner",
              !isOpenSideBar ? "side-bar--hidden" : ""
            )}
          >
            {[
              { id: 1, name: "An Grid", address: "Mallern, VIC" },
              { id: 2, name: "San En", address: "Mallern, VIC" },
              { id: 3, name: "Sarah Black", address: "Mallern, VIC" },
              { id: 4, name: "Gas Fran", address: "Mallern, VIC" }
            ].map((item, index) => (
              <div key={item.id} className={cx("side-bar__inner--item")}>
                <TraineeTooltip
                  name={item.name}
                  address={item.address}
                  src={item.name === "Sarah Black" ? SarahBlack : null}
                >
                  <Avatar
                    style={{ backgroundColor: COLOR_LIST[index] }}
                    className={cx("user-avatar")}
                    size="large"
                  >
                    {item.name
                      ?.split(" ")
                      .map(name => name.substring(0, 1))
                      .join("")}
                  </Avatar>
                </TraineeTooltip>
              </div>
            ))}
          </div>
        </div> */}
        <div
          className={cx(
            "app-content",
            !isOpenSideBar ? "app-content--hide-side-bar" : ""
          )}
        >
          <Outlet />
        </div>
      </div>
    </div>
  )
}

AppLayout.defaultProps = {
  children: null
}

export default AppLayout
