import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Typography,
  notification
} from "antd"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"

import { HeaderTitle, MainButton, OutlineButton } from "../../../components"
import homeIcon from "../../../assets/icons/Home.png"
import backIcon from "../../../assets/icons/back.png"
import nextIcon from "../../../assets/icons/next.png"
import { CustomModal } from "../../../assets/css/styled"
import { CloseIconSm } from "../../../assets/svg"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  fetchAssesstmentScoreApi,
  fetchiscompleted,
  payloadProps,
  scoreProps,
  setIndexvalue,
  setSelectedOptions
} from "../../../app/reducers/LearningSlice"

import styles from "./styles.module.scss"

export const RADIO = "radio"

interface OptionType {
  option_id?: string
  text?: string
}
interface QuestionList {
  id: number
  question?: string
  options?: OptionType[]
}
interface QuizType {
  id: string
  title: string
  sets: {
    id: number
    type?: string
    title: string
    questionList?: QuestionList[]
    description?: string
    button?: string
  }[]
}
interface ObjectivesProps {
  titleNew: string | null
  sets: any
  selectedIndex: number
  handleIndexChange: (index: number) => void
}

interface totalMarksProps {
  A: string | null
  Answer: string | null
  B: string | null
  C: string | null
  D: string | null
  Question: string
  Type: string
  id: number | null
  marks: number | null
}
interface Question {
  id: number
  question?: string
  options?: { [key: string]: string | null }
}
type CurrentQuestion = Question | null

function QuizSlides({ sets, titleNew, quizId }: any) {
  const dispatch = useAppDispatch()
  const [currentPage, setCurrentPage] = useState(0)
  const [showThankYou, setShowThankYou] = useState(false)
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false)
  const [initialRender, setInitialRender] = useState(false)
  const [isPageRefresh, setIsPageRefresh] = useState(true)
  const [confirmNavigation, setConfirmNavigation] = useState(true)
  const [validationError, setValidationError] = useState("")
  const { user } = useAppSelector(state => state.user)
  const {
    moduleId,
    SubModuleId,
    moduleType,
    completedModule,
    indexValue,
    selectedOptions
  } = useAppSelector((state: [] | any) => state?.learningList)
  const [totalMarks, setTotalMarks] = useState<totalMarksProps[]>([
    {
      A: "",
      Answer: "",
      B: "",
      C: "",
      D: "",
      Question: "",
      Type: "",
      id: null,
      marks: null
    }
  ])
  const [totalMarksCalculated, setTotalMarksCalculated] = useState<number>(0)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [indexValue, showThankYou])

  useEffect(() => {
    if (sets) {
      const totalSets = sets.length
      const marksPerSet = 100 / totalSets
      const updatedSets = sets.map((set: any) => ({
        ...set,
        marks: marksPerSet
      }))
      setTotalMarks(updatedSets)
    }
  }, [sets])
  const navigate = useNavigate()
  const paginatedData = totalMarks

  useEffect(() => {
    if (initialRender) {
      localStorage.setItem("shouldOpenModal", "true")
    }
  }, [initialRender])
  useEffect(() => {
    const shouldOpenModal = localStorage.getItem("shouldOpenModal")

    if (shouldOpenModal === "true") {
      setIsModalConfirmOpen(true)
    }
    return () => {
      localStorage.removeItem("shouldOpenModal")
      dispatch(setIndexvalue(0))
      dispatch(setSelectedOptions({}))
    }
  }, [dispatch])

  const currentQuestion: any = totalMarks?.[indexValue] || null
  const currentIndex = totalMarks ? indexValue + 1 : 0
  const FilterModule = completedModule?.data?.filter(
    (item: any) =>
      item?.attributes?.SubModuleId === `${quizId}` &&
      item?.attributes?.ModuleType === "Assessment" &&
      item?.attributes?.Completion === true
  )

  const goBack = () => {
    if (indexValue === 0) {
      navigate(-1)
    } else {
      dispatch(setIndexvalue(indexValue - 1))
      setInitialRender(true)
      if (!selectedOptions?.[indexValue]) {
        setValidationError("")
      }
    }
  }
  const goNext = () => {
    if (!selectedOptions?.[indexValue]) {
      setValidationError("Please select your answer.")
      return
    }
    setInitialRender(true)
    if (indexValue === sets.length - 1) {
      setShowThankYou(true)
    } else {
      dispatch(setIndexvalue(indexValue + 1))
      setValidationError("")
    }
  }
  const goTryagain = async () => {
    dispatch(setIndexvalue(0))
    setCurrentPage(0)
    setShowThankYou(false)
    dispatch(setSelectedOptions({}))
    setValidationError("")
    const scorePayload: scoreProps = {
      data: {
        UserID: `${user?.user?.id}`,
        ModuleID: `${moduleId}`,
        ModuleType: `${moduleType}`,
        Username: `${user?.user?.username}`,
        SubModuleId: `${SubModuleId}`,
        Scorepercentage: `${totalMarksCalculated}`
      }
    }
    const payload: payloadProps = {
      data: {
        UserID: `${user?.user?.id}`,
        ModuleID: `${moduleId}`,
        ModuleType: `${moduleType}`,
        Username: `${user?.user?.username}`,
        Completion: true,
        SubModuleId: `${SubModuleId}`
      }
    }
    try {
      if (FilterModule.length > 0) {
        navigate("/")
      } else {
        await dispatch(fetchAssesstmentScoreApi({ scorePayload }))
        await dispatch(fetchiscompleted({ payload }))
        navigate("/")
      }
    } catch (error) {
      console.error("Error while fetching iscompleted:", error)
    }
  }
  const handleValidateForm = (values: any) => {
    console.log(values)
  }
  const handleValidateFormFailed = () => {
    console.log("false")
  }

  const showModalConfirm = () => {
    setIsModalConfirmOpen(true)
  }
  const handleSubmitModalConfirm = () => {
    setIsModalConfirmOpen(false)
    setIsPageRefresh(false)
    setConfirmNavigation(false)
    navigate("/")
  }
  const handleCancelModalConfirm = () => {
    setIsModalConfirmOpen(false)
    setIsPageRefresh(false)
    setConfirmNavigation(false)
  }

  const handleSelectOption = (option: string, value: string) => {
    const currentQuestions = totalMarks?.[indexValue]
    const selectedOption = option?.toUpperCase()
    const correctOption = currentQuestions?.Answer?.toUpperCase()
    if (selectedOption === correctOption) {
      const marksForQuestion = currentQuestions?.marks ?? 0
      setTotalMarksCalculated(prevTotal => prevTotal + marksForQuestion)
    }

    dispatch(
      setSelectedOptions({
        ...selectedOptions,
        [indexValue]: { option, value }
      })
    )
    setValidationError("")
  }

  return (
    <div>
      <div className={styles.body}>
        <div className={classNames(styles.container)}>
          {showThankYou ? (
            ""
          ) : (
            <>
              {" "}
              <Row
                className={styles.positionheader}
                justify="center"
                align="middle"
              >
                <Col
                  xs={24}
                  sm={24}
                  md={15}
                  lg={17}
                  xl={19}
                  className={styles.headerLeft}
                >
                  <HeaderTitle text={titleNew} />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={9}
                  lg={7}
                  xl={5}
                  className={styles.colHomeSecond}
                >
                  {" "}
                  <div className={styles.btn}>
                    <Button
                      onClick={showModalConfirm}
                      className={styles.backBtn}
                    >
                      <div className={styles.btnHome}>
                        <span>
                          <img src={homeIcon} alt="" />
                        </span>
                        <p>Home</p>
                      </div>
                    </Button>
                    <Button
                      className={styles.backBtn}
                      onClick={goBack}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={styles.btnback}>
                        <span>
                          <img src={backIcon} alt="" />
                        </span>
                        <p>Back</p>
                      </div>
                    </Button>
                    <Button
                      className={styles.nextBtn}
                      style={{ cursor: "pointer" }}
                      onClick={goNext}
                      // disabled={!selectedOptions[currentPage]}
                    >
                      <div style={{ height: "3px" }} className={styles.btnNext}>
                        <p>Next</p>
                        <span className={styles.default}>
                          <img src={nextIcon} alt="" />
                        </span>
                        <span className={styles.defaulthover}>
                          <img src={backIcon} alt="" />
                        </span>
                      </div>
                    </Button>
                    <div className={styles.pages}>
                      {`${currentIndex} / ${
                        paginatedData && Array.isArray(paginatedData)
                          ? paginatedData.length
                          : 0
                      }`}
                    </div>
                  </div>
                </Col>
                <Divider style={{ backgroundColor: "white", opacity: "0.5" }} />
              </Row>
              <Form
                name="assessment-question"
                // onFinish={handleValidateForm}
                // onFinishFailed={handleValidateFormFailed}
                className={styles.quizquestionwrapper}
              >
                {totalMarks.length > 0
                  ? currentQuestion && (
                      <Form.Item
                        key={currentQuestion.id}
                        name={currentQuestion.Question}
                        label={`${currentQuestion.Question}`}
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "Please complete the question!"
                          }
                        ]}
                        className="form-item"
                      >
                        <Radio.Group
                          onChange={e =>
                            handleSelectOption(
                              e.target.value,
                              currentQuestion[e.target.value]
                            )
                          }
                          value={selectedOptions?.[indexValue]?.option}
                        >
                          {["A", "B", "C", "D"].map((optionKey: string) => {
                            const optionValue = currentQuestion[optionKey]
                            if (optionValue !== null) {
                              return (
                                <Radio
                                  style={{ display: "block" }}
                                  key={optionKey}
                                  value={optionKey}
                                >
                                  {optionValue}
                                </Radio>
                              )
                            }
                            return null
                          })}
                        </Radio.Group>
                        <div className={styles.validationMsg}>
                          {validationError && (
                            <Typography.Text type="danger">
                              {validationError}
                            </Typography.Text>
                          )}
                        </div>
                      </Form.Item>
                    )
                  : null}
              </Form>
            </>
          )}
          {showThankYou && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh"
              }}
            >
              <div className={styles.thankYouDiv}>
                {" "}
                <HeaderTitle text={`Your Score ${totalMarksCalculated}%`} />
                <Divider style={{ backgroundColor: "white" }} />
                <Typography.Paragraph className={styles.paragraph}>
                  Great work! Thank you for completing the quiz. Good luck
                  making it to the next round.
                </Typography.Paragraph>
                <Button
                  className={styles.nextBtn}
                  onClick={goTryagain}
                  style={{ cursor: "pointer" }}
                >
                  Try Again
                </Button>
              </div>
            </div>
          )}

          {/* {showThankYou ? (
            ""
          ) : (
            <div className={styles.pageNum}>
              {`${currentIndex} / ${
                paginatedData && Array.isArray(paginatedData)
                  ? paginatedData.length
                  : 0
              }`}
            </div>
          )} */}
        </div>
      </div>
      <CustomModal
        title="Modal confirm"
        centered
        footer={null}
        closeIcon={<CloseIconSm />}
        open={isModalConfirmOpen}
        onCancel={handleCancelModalConfirm}
        className={styles.modalConfirm}
      >
        <div className={styles.modalConfirmWrap}>
          <p className={styles.modalConfirmText}>
            Do you want to exit this Assessment and return to the home page?
          </p>
          <Divider className={styles.divider} />
          <div className={styles.modalButton}>
            <MainButton onClick={handleSubmitModalConfirm}>Yes</MainButton>
            <OutlineButton onClick={handleCancelModalConfirm}>No</OutlineButton>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}
export default QuizSlides
