import classNames from "classnames/bind"

import styles from "../styles.module.scss"

const cx = classNames.bind(styles)

function RemainingStep1() {
  return (
    <div className={cx("remaining-mess-container")}>
      <p className={cx("remaining-mess")}>
        Luckily for you, Tarangaroo Water has multiple security systems deployed
        in their corporate and operational networks.
      </p>
      <p className={cx("remaining-mess")}>
        Review SIEM security events over last few weeks and see if you can find
        any anomalies and identify any behavior which needs to be investigated
        further.
      </p>
      <p className={cx("remaining-mess")}>
        Document anomalies in your case management system and in necessary,
        declare a security incident.
      </p>
      <p className={cx("remaining-mess")}>
        Click on the button below once you are ready to progress to the next
        stage of the simulation. Otherwise, the simulation will progress
        automatically when the timer below expires.
      </p>
    </div>
  )
}

export default RemainingStep1
