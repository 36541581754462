import React, { useEffect, useState } from "react"
import { Button, Checkbox, Form, Input } from "antd"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
// eslint-disable-next-line import/no-extraneous-dependencies
import { InputOTP } from "antd-input-otp"

import "../../auth.scss"
import { useAppSelector } from "../../../../app/hooks"
import LOGoimage from "../../../../assets/imgs/logoNSB.png"

interface LoginFormState {
  email: string
}

const initialFormState: LoginFormState = {
  email: ""
}

function MfaVerification() {
  const [formData, setFormData] = useState<LoginFormState>(initialFormState)
  const [showEmailError, setShowEmailError] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const { loading, user, logohead } = useAppSelector(state => state.user)

  const [showInvalidEmailError, setShowInvalidEmailError] =
    useState<boolean>(false)
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const handleFinish = (values: any) => {
    console.log(values)
  }
  const loginText = logohead?.data?.attributes?.LoginText
  return (
    <div className="bground">
      <div className="miniuese">
        <div className="form_range">
          <img src={LOGoimage} alt="" />

          <h5>{loginText}</h5>
          <h2>Authenticate your Account</h2>
          <p>Please confirm your details by entering the authorization code</p>
        </div>

        <Form className="form_box" onFinish={handleFinish} form={form}>
          <Form.Item name="otp">
            <InputOTP autoSubmit={form} inputType="numeric" />
          </Form.Item>

          <Form.Item>
            <div
              className="text-center"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ background: "#ED223C" }}
                // disabled={isDisabled}
              >
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default MfaVerification
