import React, { useEffect, useState } from "react"
import { notification } from "antd"
import { useNavigate, useParams } from "react-router-dom"

import { DOMAIN_URL } from "../../api/config"

function Download() {
  const { fileName } = useParams()
  const navigate = useNavigate()

  const downloadFile = async () => {
    fetch(`${DOMAIN_URL}/${fileName}`)
      .then(res => {
        if (!res.ok) {
          throw new Error("Network Problem")
        }
        return res.blob()
      })
      .then(file => {
        if (
          file?.type === "text/plain" ||
          file?.type === "video/mp4" ||
          file?.type === "text/csv"
        ) {
          const tUrl = URL.createObjectURL(file)
          const tmp1 = document.createElement("a")
          tmp1.href = tUrl
          tmp1.download = `${fileName}`
          document.body.appendChild(tmp1)
          tmp1.click()
          URL.revokeObjectURL(tUrl)
          tmp1.remove()
        } else {
          notification.error({
            message: `Please visit valid url to download file`,
            key: "updatable"
          })
        }

        window.location.href = "/"
      })
      .catch(() => {
        notification.error({
          message: `Something went wrong`,
          key: "updatable"
        })
        window.location.href = "/"
      })
  }
  useEffect(() => {
    downloadFile()
  })

  return <div />
}

export default Download
