function PracticeLabIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.9">
        <path
          d="M7.5 18.3333H12.5C16.6667 18.3333 18.3333 16.6667 18.3333 12.5V7.5C18.3333 3.33333 16.6667 1.66666 12.5 1.66666H7.5C3.33334 1.66666 1.66667 3.33333 1.66667 7.5V12.5C1.66667 16.6667 3.33334 18.3333 7.5 18.3333Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33333 14.1667H11.6667C13.0417 14.1667 14.1667 13.0417 14.1667 11.6667V8.33334C14.1667 6.95834 13.0417 5.83334 11.6667 5.83334H8.33333C6.95833 5.83334 5.83333 6.95834 5.83333 8.33334V11.6667C5.83333 13.0417 6.95833 14.1667 8.33333 14.1667Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 5.83334V14.1667"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.83333 10H14.1667"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
export default PracticeLabIcon
