/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit"

export interface SimulationsState {
  duration: number
  isLaunching: boolean
  isCountingdown: boolean
  isRemaining: boolean
}

const TWO_HOURS_AND_HALF = 2 * 60 * 60 * 1000 + 30 * 60 * 1000
const simulationsState: SimulationsState = {
  duration: TWO_HOURS_AND_HALF,
  isLaunching: false,
  isCountingdown: false,
  isRemaining: false
}

export const simulationsSlice = createSlice({
  name: "simulations",
  initialState: simulationsState,
  reducers: {
    setLaunching: (state, action) => {
      state.isLaunching = action.payload
    },
    setCountingdown: (state, action) => {
      state.isCountingdown = action.payload
    },
    setRemaining: (state, action) => {
      state.isRemaining = action.payload
    },
    reset: state => {
      state.isCountingdown = false
      state.isLaunching = false
      state.isRemaining = false
    }
  }
})
export const simulationsActions = simulationsSlice.actions
export default simulationsSlice.reducer
