function CategoryIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.9">
        <path
          d="M16.6667 6.875V15C16.6667 17.5 15.175 18.3333 13.3334 18.3333H6.66669C4.82503 18.3333 3.33336 17.5 3.33336 15V6.875C3.33336 4.16667 4.82503 3.54167 6.66669 3.54167C6.66669 4.05833 6.875 4.525 7.21667 4.86666C7.55834 5.20833 8.02502 5.41667 8.54169 5.41667H11.4584C12.4917 5.41667 13.3334 4.575 13.3334 3.54167C15.175 3.54167 16.6667 4.16667 16.6667 6.875Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3333 3.54167C13.3333 4.575 12.4916 5.41667 11.4583 5.41667H8.54164C8.02497 5.41667 7.55829 5.20833 7.21662 4.86666C6.87495 4.525 6.66664 4.05833 6.66664 3.54167C6.66664 2.50833 7.50831 1.66667 8.54164 1.66667H11.4583C11.975 1.66667 12.4417 1.875 12.7833 2.21667C13.125 2.55834 13.3333 3.025 13.3333 3.54167Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66664 10.8333H9.99997"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66664 14.1667H13.3333"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default CategoryIcon
