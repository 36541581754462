import { v5 as uuidv5 } from "uuid"

function uniqueId(numberId: number): string {
  const hexString = numberId.toString(16).padStart(32, "0")

  const uuid = uuidv5(hexString, uuidv5.URL)

  return uuid
}

function extractTime(timeString: string) {
  const [hours, minutes] = timeString.split(":").map(Number)

  if (hours === 0) {
    return `${minutes} minutes`
  }
  if (minutes === 0) {
    return `${hours} hours`
  }
  return `${hours} hours and ${minutes} minutes`
}
export { extractTime, uniqueId }
