import { useEffect, useState } from "react"
import classNames from "classnames/bind"

import { LinkIcon, RealTimeGreenIcon } from "../../assets/svg"
import Card from "../Card/Card"

import styles from "./styles.module.scss"

const cx = classNames.bind(styles)
// export interface Links {
//   id: number | string
//   name: string
//   link: string
//   linkDisplayed: string
// }
// export interface ToolsAndSystemsProps {
//   title: string
//   links?: Links[]
//
interface TOOLSANDPROPS {
  sets: {
    ToolsandSystems: string
  }
}
function ToolsAndSystems({ sets }: TOOLSANDPROPS) {
  return (
    <Card className={cx("sidebar-content")}>
      <div>
        <div className={cx("sidebar-content-header")}>
          <RealTimeGreenIcon />
          <h2 className={cx("sidebar-content-title")}>Tools and Systems</h2>
        </div>

        <p
          className={cx("content-text")}
          dangerouslySetInnerHTML={{ __html: sets.ToolsandSystems }}
        />
        {/* <p className={cx("sidebar-text")}>{title}</p> */}
        {/* {links?.map(item => (
          <div key={item.id} className={cx("sidebar-link-container")}>
            <p className={cx("sidebar-text", "mb-0")}>{item.name}</p>
            <a
              href={item.link}
              target="_blank"
              className={cx("sidebar-link", "sidebar-text")}
              rel="noreferrer"
            >
              <div>
                <LinkIcon />
              </div>
              {item.linkDisplayed}
            </a>
          </div>
        ))} */}
      </div>
    </Card>
  )
}

export default ToolsAndSystems
