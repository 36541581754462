import styles from "./styles.module.scss"

export interface ScoreItemProps {
  title: string
  score: number
  scoreScale: number
}

function ScoreItem({ title, score, scoreScale }: ScoreItemProps) {
  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>{title}</p>
      <p className={styles.score}>
        {score}/{scoreScale}
      </p>
    </div>
  )
}

export default ScoreItem
