import classNames from "classnames/bind"

import { Ellipse } from "../../../../assets/imgs"

import styles from "./styles.module.scss"

const cx = classNames.bind(styles)

function Launching({ text }: { text: string }) {
  return (
    <>
      <p className={cx("launch-text")} style={{ width: "100%" }}>
        {text}
      </p>
      <div className={cx("loading-icon-container")}>
        <img className={cx("loading-icon")} src={Ellipse} alt="loading" />
        <p className={cx("loading-text")}>Loading...</p>
      </div>
    </>
  )
}

export default Launching
