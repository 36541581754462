import { useEffect, useState } from "react"
import classNames from "classnames/bind"
import { v4 as uuidv4 } from "uuid"

import { RealTimeGreenIcon, TickCircleIcon } from "../../assets/svg"
import Card from "../Card/Card"

import styles from "./styles.module.scss"

const cx = classNames.bind(styles)

interface ObjectivesProps {
  sets: {
    Objectives: string
    RecommendedSkillSet: string
  }
}
function Objectives({ sets }: ObjectivesProps) {
  return (
    <div style={{ height: "100%", marginBottom: "20px" }}>
      <Card className={cx("sidebar-content")}>
        <div>
          <div className={cx("sidebar-content-header")}>
            <RealTimeGreenIcon />
            <h2 className={cx("sidebar-content-title")}>Objectives</h2>
          </div>

          <ul className={cx("sidebar-content-list")}>
            <li
              key={uuidv4()}
              className={cx("sidebar-content-item", "sidebar-text")}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: sets?.Objectives
                }}
              />
            </li>
          </ul>
        </div>
        <div className={cx("sidebar-divider")} />
        <div>
          <div className={cx("sidebar-content-header")}>
            <RealTimeGreenIcon />
            <h2 className={cx("sidebar-content-title")}>
              Recommended Skill Set
            </h2>
          </div>
          <ul className={cx("sidebar-content-list")}>
            <li
              key={uuidv4()}
              className={cx("sidebar-content-item", "sidebar-text")}
            >
              <div
                dangerouslySetInnerHTML={{ __html: sets.RecommendedSkillSet }}
              />
            </li>
          </ul>
        </div>
      </Card>
    </div>
  )
}

export default Objectives
